<template>
  <!-- 对话框 -->
  <el-dialog
    class="moe-dialog"
    custom-class="moe-dialog"
    :class="{ 'auto-height': autoHeight }"
    :visible="show"
    center
    :title="title"
    :width="width"
    :modal-append-to-body="false"
    :close-on-click-modal="closeOnClickModal"
    @open="onOpen"
    @close="onClose"
    @before-close="onClose"
    z-index="999">
    <!-- 内容 -->
    <div class="moe-dialog__content">
      <slot />
    </div>
    <!-- 尾部 -->
    <div class="moe-dialog__footer" slot="footer" v-if="$slots.footer">
      <slot name="footer" />
    </div>
  </el-dialog>
</template>

<script>
/**
 * 弹出层 - moe-dialog
 * @description 基于el-dialog组件的二次封装的弹出层
 * @property {String} show 显示弹框
 * @property {Number} title 标题
 * @property {Number} width 宽度
 * @property {Boolean} closeOnClickModal 是否可以通过点击 modal 关闭 Dialog
 * @event {Function} open 弹框打开
 * @event {Function} close 弹框关闭
 **/
export default {
  name: 'moe-dialog',
  props: {
    //弹框显示
    show: {
      type: Boolean,
      default: false,
    },

    // 标题
    title: {
      type: String,
      default: '系统操作',
    },

    // 宽度
    width: {
      type: String,
      default: '30%',
    },

    //自适应高度
    autoHeight: {
      type: Boolean,
      default: false
    },

    //是否可以通过点击 modal 关闭 Dialog
    closeOnClickModal: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    /**
     * 弹框打开
     **/
    onOpen() {
      this.$emit('open')
    },

    /**
     * 弹框关闭
     **/
    onClose() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
/* 弹出框样式 */
.moe-dialog {
  ::v-deep .el-dialog {
    border-radius: 10px;
    overflow: hidden;

    .el-dialog__header {
      color: #fff;
      background-color: #dfa663;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .el-dialog__title {
      font-size: 17px;
      color: #fff;
      font-weight: bold;
      letter-spacing: 1px;
    }

    .el-dialog__headerbtn {
      .el-icon-close {
        font-size: 21px;
        color: #fff;
      }
    }

    .el-dialog__body {
      padding: 15px;
      min-height: 500px;
    }

    .el-dialog__footer {
      border-top: 1px solid #eee;
      padding-top: 20px;
    }

    .moe-dialog__content {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .dialog_content {
      display: flex;
      flex-direction: column;
      min-height: 70vh;
    }
  }
}
</style>

<style lang="scss">
.moe-dialog {
  &.auto-height {
    .el-dialog__body, .dialog_content {
      min-height: 0 !important;
    }
  }
}
</style>
