/**
 * 新人专享
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 创建新人专享活动 */
  addDiscount(data) {
    return http2({
      method: 'post',
      url: '/shop/newcomer',
      data,
    });
  },
  /** 获取新人专享活动详情 */
  getDiscountDetail(params) {
    return http({
      method: 'get',
      url: '/shop/newcomer/detail',
      params,
    });
  },
  /** 修改新人专享活动 */
  updateDiscount(data) {
    return http2({
      method: 'put',
      url: '/shop/newcomer',
      data,
    });
  },
  /** 修改新人专享活动配置 */
  updateDiscountConfigList(data) {
    return http2({
      method: 'put',
      url: '/shop/newcomer/items',
      data,
    });
  },
  /** 关闭新人专享活动 */
  closeDiscount(data) {
    return http2({
      method: 'post',
      url: '/shop/newcomer/close',
      data,
    });
  },
  /** 暂停新人专享活动 */
  pauseDiscount(data) {
    return http2({
      method: 'post',
      url: '/shop/newcomer/pause',
      data,
    });
  },
  /** 重启新人专享活动 */
  restartDiscount(data) {
    return http2({
      method: 'post',
      url: '/shop/newcomer/restart',
      data,
    });
  },
  /** 删除新人专享活动 */
  delNewcomer(params) {
    return http({
      method: 'delete',
      url: '/shop/newcomer',
      params,
    });
  },
};