/**
  * 优惠券
  **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 创建优惠券 */
  addCoupon(data) {
    return http2({
      method: 'post',
      url: '/shop/coupon',
      data,
    });
  },

  /** 修改优惠券 */
  updateCoupon(data) {
    return http2({
      method: 'put',
      url: '/shop/coupon',
      data,
    });
  },

  /** 获取优惠券详情 */
  getCouponDetail(params) {
    return http({
      method: 'get',
      url: '/shop/coupon/detail',
      params,
    });
  },

  /** 结束优惠券 */
  endCoupon(data) {
    return http2({
      method: 'post',
      url: '/shop/coupon/close',
      data,
    });
  },

  /** 批量获取优惠券详情 */
  getCouponListByIds(params) {
    return http({
      method: 'get',
      url: '/shop/coupon/listByIds',
      params,
    });
  },

  /** 发放优惠券 */
  couponSend(data) {
    return http2({
      method: 'post',
      url: '/shop/coupon/send',
      data,
    });
  },
};