<template>
  <div class="nav-view">
    <div class="nav-view__left">
      <div
        class="nav-view__item"
        :title="collapse ? '展开左侧菜单' : '收起左侧菜单'"
        @click="setCollapse(!collapse)"
      >
        <moe-icon class="nav-view__icon" :name="collapse ? 'menu-open' : 'menu-close'" />
        <span></span>
      </div>
      <!-- 面包屑导航 -->
      <moe-breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    </div>
    <div class="nav-view__right">
      <div class="nav-view__item" title="刷新" @click="refreshChildPage">
        <moe-icon :class="['nav-view__icon',  childPageLoad ? 'nav-view__icon--rotate' : '',  ]" name="refresh" />
      </div>
      <div class="nav-view__item" title="全屏" @click="screenFull">
        <moe-icon class="nav-view__icon" :name="isFullscreen ? 'exit-full-screen' : 'full-screen'" />
      </div>
      <el-dropdown
        class="nav-view__item"
        size="default"
        @command="systemChange"
      >
        <el-dropdown-menu slot="dropdown" style="padding: 0px">
          <el-dropdown-item command="pwd">
            <moe-icon name="edit" size="10px"/>
            修改密码
          </el-dropdown-item>
          <el-dropdown-item command="exit" divided>
            <moe-icon name="on-off" size="10px" />
            退出登录
          </el-dropdown-item>
        </el-dropdown-menu>
        <div class="nav-view__avatar">
          <el-avatar
            :size="32"
            shape="square"
            icon="el-icon-user-solid"
          />
          <span>{{ user.name }}</span>
        </div>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
/* 全屏方法 */
import screenfull from 'screenfull';
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'nav-view',
  data() {
    return {
      isFullscreen: false, //当前是否全屏
    };
  },
  created() { },
  methods: Object.assign(
    {
      /**
       * 刷新子页面
       */
      refreshChildPage() {
        if (!this.childPageLoad) {
          this.setChildPageLoad(true);
          setTimeout(() => {
            this.setChildPageLoad(false);
            this.$message.success('刷新成功');
          }, 500);
        }
      },

      /**
       * 操作全屏
       */
      screenFullChange() {
        this.isFullscreen = screenfull.isFullscreen;
      },

      /**
       * 全屏显示
       */
      screenFull() {
        console.log(screenfull);
        if (!screenfull.isEnabled) {
          return false;
        }
        screenfull.toggle();
      },

      /**
       * 系统操作，修改密码...
       * @param {Object} e
       */
      systemChange(val) {
        switch (val) {
          case 'pwd':
            this.setPwdShow(true);
            break;
          case 'exit':
            this.$router.resetRoutes();
            this.$router.push('/login');
            this.$moe_msg.success('退出成功');
            break;
          default:
            break;
        }
      },
    },

    /**
     * 设置系统数据
     * setCollapse: 设置左侧菜单展开状态
     * setChildPageLoad: 设置子页面加载状态
     * setPwdShow: 设置修改密码弹框状态
     */
    mapMutations('setting', ['setCollapse', 'setChildPageLoad', 'setPwdShow'])
  ),

  /**
   * 系统数据
   * collapse: 左侧菜单栏是否收缩
   * childPageLoad: 子页面加载状态
   * user: 用户信息
   */
  computed: {
    ...mapState('setting', ['collapse', 'childPageLoad']),
    ...mapState('user', ['user']),
  },

  mounted() {
    if (screenfull.isEnabled) {
      screenfull.on('change', this.screenFullChange);
    }
  },
  /* 监听组件销毁 */
  destroy() {
    if (screenfull.isEnabled) {
      screenfull.off('change', this.screenFullChange);
    }
  },
};
</script>

<style lang="scss" scoped>
.nav-view {
  position: relative;
  z-index: 100;
  padding: 0 25px 0 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  height: 60px;

  &--fixed {
    position: fixed;
    top: 0;
  }

  &__left {
    display: flex;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 5px;
    cursor: pointer;
    border-radius: 3px;

    &:hover {
      background-color: #000;
      transition-property: background-color, transform;
      transition-duration: 0.5s;

      i {
        animation: scaleIcon 300ms ease-in-out;
      }
    }
  }

  &__avatar {
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
      color: #fff;
    }
  }

  &__icon {
    font-size: 19px;
    margin: 0 10px;
    font-weight: bold;

    &--rotate {
      transition: transform 0.7s ease-in-out;
      transform: rotate(720deg);
    }
  }

  @keyframes scaleIcon {
    0% {
      transform: scale(0);
    }
    80% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
}
</style>