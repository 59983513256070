<template>
  <div class="moe-tag">
    <el-tag :type="type" :closable="closable" @close="onClose()">
      <slot>
        <!-- 插槽 -->
      </slot>
    </el-tag>
  </div>
</template>

<script>
export default {
  name: 'moeTag',
  model: {
    event: 'close',
  },
  props: {
    type: {
      type: String,
      default: '-',
    },
    closable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.moe-tag {

}
</style>