/**
 * @Name Auth
 * @Describe 用户权限
 **/

import LOGIN_API from '@/api/modules/login'
import {dynamicRoutes} from './routes'
import router from '@/router'
import store from '@/store'

/**
 * 获取菜单列表
 **/
function getMenus(list) {
    const menus = ['/welcome'];
    list.forEach(item => {
        item.childs && item.childs.forEach(items => {
            items.path && menus.push(items.path);
        });
    });
    return menus;
}

/**
 * 1.获取用户权限信息，返回Promise
 * 2.路由前端生成，根据后台标识进行验证
 * 3.更新用户信息
 * @return {Promise}
 **/
export default function getAuthority() {
    store.commit('user/setIsAuth', false);
    return new Promise((resolve, reject) => {
        //调用获取用户权限API
        LOGIN_API.userAuthority().then(data => {
            data=data || {};
            if (data.code == 200) {
                /**
                 * menus: 菜单信息
                 * oldRoutes: 旧路由信息
                 * newRoutes: 新路由信息
                 **/
                const oldRoutes = dynamicRoutes[0].children;
                const menus = getMenus(data.result);
                // const newRoutes = oldRoutes.filter(item => menus.some(path => path === item.meta.path));
                const newRoutes = oldRoutes.map((item) => {
                    return menus.map((path) => {
                        if (path === item.meta.path) {
                            // let children = []
                            // if (item.children && item.children.length) {
                            //     children = item.children.filter((itemChildrenItem) => path === itemChildrenItem.meta.path)
                            // }
                            return {
                                ...item,
                                // children,
                            }
                        } else {
                            if (item.children && item.children.length) {
                                return item.children.filter((itemChildrenItem) => path === itemChildrenItem.meta.path)
                            } else {
                                return []
                            }
                        }
                    }).flat()
                }).flat()

                // 添加路由
                dynamicRoutes[0].children = newRoutes;
                router.addRoutes(dynamicRoutes);

                store.commit('setting/setMenuList', data.result);
                store.commit('setting/setPageLoad', false);

                //更新用户信息
                store.dispatch('user/updateUserInfo');
                resolve(menus);
            } else {
                reject(data.message);
            }
        }).catch(err => {
            reject(err);
        });
    });
}