/**
  -- Created by Lichuangwei on 2023/10/16
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 商城管理
  -- 1.运费管理
* */

import BlankView from '@/views/BlankView.vue';

const MallManage = [
  {
    path: 'mallManage',
    name: 'MallManage',
    meta: {
      title: '商城管理',
      path: '/mallManage/freightManage/list',
    },
    component: BlankView,
    children: [
      /** 运费管理 */
      {
        path: '/mallManage/freightManage/list',
        name: 'FreightManageList',
        meta: {
          title: '运费管理',
          path: '/mallManage/freightManage/list',
        },
        component: () => import('@/views/MallManage/FreightManage/List.vue'),
      },
      {
        path: '/mallManage/freightManage/add',
        name: 'FreightManageAdd',
        meta: {
          title: '新增运费管理',
          path: '/mallManage/freightManage/add',
        },
        component: () => import('@/views/MallManage/FreightManage/Add.vue'),
      },
    ],
  },
];

export default MallManage;