/**
  * Created by Lichuangwei on 2023/1/5
  * Copyright © 2017 www.moemone.com. All rights reserved.
  */

import { v4 as uuidv4 } from 'uuid';
import crypto from 'crypto-js';
import imageCompression from 'browser-image-compression';
import { Base64 } from 'js-base64';
import { getStsRequest } from '@/api/modules/upload';
import storage from '@/storage/storage';
import STORAGE from '@/storage/keys';

export const yunHelper = {
  /** 获取STS临时访问凭证 */
  async getStsRequest(key) {
    let credentialsString = storage.get(STORAGE.OSS_TOKEN);
    if (credentialsString) {
      credentialsString = JSON.parse(credentialsString);
    } else {
      // 首次储存为null问题
      credentialsString = {};
    }

    let credentialsStringKey = credentialsString[key];
    //检查 item.expireInSecond 时间是否过期 过期清除credentialsStringKey
    if (
      credentialsStringKey &&
      credentialsStringKey.expireInSecond <= new Date().getTime()
    ) {
      credentialsStringKey = '';
    }

    if (credentialsStringKey) {
      return credentialsStringKey;
    } else {
      try {
        const { result } = await getStsRequest({ key });
        storage.set(
          STORAGE.OSS_TOKEN,
          JSON.stringify(
            Object.assign(credentialsString, {
              /**
               * result.expiration 为秒单位 * 1000转为毫秒级别 + 当前毫秒数 - 2分钟毫秒数误差
               */
              [key]: {
                ...result,
                expireInSecond:
                  result.expiration * 1000 +
                  new Date().getTime() -
                  2 * 60 * 1000,
              },
            })
          )
        );
        return result;
      } catch (error) {
        return error;
      }
    }
  },
  /** 根据uuid生成图片名称 */
  getuuidFormFileName(file) {
    let uuid = uuidv4().replace(/-/g, '');
    let name = '';

    if (file.name) {
      let array = file.name.split('.');
      name = `${uuid}.${array[array.length - 1]}`;
    }

    return name;
  },
  /** 图片压缩 */
  async getCompressorFile(file) {
    let suffix = file.name || file.url;
    let type = suffix.substr(suffix.lastIndexOf('.') + 1);
    if (!/(png|jpeg|jpg|webp)$/i.test(type)) {
      return '';
    }
    const compressedFile = await imageCompression(file, {
      maxSizeMB: 0.25,
      // maxWidthOrHeight: 1920,
      useWebWorker: true,
      initialQuality: 0.5,
      alwaysKeepResolution: true,
    });
    return compressedFile;
    // return new Promise((resolve, reject) => {
    //   new Compressor(file, {
    //     quality: 0.75,
    //     convertSize: 256 * 1024,
    //     success(result) {
    //       //压缩图片
    //       resolve(result);
    //     },
    //     error(err) {
    //       //其它不压缩
    //       resolve(file);
    //     },
    //   });
    // });
  },
  getpolicy() {
    const date = new Date();
    date.setHours(date.getHours() + 1);
    const policyText = {
      expiration: date.toISOString(), // 设置policy过期时间。
      conditions: [
        // 限制上传大小。
        ['content-length-range', 0, 1024 * 1024 * 1024],
      ],
    };

    return Base64.encode(JSON.stringify(policyText));
  },
  /** 计算签名 */
  computeSignature(accessKeySecret, canonicalString) {
    return crypto.enc.Base64.stringify(
      crypto.HmacSHA1(canonicalString, accessKeySecret)
    );
  },
  /**
   * 文件上传更新用
   * 把原后台返回图片全路径
   * 截取只要图片后半部本
   * 例: https://cloudpets-prod.oss-cn-shenzhen.aliyuncs.com/Android%20APK/CloudPet-release1.2.5(20220921).apk
   * return Android%20APK/CloudPet-release1.2.5(20220921).apk
   */
  getTempFilePath(imageUrl) {
    if (!imageUrl) return '';
    try {
      return new URL(imageUrl).pathname.substring(1);
    } catch {
      return imageUrl;
    }
  },
  /** 根据图片后半补充全路径图片 */
  getOssImageUrl(imageUrl) {
    return `${process.env.VUE_APP_OSS_IMGURL}/${imageUrl}`;
  },
  /**
   * 传入单图片全路径 或 多图片路径用,拼接
   * 截取只要图片后半部本
   */
  formatterImageUrl(imageUrl) {
    if (!imageUrl) return '';
    let newImageUrlList = imageUrl.split(',');
    return newImageUrlList.map((url) => this.getTempFilePath(url)).join(',');
  },
};