/**
 * 导出相关配置
 **/

/**
 * user: 用户模块
 * setting: 系统设置模块
*/

import user from './user'
import setting from './setting'

export default {
	user,
	setting
}
