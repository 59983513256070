/**
 * 赠品管理
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 创建买赠活动 */
  addBuyGift(data) {
    return http2({
      method: 'post',
      url: '/shop/buyGift',
      data,
    });
  },
  /** 修改买赠活动 */
  updateBuyGift(data) {
    return http2({
      method: 'put',
      url: '/shop/buyGift',
      data,
    });
  },
  /** 关闭买赠活动 */
  closeBuyGift(data) {
    return http2({
      method: 'post',
      url: '/shop/buyGift/close',
      data,
    });
  },
  /** 删除买赠活动 */
  delBuyGift(params) {
    return http({
      method: 'delete',
      url: '/shop/buyGift',
      params,
    });
  },
  /** 获取买赠活动详情 */
  getBuyGiftDetail(params) {
    return http({
      method: 'get',
      url: '/shop/buyGift/detail',
      params,
    });
  },
};
