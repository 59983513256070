/**
 * 设备API
 **/

import http from '@/utils/http'
import http2 from '@/utils/http2'
import qs from 'qs'

/* export API */
export default {
  /** 订单详情 */
  queryOrderListById(params) {
    return http({
      method: 'get',
      url: '/shop/order/queryOrderListById',
      params,
    });
  },

  /** 发货请求 */
  deliverGoods(data) {
    return http2({
      method: 'post',
      url: '/shop/order/deliverGoods',
      data,
    });
  },

  /** 查询物流 */
  getExpress(params) {
    return http({
      method: 'get',
      url: '/shop/order/getExpress',
      params,
    });
  },

  /** 售后详情 */
  returnListDetails(params) {
    return http({
      method: 'get',
      url: '/shop/order/returnListDetails',
      params,
    });
  },

  /** 获取拒绝原因列表 */
  queryShopRefundReasons(params) {
    return http({
      method: 'get',
      url: '/shop/order/queryShopRefundReasons',
      params,
    });
  },

  /** 查询退货物流 */
  getExpressRefund(params) {
    return http({
      method: 'get',
      url: '/shop/order/getExpressRefund',
      params,
    });
  },

  /** 同意退款 */
  orderReturn(data) {
    return http2({
      method: 'put',
      url: '/shop/order/orderReturn',
      data,
    });
  },

  /** 售后拒绝 */
  returnRefuse(data) {
    return http2({
      method: 'put',
      url: '/shop/order/returnRefuse',
      data,
    });
  },

  /** 退款收货地址 */
  areasReturnList(params) {
    return http({
      method: 'get',
      url: '/shop/order/areasReturnList',
      params,
    });
  },

  /** 同意退货 */
  agreeReturnGoods(data) {
    return http2({
      method: 'put',
      url: '/shop/order/agreeReturnGoods',
      data,
    });
  },

  /** 售后类型修改 */
  returnUpdate(data) {
    return http2({
      method: 'put',
      url: '/shop/order/returnUpdate',
      data,
    });
  },

  /** 售后类型修改 */
  refuseDeposit(data) {
    return http2({
      method: 'post',
      url: '/shop/order/refuseDeposit',
      data,
    });
  },

  /** 待付款----关闭订单 */
  orderClose(data) {
    return http2({
      method: 'put',
      url: '/shop/order/close',
      data,
    });
  },

  /** 子订单退款----主动退款 */
  refundItem(data) {
    return http2({
      method: 'put',
      url: '/shop/order/refundItem',
      data,
    });
  },

  /** 查询物流单号所属快递公司 */
  orderAutoNumber(params) {
    return http({
      method: 'get',
      url: '/shop/order/autonumber',
      params,
    });
  },

  /** 售后最多可退 */
  afterSalesMaxMoney(params) {
    return http({
      method: 'get',
      url: '/shop/order/afterSalesMaxMoney',
      params,
    });
  },

  /** 订单详情----修改收货地址 */
  updateAddress(data) {
    return http2({
      method: 'post',
      url: '/shop/order/updateAddress',
      data,
    });
  },

  /** 订单详情----修改物流单号 */
  updateExpress(data) {
    return http2({
      method: 'post',
      url: '/shop/order/updateExpress',
      data,
    });
  },

  /** 售后协商历史 */
  refundHistory(params) {
    return http({
      method: 'get',
      url: '/shop/order/refundHistory',
      params,
    });
  },

  /** 修改订单商家备注 */
  updateRemark(data) {
    return http2({
      method: 'put',
      url: '/shop/order/updateRemark',
      data,
    });
  },
};
