<template>
  <!-- icon图标 -->
  <i class="moe-icon" :class="['icon-moe-' + name]" :style="{ fontSize:size, color }"></i>
</template>

<script>
/**
 * 字体图标 - moe-icon
 * @description 显示字体图标...
 * @property {String} name 名称
 * @property {String} size 大小
 * @property {String} color 颜色
 **/
export default {
  name: 'moe-icon',
  props: {
    // 图标名称
    name: String,

    // 图标大小
    size: {
      type: String,
      default: '16px',
    },

    // 图标颜色
    color: {
      type: String,
      default: 'inherit',
    },
  },
}
</script>

<style scoped lang="scss">
@import './src/iconfont.css';
.moe-icon {
  font-family: 'iconfont' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: inherit;
}
</style>
