/**
 * 内容管理
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';


export default {
  /* 获取首页组件详情 */
  getHomeDetail(params) {
    return http({
      method: 'get',
      url: '/shop/home/detail',
      params,
    });
  },

  /** 提交首页组件内容 */
  updateHome(data) {
    return http2({
      method: 'post',
      url: '/shop/home',
      data,
    });
  },

  /** 修改首页组件状态 */
  updateHomeStatus(data) {
    return http2({
      method: 'put',
      url: '/shop/home/status',
      data,
    });
  },

  /** 查询所有前台分类 */
  getFrontCategoryAll() {
    return http({
      method: 'get',
      url: '/shop/frontCategory/all',
    });
  },

  /** 创建前台分类 */
  addFrontCategory(data) {
    return http2({
      method: 'post',
      url: '/shop/frontCategory',
      data,
    });
  },

  /** 修改前台分类 */
  updateFrontCategory(data) {
    return http2({
      method: 'put',
      url: '/shop/frontCategory',
      data,
    });
  },

  /** 更新前台分类状态 */
  updateFrontCategoryStatus(data) {
    return http2({
      method: 'put',
      url: '/shop/frontCategory/status',
      data,
    });
  },

  /** 查询前台分类详情 */
  getFrontCategoryDetail(params) {
    return http({
      method: 'get',
      url: '/shop/frontCategory/detail',
      params,
    });
  },

  /** 删除前台分类状态 */
  deleteFrontCategory(params) {
    return http({
      method: 'delete',
      url: '/shop/frontCategory',
      params,
    });
  },
};