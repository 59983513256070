<template>
  <!-- 表格查询组件 -->
  <div class="moe-inquire">
    <!-- <div class="moe-inquire__title">
      <i class="el-icon-search" />
      筛选查询
    </div> -->
    <!-- 表单 -->
    <el-form inline label-position="top">
      <!-- 默认插槽 -->
      <slot></slot>

      <!-- 搜索重置按钮 -->
      <el-form-item class="moe-inquire__search" label="查询">
        <div class="moe-inquire__btn">
          <el-button type="primary" icon="el-icon-search" @click="searchInfo">搜索</el-button>
          <el-button type="info" icon="el-icon-refresh" @click="resetInfo" v-if="isReset">重置</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/**
 * 查询信息 - moe-inquire
 * @description 用于页面筛选表格信息
 * @property {Boolean} isReset 显示重置按钮
 * @event {Function} search 搜索信息
 **/
export default {
  name: 'moe-inquire',
  props: {
    // 是否显示重置按钮
    isReset: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    /**
     * 搜索信息
     **/
    searchInfo() {
      this.$emit('search', true)
    },

    /**
     * 重置信息
     **/
    resetInfo() {
      this.$emit('search', false)
    },
  },
}
</script>

<style scoped lang="scss">
/* 表单筛选 */
.moe-inquire {
  background-color: #fff;
  padding: 10px;
  overflow: hidden;
  transition: all 0.3s;
  height: auto;
  min-height: 80px;
  border-radius: 2px;
  min-width: 300px;
  // margin-bottom: 15px;

  &__title {
    font-size: 16px;
    padding: 0 5px 5px 0;
  }

  /* 表单 */
  ::v-deep .el-form {
    &-item__label {
      padding-bottom: 0;
    }

    &-item {
      margin-bottom: 5px;
      // margin-left: 15px;
    }
  }

  /* 搜索按钮 */
  &__search ::v-deep .el-form-item__label {
    visibility: hidden;
  }

  &__btn {
    display: inline-block;
  }
}
</style>
