/**
 * 赠品管理
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 创建赠品活动 */
  addGift(data) {
    return http2({
      method: 'post',
      url: '/shop/gift',
      data,
    });
  },
  /** 修改赠品活动 */
  updateGift(data) {
    return http2({
      method: 'put',
      url: '/shop/gift',
      data,
    });
  },
  /** 关闭赠品活动 */
  closeGift(data) {
    return http2({
      method: 'post',
      url: '/shop/gift/close',
      data,
    });
  },
  /** 获取赠品活动详情 */
  getGiftDetail(params) {
    return http({
      method: 'get',
      url: '/shop/gift/detail',
      params,
    });
  },
  /** 查询已使用的商品sku */
  getGiftUseSkus(params) {
    return http({
      method: 'get',
      url: '/shop/gift/usedSkus',
      params,
    });
  },
  /** 批量获取赠品详情 */
  getGiftListByIds(params) {
    return http({
      method: 'get',
      url: '/shop/gift/listByIds',
      params,
    });
  },
};