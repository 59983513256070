/**
 * 九宫格抽奖
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';

export default {
  /** 创建抽奖活动 */
  addLottery(data) {
    return http2({
      method: 'post',
      url: '/shop/lottery',
      data,
    });
  },
  /** 修改抽奖活动 */
  updateLottery(data) {
    return http2({
      method: 'put',
      url: '/shop/lottery',
      data,
    });
  },
  /** 关闭抽奖活动 */
  closeLottery(data) {
    return http2({
      method: 'post',
      url: '/shop/lottery/close',
      data,
    });
  },
  /** 新增抽奖奖品配置 */
  updateLotteryItem(data) {
    return http2({
      method: 'post',
      url: '/shop/lottery/item',
      data,
    });
  },
  /** 获取抽奖活动详情 */
  getLotteryDetail(params) {
    return http({
      method: 'get',
      url: '/shop/lottery/detail',
      params,
    });
  },
};
