<template>
  <el-option :label="label" :value="value"></el-option>
</template>

<script>
export default {
  name: 'moeOption',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: ''
    },
  },
}
</script>