/**
 * 主图打标
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 创建主图打标 */
  addGoodsBadge(data) {
    return http2({
      method: 'post',
      url: '/shop/goodsBadge',
      data,
    });
  },
  /** 修改主图打标 */
  updateGoodsBadge(data) {
    return http2({
      method: 'put',
      url: '/shop/goodsBadge',
      data,
    });
  },
  /** 结束主图打标 */
  closeGoodsBadge(data) {
    return http2({
      method: 'post',
      url: '/shop/goodsBadge/close',
      data,
    });
  },
  /** 删除主图打标 */
  delGoodsBadge(params) {
    return http({
      method: 'delete',
      url: '/shop/goodsBadge',
      params,
    });
  },
  /** 获取主图打标详情 */
  getGoodsBadgeDetail(params) {
    return http({
      method: 'get',
      url: '/shop/goodsBadge/detail',
      params,
    });
  },
};
