<template>
  <!-- 页面组件 -->
  <div class="moe-page">
    <!-- <div class="moe-page__head">
      <h2 class="moe-page__title">{{ meta.title || title }}</h2>
    </div> -->
    <div class="moe-page__inner">
      <slot />
    </div>
  </div>
</template>

<script>
/**
 * 页面 - moe-page
 * @description 每个页面的包裹组件
 * @property {String} title 标题
 * @property {String} desc 描述
 * @property {Boolean} isBack 是否显示返回按钮
 **/
export default {
  name: 'moe-page',
  props: ['title', 'isBack'],
  model: {
    event: 'onShow',
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  /** 当其选项卡的内容变得可见或被隐藏时，会触发 visibilitychange 事件. */
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.$emit('onShow')
      }
    })
  },
  /** 组件销毁时，移除visibilitychange监听函数 */
  destroyed() {
    document.removeEventListener('visibilitychange', () => {})
  }
};
</script>

<style lang="scss" scoped>
.moe-page {
  position: relative;
  margin: 5px 5px 0;
  height: calc(100% - 5px);
  &__head {
    padding: 7px 20px;
    padding-bottom: 3px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }
  &__title {
    font-size: 15px;
    font-weight: normal;
    margin: 7px 0;
    color: #333;
  }

  &__inner {
    // height: calc(100% - 60px);
    height: calc(100%);
    border-radius: 5px;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    ::v-deep .l-describe-list{
      padding: 15px 0;
      .l-describe__title {
        padding-left: 15px;
        padding-top: 5px;
        margin-bottom: 0;
      }
    }
  }

}
</style>
