/**
  * 商品管理
  **/

import http from '@/utils/http'
import http2 from '@/utils/http2'
import qs from 'qs'

/** export API */
export default {
  /** 活动详情 */
  activityAmendDetail(params) {
    return http({
      method: 'get',
      url: '/shop/activity/queryShopActivityById',
      params,
    });
  },

  /** 活动修改 */
  activityAmend(data) {
    return http({
      method: 'post',
      url: '/shop/activity/updateShopActivity',
      data: qs.stringify(data),
    });
  },

  /** 活动状态修改 */
  activityStatus(data) {
    return http({
      method: 'post',
      url: '/shop/activity/shopActivityShelf',
      data: qs.stringify(data),
    });
  },

  /* 活动删除 */
  activityDelete(params) {
    return http({
      method: 'get',
      url: '/shop/activity/deleteShopActivity',
      params,
    });
  },

  /** 活动添加 */
  activityAdd(data) {
    return http({
      method: 'post',
      url: '/shop/activity/insertShopActivity',
      data: qs.stringify(data),
    });
  },

  /** 活动商品列表 */
  activityGoodsDetail(params) {
    return http({
      method: 'get',
      url: '/shop/activity/getShopActivityGoods',
      params,
    });
  },

  /** 新增活动下商品 */
  insertShopActivityGoods(data) {
    return http({
      method: 'post',
      url: '/shop/activity/insertShopActivity',
      data,
    });
  },

  /** 更新活动下商品 */
  updateActivityGoods(data) {
    return http2({
      method: 'post',
      url: '/shop/activity/updateGoods',
      data,
    });
  },

  /** 移出活动商品 */
  deleteActivitShopGoods(params) {
    return http({
      method: 'get',
      url: '/shop/activity/deleteActivitShopGoods',
      params,
    });
  },

  /** 商品规格管理添加 */
  specsAdd(data) {
    return http({
      method: 'post',
      url: '/shop/specs/insertShopSpecs',
      data: qs.stringify(data),
    });
  },

  /** 商品规格值详情 */
  specsAmendDetail(params) {
    return http({
      method: 'get',
      url: '/shop/specs/queryShopSpecsById',
      params,
    });
  },

  /** 商品规格修改 */
  specsUpdate(data) {
    return http({
      method: 'post',
      url: '/shop/specs/updateShopSpecs',
      data: qs.stringify(data),
    });
  },

  /** 商品规格值详情 */
  specsValueDetail(params) {
    return http({
      method: 'get',
      url: '/shop/specs/getShopSpecsValue',
      params,
    });
  },

  /** 商品规格值新增 */
  insertShopSpecsValue(data) {
    return http2({
      method: 'post',
      url: '/shop/specs/insertShopSpecsValue',
      data,
    });
  },

  /** 商品规格值删除 */
  deleteShopSpecsValue(params) {
    return http2({
      method: 'get',
      url: '/shop/specs/deleteShopSpecsValue',
      params,
    });
  },

  /** 商品详情 */
  goodsAmendDetail(params) {
    return http({
      method: 'get',
      url: '/shop/goods/queryShopGoodsById',
      params,
    });
  },

  /** 获取规格列表 */
  querySpecsList(params) {
    return http({
      method: 'get',
      url: '/shop/specs/querySpecsList',
      params,
    });
  },

  /** 规格删除 */
  deleteShopSpecs(params) {
    return http({
      method: 'get',
      url: '/shop/specs/deleteShopSpecs',
      params,
    });
  },

  /** 商品删除 */
  goodsDelete(params) {
    return http({
      method: 'get',
      url: '/shop/goods/deleteShopGoods',
      params,
    });
  },

  /** 新增商品 */
  insertShopGoods(data) {
    return http({
      method: 'post',
      url: '/shop/goods/insertShopGoods',
      data: qs.stringify(data),
    });
  },

  /** 查询商品详情 */
  queryShopGoodsById(params) {
    return http({
      method: 'get',
      url: '/shop/goods/queryShopGoodsById',
      params,
    });
  },

  /* 修改商品 */
  updateShopGoods(data) {
    return http({
      method: 'post',
      url: '/shop/goods/updateShopGoods',
      data: qs.stringify(data),
    });
  },

  /** 新增商品规格 */
  goodsSpecInsert(data) {
    return http2({
      method: 'post',
      url: '/shop/goods/goodsSpecInsert',
      data,
    });
  },

  /** 查询商品规格规格 */
  queryShopGoodsSpec(params) {
    return http({
      method: 'get',
      url: '/shop/goods/queryShopGoodsSpec',
      params,
    });
  },

  /** 新增商品SKU */
  insertShopGoodsItemList(data) {
    return http2({
      method: 'post',
      url: '/shop/goods/insertShopGoodsItemList',
      data,
    });
  },

  /** 修改商品SKU */
  updateShopGoodsItemJava(data) {
    return http2({
      method: 'post',
      url: '/shop/goods/updateShopGoodsItemJava',
      data,
    });
  },

  /** 商品上下架 */
  shopGoodsShelf(data) {
    return http({
      method: 'post',
      url: '/shop/goods/shopGoodsShelf',
      data: qs.stringify(data),
    });
  },

  /** 回收站商品恢复到仓库 */
  shopGoodsReduction(data) {
    return http({
      method: 'post',
      url: '/shop/goods/shopGoodsReduction',
      data: qs.stringify(data),
    });
  },

  /** 获取商品一二三级分类 */
  categoryListTree() {
    return http({
      method: 'get',
      url: '/shop/goods/categoryListTree',
    });
  },

  /** 获取商品一二三级分类 */
  categoryVo(params) {
    return http({
      method: 'get',
      url: '/shop/goods/categoryVo',
      params,
    });
  },

  /** 新增三级分类 */
  insertCategory(data) {
    return http2({
      method: 'post',
      url: '/shop/goods/insertCategory',
      data,
    });
  },

  /** 修改三级分类 */
  updateCategory(data) {
    return http2({
      method: 'post',
      url: '/shop/goods/updateCategory',
      data,
    });
  },

  /** 删除分类规格 */
  delCategorySpec(params) {
    return http({
      method: 'get',
      url: '/shop/goods/delCategorySpec',
      params,
    });
  },

  /** 删除商品分类 */
  delCategory(params) {
    return http({
      method: 'get',
      url: '/shop/goods/delCategory',
      params,
    });
  },

  /** 商品分类上下架 */
  shelfCategory(params) {
    return http({
      method: 'get',
      url: '/shop/goods/shelfCategory',
      params,
    });
  },

  /** 新增商品（新） */
  insertGoods(data) {
    return http2({
      method: 'post',
      url: '/shop/goods/insertGoods',
      data,
    });
  },

  /** 修改商品（新） */
  updateGoods(data) {
    return http2({
      method: 'post',
      url: '/shop/goods/updateGoods',
      data,
    });
  },

  /** 新增热门搜索 */
  searchAdd(data) {
    return http({
      method: 'post',
      url: '/shop/search/add',
      data: qs.stringify(data),
    });
  },

  /** 热门搜索详情 */
  searchDetails(params) {
    return http({
      method: 'get',
      url: '/shop/search/details',
      params,
    });
  },

  /** 修改热门搜索 */
  searchUpdate(data) {
    return http({
      method: 'post',
      url: '/shop/search/update',
      data: qs.stringify(data),
    });
  },

  /** 删除热门搜索 */
  searchDelete(params) {
    return http({
      method: 'get',
      url: '/shop/search/del',
      params,
    });
  },

  /** 热门搜索上下架 */
  searchShelf(params) {
    return http({
      method: 'get',
      url: '/shop/search/shelf',
      params,
    });
  },

  updateActivitGoodsSort(params) {
    return http({
      method: 'put',
      url: '/shop/activity/updateActivitGoodsSort',
      params,
    });
  },

  /** 修改商品虚拟销量 */
  shopGoodsFakeBuyNum(data) {
    return http({
      method: 'patch',
      url: '/shop/goods/patchShopGoods',
      data,
    });
  },

  /** 根据商品ID获取商品列表 */
  getQueryShopGoodsByIds(params) {
    return http({
      method: 'get',
      url: '/shop/goods/queryShopGoodsByIds',
      params,
    });
  },

  /** 修改商品sku库存 */
  updateShopGoodsItemStock(data) {
    return http2({
      method: 'patch',
      url: '/shop/goods/updateShopGoodsItemStock',
      data,
    });
  },

  /** 批量商品上下架 */
  shopGoodsShelfBatch(data) {
    return http2({
      method: 'post',
      url: '/shop/goods/shopGoodsShelfBatch',
      data,
    });
  },

  /** 获取商品推荐列表 */
  getGoodsRecommendList(params) {
    return http({
      method: 'get',
      url: '/shop/goods/recommend/list',
      params,
    });
  },

  /** 更新商品推荐活动信息 */
  updateGoodsRecommendList(data) {
    return http2({
      method: 'put',
      url: '/shop/goods/recommend',
      data,
    });
  },
};
