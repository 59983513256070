/**
  -- Created by Lichuangwei on 2023/09/28
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 系统管理
***/

import BlankView from '@/views/BlankView.vue';

const SystemManage = [
  {
    path: 'systemManage',
    name: 'SystemManage',
    meta: {
      title: '系统管理',
      path: '/systemManage/returnAreas/list',
    },
    component: BlankView,
    children: [
      {
        path: '/systemManage/returnAreas/list',
        name: 'SystemManageReturnAreasList',
        meta: {
          title: '',
          path: '/systemManage/returnAreas/list',
          affix: true,
        },
        component: () => import('@/views/SystemManage/returnAreas/list.vue'),
      },
    ],
  },
];

export default SystemManage;