<template>
  <el-checkbox-group
    class="moe-checkbox-group df dac fww"
    v-model="checkList"
    @change="changeHandle">
    <div class="df moe-checkbox-group-item fww mb-20 mr-20" v-for="(item, index) in checkboxList" :key="index">
      <el-checkbox class="moe-checkbox-group-item-checkbox" :border="border" :disabled="item.disabled" :label="item.value">{{ item.value }}</el-checkbox>
      <!-- <div class="pr-10 df aic" v-if="!item.id">
        <el-button @click="deleteHandle(index)" size="medium" class="moe-checkbox-group-item-button color-gray2" type="text" icon="el-icon-delete"></el-button>
      </div> -->
    </div>
  </el-checkbox-group>
</template>

<script>
export default {
  name: 'moeCheckboxGroup',
  model: {
    props: 'value',
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    checkboxList: {
      type: Array,
      default: () => []
    },
    border: { //是否显示边框
      type: Boolean,
      default: false
    },
    change: {
      type: Function,
      default:() => {}
    }
  },
  watch: {
    value(val) {
      this.checkList = val;
    }
  },
  data() {
    return {
      checkList: this.value
    }
  },
  methods: {
    changeHandle(checkList) {
      this.$emit('input', checkList);
      this.$emit('change', checkList);
    },
    deleteHandle(index) {
      this.$emit('checkboxList', this.checkboxList.splice(index, 1));
    },
  },
}
</script>

<style lang="scss" scoped>
.moe-checkbox-group {
  &-item {
    height: 100%;
    &-checkbox {
      height: 36px;
      margin-right: 10px !important;
    }
    &-button {
      height: 36px;
    }
  }
}
</style>