<template>
  <div class="l-tab">
    <el-tabs
      class="l-tab__list"
      :value="$route.path"
      @tab-click="(e) => $router.push(e.name)"
    >
      <el-tab-pane
        :label="item.title"
        :name="item.path"
        v-for="item of tabList"
        :key="item.path"
        :closable="false"
      />
    </el-tabs>
    <!-- 右侧选项卡 -->
    <el-dropdown size="default" @command="onTabMenuClick">
      <el-dropdown-menu slot="dropdown" style="padding-bottom: 0px">
        <!-- <el-dropdown-item command="refresh"
          ><i class="el-icon-refresh" />刷新页面</el-dropdown-item
        > -->
        <el-dropdown-item command="left"
          ><i class="el-icon-back" />关闭左侧</el-dropdown-item
        >
        <el-dropdown-item command="right"
          ><i class="el-icon-right" />关闭右侧</el-dropdown-item
        >
        <el-dropdown-item command="all"
          ><i class="el-icon-close" />关闭其他</el-dropdown-item
        >
      </el-dropdown-menu>
      <el-button
        class="l-tab__btn"
        type="text"
        size="small"
        icon="el-icon-arrow-down"
        >标签栏工具</el-button
      >
    </el-dropdown>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'tab-view',

  methods: Object.assign(
    {
      /**
       * 点击tab工具栏
       **/
      onTabMenuClick(key) {
        let index = this.tabList.findIndex((k) => k.path === this.$route.path);
        let tabList = [];

        if (key === 'refresh') {
          this.setIsRefresh();
        } else if (key === 'left') {
          tabList = this.tabList.filter((k, i) => i >= index);
        } else if (key === 'right') {
          tabList = this.tabList.filter((k, i) => i <= index);
        } else if (key === 'all') {
          tabList = this.tabList.filter((k, i) => i === index);
        }
        this.setTabList(tabList);
      },
    },

    mapMutations('setting', ['setTabList'])
  ),

  /**
   * 系统数据
   * menuList: 左侧菜单栏列表
   * collapse: 左侧菜单栏是否收缩
   */
  computed: mapState('setting', ['tabList']),
};
</script>

<style lang="scss" scoped>
.l-tab {
  display: flex;
  overflow: hidden;
  padding-left: 15px;
  height: 40px;
  overflow: hidden;
  background-color: #fff;

  ::v-deep .el-tabs__header {
    margin-bottom: 1px;
  }

  ::v-deep .el-tabs__nav-wrap::after {
    display: none;
  }

  .l-tab__list {
    flex-grow: 1;
    overflow: hidden;
  }

  .l-tab__btn {
    width: 90px;
    height: 100%;
    border-radius: 0;
  }
}
</style>
