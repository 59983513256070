/**
  -- Created by Lichuangwei on 2024/1/5
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 账单管理
  -- 1.供应商账单
* */

import BlankView from '@/views/BlankView.vue';

const BillingManage = [
  {
    path: 'billingManage',
    name: 'BillingManage',
    meta: {
      title: '账单管理',
      path: '/billingManage/supplierBilling/list',
    },
    component: BlankView,
    children: [
      // 供应商账单
      {
        path: '/billingManage/supplierBilling/list',
        name: 'BillingManageSupplierBillingList',
        meta: {
          title: '供应商账单',
          path: '/billingManage/supplierBilling/list',
          affix: true,
        },
        component: () => import('@/views/BillingManage/SupplierBilling/List.vue'),
      },
      {
        path: '/billingManage/supplierBilling/detail',
        name: 'BillingManageSupplierBillingDetail',
        meta: {
          title: '账单详情',
          path: '/billingManage/supplierBilling/detail',
          affix: true,
        },
        component: () => import('@/views/BillingManage/SupplierBilling/Detail.vue'),
      },
    ],
  },
];

export default BillingManage;