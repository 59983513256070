/**
  * 供应商账单
  **/

import http from '@/utils/http';

export default {
  /** 查询供应商账单详情 */
  getVendorBillDetail(params) {
    return http({
      method: 'get',
      url: '/shop/vendor/billDetail',
      params,
    });
  },
};