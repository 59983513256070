/**
 * 广告管理
 **/

import http from '@/utils/http'
import qs from 'qs'


export default {
  /* 新增APP广告 */
  appAdvertAdd(data) {
    return http({
      method: 'post',
      url: '/AppAdvert/add',
      data: qs.stringify(data)
    })
  },

  /* APP广告详情 */
  appAdvertDetails(params) {
    return http({
      method: 'get',
      url: '/AppAdvert/details',
      params
    })
  },

  /* 修改APP广告 */
  appAdvertUpdate(data) {
    return http({
      method: 'post',
      url: '/AppAdvert/update',
      data: qs.stringify(data)
    })
  },

  /* 删除APP广告 */
  appAdvertDel(params) {
    return http({
      method: 'get',
      url: '/AppAdvert/del',
      params
    })
  },

  /* APP广告上下架 */
  appAdvertShelf(params) {
    return http({
      method: 'get',
      url: '/AppAdvert/shelf',
      params
    })
  },
}