/**
 * 签到活动
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 获取签到活动信息 */
  getSignDetail() {
    return http({
      method: 'get',
      url: '/shop/sign',
    });
  },
  /** 更新签到活动信息 */
  updateSign(data) {
    return http2({
      method: 'put',
      url: '/shop/sign',
      data,
    });
  },
};