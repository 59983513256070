/**
 * 角色API
 **/

import http from '@/utils/http'
import http2 from '@/utils/http2'
import qs from 'qs'

/* export API */
export default {
    /* 角色列表 */
    roleList(params) {
        return http({
            method: 'get',
            url: '/role/pageList',
            params
        })
    },

    /* 角色详情 */
    roleDetail(params) {
        return http({
            method: 'get',
            url: '/role/info',
            params
        })
    },

    /* 角色所有列表 */
    roleListAll(params) {
        return http({
            method: 'get',
            url: '/role/allList',
            params
        })
    },

    /* 角色添加 */
    roleAdd(data) {
        return http2({
            method: 'post',
            url: '/role/add',
            data: data
        })
    },


    /* 角色修改 */
    roleAmend(data) {
        return http2({
            method: 'post',
            url: '/role/update',
            data: data
        })
    },

    /* 角色修改 */
    roleDelete(params) {
        return http({
            method: 'get',
            url: '/role/delete',
            params
        })
    },

    /* 所有权限菜单 */
    allAuthList() {
        return http({
            method: 'get',
            url: '/role/menu/all'
        })
    },

    /* 菜单添加 */
    menuAdd(data) {
        return http({
            method: 'post',
            url: '/role/menu',
            data:qs.stringify(data)
        })
    },
    /* 菜单详情 */
    menuDetail(params) {
        return http({
            method: 'get',
            url: 'role/menu/'+params
        })
    },
    /* 菜单修改 */
     menuAmend(data) {
        return http({
            method: 'put',
            url: '/role/menu',
            data:qs.stringify(data)
        })
    },
     /* 菜单删除 */
     menuDelete(params) {
        return http({
            method: 'delete',
            url: 'role/menu/'+params
        })
    },

}