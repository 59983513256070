/**
 * 猜你喜欢
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 获取猜你喜欢信息 */
  getGoodsGuessList(params) {
    return http({
      method: 'get',
      url: '/shop/goods/guess',
      params,
    });
  },

  /** 更新猜你喜欢信息 */
  updateGoodsGuessList(data) {
    return http2({
      method: 'put',
      url: '/shop/goods/guess',
      data,
    });
  },
};