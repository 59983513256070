/**
 * 助力拉新活动
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 创建助力拉新活动 */
  addAssist(data) {
    return http2({
      method: 'post',
      url: '/shop/assist',
      data,
    });
  },
  /** 修改助力拉新活动 */
  updateAssist(data) {
    return http2({
      method: 'put',
      url: '/shop/assist',
      data,
    });
  },
  /** 获取助力拉新活动详情 */
  getAssistDetail(params) {
    return http({
      method: 'get',
      url: '/shop/assist/detail',
      params,
    });
  },
  /** 关闭助力拉新活动 */
  closeAssist(data) {
    return http2({
      method: 'post',
      url: '/shop/assist/close',
      data,
    });
  },
  /** 删除助力拉新活动 */
  delAssist(params) {
    return http({
      method: 'delete',
      url: '/shop/assist',
      params,
    });
  },
  /** 助力拉新活动记录列表 */
  getAssistRecordList(params) {
    return http({
      method: 'get',
      url: '/shop/assist/recordList',
      params,
    });
  },
};
