/**
 * 秒杀活动
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 创建秒杀活动 */
  addFlash(data) {
    return http2({
      method: 'post',
      url: '/shop/flash',
      data,
    });
  },
  /** 修改秒杀活动 */
  updateFlash(data) {
    return http2({
      method: 'put',
      url: '/shop/flash',
      data,
    });
  },
  /** 关闭秒杀活动 */
  closeFlash(data) {
    return http2({
      method: 'post',
      url: '/shop/flash/close',
      data,
    });
  },
  /** 删除秒杀活动 */
  delFlash(params) {
    return http({
      method: 'delete',
      url: '/shop/flash',
      params,
    });
  },
  /** 获取秒杀活动详情 */
  getFlashDetail(params) {
    return http({
      method: 'get',
      url: '/shop/flash/detail',
      params,
    });
  },
};
