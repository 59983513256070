/**
 * 系统API
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import http3 from '@/utils/http3';
import qs from 'qs';

/* export API */
export default {
  /** 获取展示的地址 */
  getQueryAreas(params) {
    return http({
      method: 'get',
      url: '/areas/queryAreas',
      params,
    });
  },
  /** 创建退货地址 */
  addReturnAreas(data) {
    return http2({
      method: 'post',
      url: '/shop/returnAreas',
      data,
    });
  },
  /** 查询退货地址详情 */
  getReturnAreasDetail(params) {
    return http({
      method: 'get',
      url: '/shop/returnAreas/detail',
      params,
    });
  },
  /** 修改退货地址 */
  updateReturnAreas(data) {
    return http2({
      method: 'put',
      url: '/shop/returnAreas',
      data,
    });
  },
  /** 删除退货地址 */
  delReturnAreas(params) {
    return http({
      method: 'delete',
      url: '/shop/returnAreas',
      params,
    });
  },
  /** 更新退货地址状态 */
  updateReturnAreasStatus(data) {
    return http2({
      method: 'put',
      url: '/shop/returnAreas/status',
      data,
    });
  },

  /** 获取大区列表 */
  getAreasBigAreas(params) {
    return http({
      method: 'get',
      url: '/areas/bigAreas',
      params,
    });
  },
  /** 获取地址列表集合 */
  getAreasJsonList() {
    return http3({
      method: 'get',
      url: '/app/areas.json',
    });
  },
};