<template>
  <!-- 级联选择器 -->
  <el-cascader class="moe-cascader"
    v-model="cascaderValue"
    :options="options"
    :disabled="disabled"
    @change="changeHandle"
    :props="{ checkStrictly, multiple }"
    :placeholder="placeholder"
    :style="{ width: width }"
    :filterable="filterable"
    clearable>
    <template slot-scope="{ node, data }">
      <span>{{ data.label }}</span>
      <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
    </template>
  </el-cascader>
</template>

<script>
export default {
  name: 'moeCascader',
  model: {
    props: 'value',
    event: 'change',
  },
  props: {
    value: {        // v-model 选中项绑定值
      type: Array,
      default: () => [],
    },
    options: {      // 可选项数据源，键名可通过 Props 属性配置
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: '100%'
    },
    checkStrictly: {  // 是否严格的遵守父子节点不互相关联
      type: Boolean,
      default: false,
    },
    disabled: {       // 禁用状态
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    filterable: {
      default: true   // 是否可搜索选项
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(val) {
      this.cascaderValue = val;
    }
  },
  data() {
    return {
      cascaderValue: this.value
    }
  },
  methods: {
    changeHandle(cascaderValue) {
      this.$emit('input', cascaderValue);
      this.$emit('change', cascaderValue);
    },
  },
}
</script>

<style lang="scss" scoped>
.moe-cascader {}
</style>