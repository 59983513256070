/**
 * @Name Router
 * @Describe 路由配置
 **/

import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import getAuthority from './authority'
import store from '@/store'
import 'nprogress/nprogress.css'
import publicRoute from './public-route';

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'hash',
	routes: publicRoute
});

/**
 * 重写路由push方法
 **/
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(error => error);
}

/**
 * 重写路由replace方法
 **/
const routerReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
	return routerReplace.call(this, location).catch(error => error);
}

/**
 * 重置路由方法
 **/
VueRouter.prototype.resetRoutes = function () {
	sessionStorage.clear();

	store.commit('setting/setMenuList', []);
	store.commit('setting/setTabList', []);
	router.matcher = new VueRouter({
		routes: publicRoute
	}).matcher;
}

/**
 * 全局监听路由前置钩子
 **/
router.beforeEach(async (to, from, next) => {
	NProgress.start();
	document.title = to.meta.title || '云宠智能后台管理系统';

	//是否获取权限
	const isAuth = store.state.user.isAuth;

	if (isAuth) {
		getAuthority().then(() => next(to));
	}else {
		if (to.name) {
			next();
		} else {
			next({
				path: '/not-found',
				replace: true,
			});
		}
		store.commit('setting/setPageLoad', false);
	}
});

/**
 * 全局监听路由后置钩子
 **/
router.afterEach(() => {
	NProgress.done();
});

export default router;
