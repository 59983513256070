/**
  -- Created by Lichuangwei on 2023/09/27
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 供应商管理
***/

import BlankView from '@/views/BlankView.vue';

const SupplierManage = [
  {
    path: 'supplierManage',
    name: 'SupplierManage',
    meta: {
      title: '供应商管理',
      path: '/supplierManage/list',
    },
    component: BlankView,
    children: [
      {
        path: '/supplierManage/list',
        name: 'SupplierManageList',
        meta: {
          title: '供应商管理',
          path: '/supplierManage/list',
          affix: true,
        },
        component: () => import('@/views/SupplierManage/list.vue'),
      },
      {
        path: '/supplierManage/add',
        name: 'SupplierManageAdd',
        meta: {
          title: '供应商管理',
          path: '/supplierManage/add',
          affix: true,
        },
        component: () => import('@/views/SupplierManage/add.vue'),
      },
    ],
  },
];

export default SupplierManage;