/**
 * @Module Http
 * @Describe 服务请求
 **/

import axios from 'axios';
import message from './message';
import config from '@/config';
import router from '@/router';

axios.defaults.baseURL = config.serveUrl;
import { app } from '../main';
import USER_API from '../api/modules/user';

//axios请求配置
const server = axios.create({
    // baseURL: config.serveUrl, //服务器地址
    timeout: 1000 * 60, //请求超时时间
});

//axios请求拦截器，向服务器发起请求，设置token
server.interceptors.request.use((config) => {
    let token = sessionStorage.getItem('token');
    let sensitiveToken = sessionStorage.getItem('sensitiveToken');
    config.headers['authorization'] = token || ''; //设置请求头
    config.headers['X-Sensitive-Token'] = sensitiveToken || ''; //敏感操作验证 token
    return config;
}, function (err) {
    //请求错误
    return Promise.reject(err);
});

//axios响应拦截器，获取响应数据
server.interceptors.response.use((res) => {
    //获取响应数据
    let data = res.data;
    if (data.code === 4002) {
        let timeout = setTimeout(() => {
            app.$prompt('', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPlaceholder: '请输入操作密码',
                inputErrorMessage: '',
                closeOnClickModal: false,
                distinguishCancelAndClose: true,
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        if (!instance.inputValue) {
                            return app.$message.warning('请输入操作密码');
                        }
                        instance.confirmButtonLoading = true;
                        instance.confirmButtonText = '请稍候...';
                        USER_API.sensitiveCheck({ pwd: instance.inputValue }).then((data) => {
                            if (data.code === 200) {
                                sessionStorage.setItem('sensitiveToken', data.result.token);
                                done();
                            } else {
                                app.$message.error(data.message);
                            }
                        }).finally(() => {
                            instance.confirmButtonLoading = false;
                            instance.confirmButtonText = '确定';
                            clearTimeout(timeout);
                        })
                    } else if (action === 'cancel') {
                        done();
                    }
                }
            })
        }, 1000)
    } else if (data.code == 401) {
        router.push('/login');
        router.resetRoutes();
        message.closeAll();
        message.error('未授权，请重新登录 !');
    } else {
        return data;
    }
}, function (err) {
    //服务器响应错误
    message.closeAll();
    let msg = '服务器异常，请稍后重试 !';
    if (err && err.response) {
        msg = getErrorStatus(err.response.status);
    }
    message.error(msg);
    return Promise.reject(err);
});


// 返回错误请求信息
function getErrorStatus(status) {
    const map = new Map([
        [400, '错误请求 !'],
        [401, '未授权，请重新登录 !'],
        [403, '拒绝访问 !'],
        [404, '请求错误，未找到该资源 !'],
        [405, '请求方法未允许 !'],
        [408, '请求超时 !'],
        [500, '服务器端出错 !'],
        [501, '网络未实现 !'],
        [502, '网络错误 !'],
        [503, '服务不可用 !'],
        [504, '网络超时 !'],
        [505, 'http版本不支持该请求 !'],
        ['default', `连接错误${status} !`]
    ]);

    return map.get(status) || map.get('default');
}

/* export request */
export default server.request;