import { Decimal } from 'decimal.js'

/**
  * 计算比例，限制文件上传
  */
export const reductionTo = (m, n) => {
  var arr = [];
    if (!isInteger(m) || !isInteger(n)) {
        console.log('m和n必须为整数');
        return;
    } else if (m<=0||n <= 0) {
        console.log('m和n必须大于0');
        return;
    }
    var a = m;
    var b = n;
    (a >= b) ? (a = m, b = n) : (a = n, b = m);
    if (m != 1 && n != 1) {
        for (var i = b; i >= 2; i--) {
            if (m % i == 0 && n % i == 0) {
                m = m / i;
                n = n / i;
            }
        }
    }
    arr[0] = m;
    arr[1] = n;
    return arr;
}

//判断一个数是否为整数
function isInteger(obj) {
    return obj % 1 === 0
}

/**
  * 加法
  */
export const mathAdd = (number1, number2) => {
  return Decimal.add(number1 || 0, number2 || 0).toNumber();
}

/**
  * 减法
  */
export const mathSub = (number1, number2) => {
  return Decimal.sub(number1 || 0, number2 || 0).toNumber();
}

/**
  * 乘法
  */
export const mathMul = (number1, number2) => {
  return Decimal.mul(number1 || 0, number2 || 0).toNumber();
}

/**
  * 除法
  * 使用除法时候要注意 除数（分母）不能为0。
  */
export const mathDiv = (number1, number2) => {
  return Decimal.div(number1 || 0, number2 || 0).toNumber();
}

/**
  * 取绝对值
  */
export const mathAbs = (number) => {
  return Decimal.abs(number).toNumber()
}

/**
  * 比较
  * 大于 >
  */
export const mathGreaterThan = (number1, number2) => {
  return new Decimal(number1 || 0).greaterThan(number2 || 0);
}

/**
  * 比较
  * 大于等于 >=
  */
export const mathGreaterThanOrEqualTo = (number1, number2) => {
  return new Decimal(number1 || 0).greaterThanOrEqualTo(number2 || 0);
}

/**
  * 比较
  * 小于 <
  */
export const mathLessThan = (number1, number2) => {
  return new Decimal(number1 || 0).lessThan(number2 || 0);
}

/**
  * 比较
  * 小于等于 <=
  */
export const mathLessThanOrEqualTo = (number1, number2) => {
  return new Decimal(number1 || 0).lessThanOrEqualTo(number2 || 0);
}

/**
  * 比较
  * 等于 =
  */
export const mathEquals = (number1, number2) => {
  return new Decimal(number1 || 0).equals(number2 || 0);
}

export const mathMin = (ArrayNumber) => {
  return Decimal.min(ArrayNumber)
}

export const mathMax = (ArrayNumber) => {
  return Decimal.max(ArrayNumber)
}

// const num = new Decimal('123.096');
// const rounded = num.toDecimalPlaces(2, Decimal.ROUND_UP)
// console.log('rounded', rounded.toNumber())

export default {
    mathAdd,
    mathSub,
    mathMul,
    mathDiv,
    mathAbs,
    mathGreaterThan,
    mathGreaterThanOrEqualTo,
    mathLessThan,
    mathLessThanOrEqualTo,
    mathEquals,
    mathMin,
    mathMax,
}