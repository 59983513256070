<template>
  <div id="app" v-loading="pageLoad">
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'App',
  computed: mapState('setting', ['pageLoad']),
};
</script>

<style lang="scss">
@import '@/theme/public';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  width: 100vw;
  height: 100vh;
}

#nprogress .bar {
  background: #dfa663 !important;
}
</style>
