/**
 * 商品评价
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 更新商品评显示状态 */
  updateState(data) {
    return http2({
      method: 'post',
      url: '/shop/goods/review/updateState',
      data,
    });
  },
  /** 更新商品评价置顶状态 */
  updatePin(data) {
    return http2({
      method: 'post',
      url: '/shop/goods/review/updatePin',
      data,
    });
  },
};
