<template>
  <el-checkbox
    class="moe-checkbox"
    v-model="defaultValue"
    :border="border"
    :disabled="disabled"
    :size="size"
    :trueLabel="trueLabel"
    :falseLabel="falseLabel"
    :indeterminate="isIndeterminate"
    @change="handleChange">
    <slot />
  </el-checkbox>
</template>

<script>
/**
 * 下拉框 - moe-checkbox
 * @description 基于el-checkbox组件的二次封装
 * @property {String | Number | Boolean} value 选中value
 * @property {Boolean} border 是否显示边框
 * @property {Boolean} disabled 是否禁用
 * @property {String | Number} trueLabel 选中时的值        注:Boolean 类型报错
 * @property {String | Number} falseLabel 没有选中时的值    注:Boolean 类型报错
 * @property {String} size Checkbox 的尺寸，仅在 border 为真时有效 medium / small / mini
 * @event {Function} change 当绑定值变化时触发的事件
 **/
export default {
  name: 'moe-checkbox',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    border: {
      type: Boolean,
      default: false,
    },
    trueLabel: {
      type: [String, Number, Boolean],
      default: '',
    },
    falseLabel: {
      type: [String, Number, Boolean],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '-',
    },
    isIndeterminate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultValue: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.defaultValue = newValue
    }
  },
  methods: {
    handleChange(e) {
      this.$emit('change', e)
    },
  }
}
</script>

<style lang="scss" scoped>

</style>