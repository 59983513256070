/**
 * Created by zed on 2020/9/2
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 本地存储工具类，注意清除缓存时会导致保存的数据丢失
 */
// import Cookies from 'js-cookie'
// import Logger from '@/utils/logger'

// const logger = new Logger('本地存储工具「/utils/storage」', Logger.ERROR)

/* 本地存储 */
const storage = {
  /**
   * 同步存储「键值-数据」至本地缓存，会覆盖相同键值的数据
   * @param key   键值字符串
   * @param data  数据字符串
   */
  set(key = '', data = null) {
    // Cookies.set(key, data)
    // logger.debug('同步存储', key + ' - ' + data)
    localStorage.setItem(key, data)
  },
  /**
   * 同步读取本地缓存中键值对应的数据
   * @param key       键值字符串
   * @return string   数据字符串，无值时为null
   */
  get(key = '') {
    // Cookies.get(key)
    const data = localStorage.getItem(key)
    // logger.debug('同步读取', key + ' - ' + data)
    return data
  },
  /**
   * 同步删除本地缓存中键值对应的数据
   * @param key       键值
   */
  del(key = '') {
    // Cookies.remove(key)
    // logger.debug('同步删除', key)
    localStorage.removeItem(key)
  },

  /**
   * 同步存储「键值-数据」至本地缓存，会覆盖相同键值的数据。
   * 同时指定缓存有效期，取值时会根据有效期进行判断，过期则返回null
   * @param key             键值
   * @param data            数据
   * @param expireInSecond  过期时间
   */
  setExpire(key = '', data = null, expireInSecond = 30) {
    let storageTarget = {
      data: data,
      expire: new Date().getTime() + expireInSecond * 1000
    }
    this.set(key, storageTarget)
  },
  /**
   * 同步读取本地缓存中键值对应的数据，过期则返回null
   * 必须使用 setExpire 方法设置本地缓存
   * @param key       键值字符串
   * @return string   数据字符串，无值时为null
   */
  getExpire(key = '') {
    let storageTarget = this.get(key)
    if (storageTarget && storageTarget.expire && storageTarget.expire < new Date().getTime()) {
      return storageTarget.data
    }
    return null
  }

  /**
   * 异步操作将「键值-数据」存储在本地缓存，若存在同键名数据则直接覆盖
   * @param key       键值
   * @param data      数据
   * @param success   操作成功回调
   * @param fail      操作失败回调
   * @param complete  操作结束回调（不论成功或失败都会回调）
   */
  // setAsync({key = '', data = null, success = function (res) {}, fail = function (res) {}, complete = function (res) {}}) {},
  /**
   * 异步读取本地缓存中键值对应的数据
   * @param key       键值
   * @param data      数据
   * @param success   读取成功回调
   * @param fail      读取失败回调
   * @param complete  读取结束回调（不论成功或失败都会回调）
   */
  // getAsync({key = '', success = function (res) {}, fail = function (res) {}, complete = function (res) {}}) {},
  /**
   * 异步删除本地缓存中键值对应的数据
   * @param key       键值
   * @param success   读取成功回调
   * @param fail      读取失败回调
   * @param complete  读取结束回调（不论成功或失败都会回调）
   */
  // delAsync({key = '', success = function (res) {}, fail = function (res) {}, complete = function (res) {}}) {},
  /**
   * 获取本地缓存中所有的键值
   * @return {string[]}
   */
  // getKeys() {}
}

export default storage
