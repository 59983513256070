/**
 * 数据统计API
 **/

import http from '@/utils/http'
// import qs from 'qs'

export default {
    /* 用户统计 */
    readUser() {
        return http({
            method: 'get',
            url: '/statistics/user',
        })
    },
    /* 宠物性别统计 */
    readPetSex(petType) {
        return http({
            method: 'get',
            url: '/statistics/petSex',
            params: { petType: petType }
        })
    },
    /* 宠物品种统计 */
    readPetVariety(petType) {
        return http({
            method: 'get',
            url: '/statistics/petVariety',
            params: { petType: petType }
        })
    },
    /* 宠物食物统计 */
    readPetFood(petType) {
        return http({
            method: 'get',
            url: '/statistics/petFood',
            params: { petType: petType }
        })
    },
    /* 宠物情感统计 */
    readPetEmotion(petType) {
        return http({
            method: 'get',
            url: '/statistics/petEmotion',
            params: { petType: petType }
        })
    },
    /* 设备统计 */
    readDevice() {
        return http({
            method: 'get',
            url: '/statistics/device',
        })
    },
    /* 提醒事项统计 */
    readRemind() {
        return http({
            method: 'get',
            url: '/statistics/remind',
        })
    },
    /** 新*用户统计 */
    getUserCensus() {
        return http({
            method: 'get',
            url: '/statistics/getUserCensus'
        })
    },
    /** 获取最近七天用户统计数据 */
    getSevenDaysUsers() {
        return http({
            method: 'get',
            url: '/statistics/getSevenDaysUsers'
        })
    },
}
