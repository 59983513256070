/**
  * 品牌
  **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 分页查询品牌列表 */
  getBrandList(params) {
    return http({
      method: 'get',
      url: '/shop/brand/list',
      params
    })
  },
  /** 创建品牌 */
  addBrand(data) {
    return http2({
      method: 'post',
      url: '/shop/brand',
      data,
    });
  },

  /** 修改品牌 */
  updateBarnd(data) {
    return http2({
      method: 'put',
      url: '/shop/brand',
      data,
    });
  },

  /** 查询品牌详情 */
  getBrandDetail(params) {
    return http({
      method: 'get',
      url: '/shop/brand/detail',
      params,
    });
  },

  /** 删除品牌 */
  deleteBrand(params) {
    return http({
      method: 'delete',
      url: '/shop/brand',
      params,
    });
  },

  /** 更新品牌状态 */
  updateBarndStatus(data) {
    return http2({
      method: 'put',
      url: '/shop/brand/status',
      data,
    });
  },
};