<template>
  <!-- 图片 -->
  <div>
    <el-image
      class="moe-image"
      v-for="(item, index) in setSrc"
      :key="index"
      :style="{ width, height }"
      :src="item"
      :preview-src-list="setSrc"
      :class="className"
      :fit="fit">

      <div slot="error" class="image-slot">
        <slot name="error" v-if="$slots" />
        <div v-else>加载失败</div>
      </div>
    </el-image>
  </div>
</template>

<script>
/**
 * 图片 - moe-image
 * @description 基于el-image组件的二次封装，用于显示图片
 * @property {String,Array} src 地址
 * @property {String} fit 剪裁方式
 * @property {String} width 宽度
 * @property {String} height 高度
 **/
export default {
  name: 'moe-image',
  props: {
    // 图标
    src: [String, Array],

    // 宽
    width: String,

    // 高
    height: String,

    // 图标显示方式
    fit: {
      type: String,
      default: 'contain',
    },
    className: String,
  },
  computed: {
    // 设置路径
    setSrc() {
      // 图片前缀
      let before_url = this.$moe_config.fileUrl;

      let list = [];
      if (!this.src) {
        return ['null']; //为了图片加载失败 手动赋值null
      } else {
        if (typeof this.src === 'string') {
          if (this.src.indexOf('http') === -1) {
            list = [before_url + this.src];
          } else {
            list = [this.src];
          }
        } else {
          list = [...this.src.map((url) => {
            if (url.indexOf('http') === -1) {
              return before_url + url
            } else {
              return url
            }
          })];
        }
        return list;
      }
    }
  },
  mounted() {
  }
}
</script>

<style lang='scss'>
.moe-image {
  position: relative;
  .image-slot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f7fa;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #c0c4cc;
  }
}
</style>