<template>
  <!-- 详情列表Item -->
  <div class="moe-describe-item" :style="{ width: itemWidth }">
    <div class="moe-describe-item__label">{{ label ? `${label}:` : '' }}</div>
    <div class="moe-describe-item__value"><slot /></div>
  </div>
</template>

<script>
/**
 * 描述列表Item - moe-describe-item
 * @description 用于页面显示数据详情信息，需放入moe-describe-list组件内容使用
 * @property {String} label 标签
 * @property {Number} col 占几列
 **/
export default {
  name: 'moe-describe-item',
  inject: ['ldescribeList'],
  props: {
    //标签
    label: String,

    //占几列
    col: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    itemWidth() {
      return (100 / this.$parent.col) * this.col + '%'
    },
  },
}
</script>


<style lang="scss" scoped>
.moe-describe-item {
  display: flex;
  align-items: flex-start;
  color: #333;
  margin: 10px 0;
  padding: 0 30px;
  line-height: 25px;
  box-sizing: border-box;
  font-size: 15px;

  &__label {
    margin-right: 10px;
    white-space: nowrap;

    &:after {
      // content: ':';
    }
  }

  &__value {
    flex: 1;
    word-wrap: break-word;

    &:empty::before {
      content: '-';
    }
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (min-width: 1201px) and (max-width: 1500px) {
}

@media only screen and (min-width: 1501px) and (max-width: 1920px) {
}
</style>