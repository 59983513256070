/**
  * 帮助与反馈
  **/

import http from '@/utils/http'
import http2 from '@/utils/http2'
import qs from 'qs'

export default {
  /** 问题分类管理列表 */
  assortList() {
    return http({
      method: 'get',
      url: '/problem/assortList',
    })
  },

  /** 新增问题分类 */
  addAssort(data) {
    return http2({
      method: 'post',
      url: '/problem/addAssort',
      data
    })
  },

  /** 修改问题分类 */
  updateAssort(data) {
    return http({
      method: 'put',
      url: '/problem/updateAssort',
      data
    })
  },

  /** 问题分类上下架 */
  assortShelf(data) {
    return http({
      method: 'put',
      url: '/problem/assortShelf',
      data: qs.stringify(data)
    })
  },

  /** 删除问题分类 */
  delAssort(params) {
    return http({
      method: 'delete',
      url: '/problem/delAssort',
      params
    })
  },

  /** 新增常见问题 */
  addProblem(data) {
    return http2({
      method: 'post',
      url: '/problem/addProblem',
      data
    })
  },

  /** 常见问题详情 */
  queryCommonProblem(params) {
    return http({
      method: 'get',
      url: '/problem/queryCommonProblem',
      params
    })
  },

  /** 修改常见问题 */
  updateProblem(data) {
    return http2({
      method: 'put',
      url: '/problem/updateProblem',
      data
    })
  },

  /** 删除常见问题 */
  delProblem(params) {
    return http({
      method: 'delete',
      url: '/problem/delProblem',
      params
    })
  },

  /** 分页查询用户反馈问题-详情 */
  queryUserProblem(params) {
    return http({
      method: 'get',
      url: '/problem/queryUserProblem',
      params
    })
  },

  /** 答复用户反馈问题 */
  answerUserProblem(data) {
    return http2({
      method: 'put',
      url: '/problem/answerUserProblem',
      data
    })
  },
}