<template>
  <!-- 级联选择器 -->
  <el-cascader
    :class="className"
    class="moe-address"
    ref="cascaderRef"
    :props="props"
    v-model="cascaderValue"
    :placeholder="placeholder"
    @change="changeCascader"
    clearable>
    </el-cascader>
</template>

<script>
import SYSTEM_API from '@/api/modules/system';
export default {
  name: 'moe-address',
  model: {
    props: 'value',
    event: 'change',
  },
  props: {
    value: {        // v-model 选中项绑定值
      type: Array,
      default: () => [],
    },
    className: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '请选择地址'
    },
    // 是否获取中文名称
    getSelectTextList: {
      type: Boolean,
      default: false,
    },
    selectTextList: {
      type: Array,
      default: () => [],
    }
  },
  watch: {
    value(val) {
      this.cascaderValue = val;
    }
  },
  data() {
    return {
      cascaderValue: this.value,
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level, data } = node;
          let params = {
            path: level + 1,
          }
          if (level != 0) {
            params.parentId = data.value
          }
          let nodes = [];
          setTimeout(() => {
            SYSTEM_API.getQueryAreas(params).then((data) => {
              if (data.code === 200) {
                nodes = data.result.map(({ name, areaId }) => {
                  return {
                    label: name,
                    value: areaId,
                    leaf: level >= 2
                  }
                })
              }
              resolve(nodes)
            })
          }, 200);
        }
      },
    }
  },
  methods: {
    changeCascader(selectList) {
      if (this.getSelectTextList) {
        if (selectList.length) {
          let selectTextList = this.$refs['cascaderRef'].getCheckedNodes()[0].pathLabels
          this.$emit('update:selectTextList', selectTextList);
        } else {
          this.$emit('update:selectTextList', []);
        }
      }
      this.$emit('change', selectList);
    },
  },
}
</script>

<style lang="scss" scoped>
.moe-cascader {}
</style>