/**
 * @Name: config
 * @Describe: 系统配置
 **/

// 接口请求前缀
let serveUrl = process.env.VUE_APP_SERVICEURL;
// 日志请求前缀
let logUrl = process.env.VUE_APP_LOG;
if (process.env.NODE_ENV === 'local') {
	serveUrl = '/api'
	logUrl = '/log'
}

// 文件地址前缀
const beforeFile = process.env.VUE_APP_BEFOREFILE;
const OSS_IMGURL = process.env.VUE_APP_OSS_IMGURL;
const OSS_UPLOAD = process.env.VUE_APP_OSS_UPLOAD;


// 页面地址
const {protocol} = window.location;

// 切割字符
const split_str='//';

// 处理前缀
let str_server = serveUrl.split(split_str);
let str_beforeFile = beforeFile.split(split_str);
// 改变
str_server[0] = protocol;
str_beforeFile[0] = protocol;

// 结果
const uploadUrl = str_server.join(split_str) + '/manage';
// const fileUrl = str_beforeFile.join(split_str);
const fileUrl = beforeFile;

const downloadUrl = '';
const exteriorUrl = '';

export default {
	/* 服务器地址前缀 */
	serveUrl,
	/** 请求oss文件前缀 */
	OSS_UPLOAD,
	/* 日志请求前缀 */
	logUrl,

	/* 文件地址 */
	fileUrl,

	/* 上传地址 */
	uploadUrl,

	/* 下载地址 */
	downloadUrl,

	/* 外部链接地址 */
	exteriorUrl,
	oss_imgUrl: OSS_IMGURL
}