/**
 * @Module Mssage
 * @Describe 全局消息
 **/

/* 引入element-ui全局消息组件 */
import {
	Message
} from 'element-ui';

const ShowConfig = {
  animationDuration: 1500,
  completionHandler: null,
};

/* export message */
export default {
  /* 成功提示 */
  success(msg, config = ShowConfig) {
    Message.success({
      message: msg,
      duration: config.animationDuration,
		});
		if (config.completionHandler) {
      let timeout = setTimeout(() => {
        this.closeAll();
        config.completionHandler();
        clearTimeout(timeout);
      }, config.animationDuration || ShowConfig.animationDuration);
    }
  },

  /* 警告提示 */
  warning(msg) {
    Message.warning({
      message: msg,
      duration: 1500,
    });
  },

  /* 信息提示 */
  info(msg) {
    Message.info({
      message: msg,
      duration: 1500,
    });
  },

  /* 错误提示 */
  error(msg) {
    Message.error({
      message: msg,
      duration: 1500,
    });
  },

  /* 自定义提示 */
  custom(option) {
    Message(option);
  },

  /* 关闭全部提示 */
  closeAll() {
    Message.closeAll();
  },
};
