import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './config/ui-core'
import './config/moe-ui'
import './utils/tinymce/index'

Vue.config.productionTip = false

/**
 	* 挂载方法
 	* config: 系统配置
 	* api: 服务api
 	* http: 服务请求
 	* layer: 弹出层
 	* format: 数据格式处理
 	* verify: 数据校验
 	*/

import config from '@/config';
import api from '@/api';
import http from '@/utils/http';
import message from '@/utils/message';
import layer from '@/utils/layer';
import format from '@/utils/format';
import verify from '@/utils/verify';
import time from '@/utils/time';
import data from '@/utils/data';
import { yunHelper } from '@/utils/yun-helper';
import lodash from '@/utils/lodash-es';
import math from '@/utils/math';
import coordinator from '@/utils/coordinator';
import formatter from '@/utils/formatter';

/**
 	* Vue全局对象
 	*/
Vue.prototype = Object.assign(Vue.prototype, {
  $moe_config: config,
  $moe_api: api,
  $moe_http: http,
  $moe_msg: message,
  $moe_layer: layer,
  $moe_verify: verify,
  $moe_data: data,
  $moe_time: time,
  $moe_format: format,
  $moe_yunHelper: yunHelper,
  $moe_lodash: lodash,
  $moe_math: math,
  $moe_coordinator: coordinator,
  $moe_formatter: formatter,
});

export const app = new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')