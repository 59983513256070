/**
  -- Created by Lichuangwei on 2023/5/12
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 活动管理
  -- 1.预售活动
  -- 2.拼团活动
  -- 3.商品折扣
* */

import BlankView from '@/views/BlankView.vue'

const ActivityManage = [
  {
    path: 'activityManage',
    name: 'ActivityManage',
    meta: {
      title: '活动管理',
      path: '/activityManage/presale/list',
    },
    component: BlankView,
    children: [
      /** 优惠券 */
      {
        path: '/activityManage/coupon/list',
        name: 'ActivityManageCouponList',
        meta: {
          title: '优惠券列表',
          path: '/activityManage/coupon/list',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/coupon/list.vue'),
      },
      {
        path: '/activityManage/coupon/add',
        name: 'ActivityManageCouponAdd',
        meta: {
          title: '新增优惠券',
          path: '/activityManage/coupon/add',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/coupon/add.vue'),
      },
      {
        path: '/activityManage/coupon/detail',
        name: 'ActivityManageCouponDetail',
        meta: {
          title: '查看优惠券',
          path: '/activityManage/coupon/detail',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/coupon/detail.vue'),
      },
      /** 预售活动 */
      {
        path: '/activityManage/presale/list',
        name: 'ActivityManagePresaleList',
        meta: {
          title: '预售活动列表',
          path: '/activityManage/presale/list',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/presale/list.vue'),
      },
      {
        path: '/activityManage/presale/add',
        name: 'ActivityManagePresaleAdd',
        meta: {
          title: '新增预售活动',
          path: '/activityManage/presale/add',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/presale/add.vue'),
      },
      {
        path: '/activityManage/presale/detail',
        name: 'ActivityManagePresaleDetail',
        meta: {
          title: '查看预售活动',
          path: '/activityManage/presale/detail',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/presale/detail.vue'),
      },
      /** 拼团活动 */
      {
        path: '/activityManage/group/list',
        name: 'ActivityManageGroupList',
        meta: {
          title: '拼团活动列表',
          path: '/activityManage/group/list',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/group/list.vue'),
      },
      {
        path: '/activityManage/group/add',
        name: 'ActivityManageGroupAdd',
        meta: {
          title: '新建拼团活动',
          path: '/activityManage/group/add',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/group/add.vue'),
      },
      {
        path: '/activityManage/group/amend',
        name: 'ActivityManageGroupAmend',
        meta: {
          title: '编辑拼团活动',
          path: '/activityManage/group/amend',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/group/amend.vue'),
      },
      {
        path: '/activityManage/group/detail',
        name: 'ActivityManageGroupDetail',
        meta: {
          title: '查看拼团活动',
          path: '/activityManage/group/detail',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/group/detail.vue'),
      },
      {
        path: '/order/group/list',
        name: 'OrderGroupList',
        meta: {
          title: '拼团订单',
          path: '/order/group/list',
          affix: true,
        },
        component: () => import('@/views/Order/Group/list.vue'),
      },
      /** 商品折扣 */
      {
        path: '/activityManage/goodsDiscount/list',
        name: 'ActivityManageGoodsDiscountList',
        meta: {
          title: '商品折扣列表',
          path: '/activityManage/goodsDiscount/list',
          affix: true,
        },
        component: () =>
          import('@/views/ActivityManage/goodsDiscount/list.vue'),
      },
      {
        path: '/activityManage/goodsDiscount/add',
        name: 'ActivityManageGoodsDiscountAdd',
        meta: {
          title: '新增单品折扣',
          path: '/activityManage/goodsDiscount/add',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/goodsDiscount/add.vue'),
      },
      {
        path: '/activityManage/goodsDiscount/detail',
        name: 'ActivityManageGoodsDiscountDetail',
        meta: {
          title: '查看单品折扣',
          path: '/activityManage/goodsDiscount/detail',
          affix: true,
        },
        component: () =>
          import('@/views/ActivityManage/goodsDiscount/detail.vue'),
      },
      /** 店铺折扣 */
      {
        path: '/activityManage/shopDiscount/list',
        name: 'ActivityManageShopDiscountList',
        meta: {
          title: '店铺折扣列表',
          path: '/activityManage/shopDiscount/list',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/shopDiscount/list.vue'),
      },
      {
        path: '/activityManage/shopDiscount/add',
        name: 'ActivityManageShopDiscountAdd',
        meta: {
          title: '新增店铺折扣',
          path: '/activityManage/shopDiscount/add',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/shopDiscount/add.vue'),
      },
      {
        path: '/activityManage/shopDiscount/detail',
        name: 'ActivityManageShopDiscountDetail',
        meta: {
          title: '查看店铺折扣',
          path: '/activityManage/shopDiscount/detail',
          affix: true,
        },
        component: () =>
          import('@/views/ActivityManage/shopDiscount/detail.vue'),
      },
      /** N元任选 */
      {
        path: '/activityManage/nYuanOptional/list',
        name: 'ActivityManageNYuanOptionalList',
        meta: {
          title: 'N元任选列表',
          path: '/activityManage/nYuanOptional/list',
          affix: true,
        },
        component: () =>
          import('@/views/ActivityManage/nYuanOptional/list.vue'),
      },
      {
        path: '/activityManage/nYuanOptional/add',
        name: 'ActivityManageNYuanOptionalAdd',
        meta: {
          title: '新增活动',
          path: '/activityManage/nYuanOptional/add',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/nYuanOptional/add.vue'),
      },
      {
        path: '/activityManage/nYuanOptional/detail',
        name: 'ActivityManageNYuanOptionalDetail',
        meta: {
          title: '查看活动',
          path: '/activityManage/nYuanOptional/detail',
          affix: true,
        },
        component: () =>
          import('@/views/ActivityManage/nYuanOptional/detail.vue'),
      },
      /** 新人专享 */
      {
        path: '/activityManage/newcomer/list',
        name: 'ActivityManageNewcomerList',
        meta: {
          title: '新人专享列表',
          path: '/activityManage/newcomer/list',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/newcomer/list.vue'),
      },
      {
        path: '/activityManage/newcomer/add',
        name: 'ActivityManageNewcomerAdd',
        meta: {
          title: '新增新人专享',
          path: '/activityManage/newcomer/add',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/newcomer/add.vue'),
      },
      {
        path: '/activityManage/newcomer/detail',
        name: 'ActivityManageNewcomerDetail',
        meta: {
          title: '新增新人专享',
          path: '/activityManage/newcomer/detail',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/newcomer/detail.vue'),
      },
      /** 助力拉新 */
      {
        path: '/activityManage/assist/list',
        name: 'ActivityManageAssistList',
        meta: {
          title: '助力拉新',
          path: '/activityManage/assist/list',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/Assist/List.vue'),
      },
      {
        path: '/activityManage/assist/add',
        name: 'ActivityManageAssistAdd',
        meta: {
          title: '新增助力拉新',
          path: '/activityManage/assist/add',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/Assist/Add.vue'),
      },
      {
        path: '/activityManage/assist/detail',
        name: 'ActivityManageAssistDetail',
        meta: {
          title: '查看助力拉新',
          path: '/activityManage/assist/detail',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/Assist/Detail.vue'),
      },
      /** 赠品管理 */
      {
        path: '/activityManage/giftManage/list',
        name: 'ActivityManageGiftManageList',
        meta: {
          title: '赠品管理',
          path: '/activityManage/giftManage/list',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/GiftManage/List.vue'),
      },
      {
        path: '/activityManage/giftManage/add',
        name: 'ActivityManageGiftManageAdd',
        meta: {
          title: '新增赠品',
          path: '/activityManage/giftManage/add',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/GiftManage/Add.vue'),
      },
      {
        path: '/activityManage/giftManage/detail',
        name: 'ActivityManageGiftManageDetail',
        meta: {
          title: '新增赠品',
          path: '/activityManage/giftManage/detail',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/GiftManage/Detail.vue'),
      },
      /** 买赠活动 buyGift BuyGift */
      {
        path: '/activityManage/buyGift/list',
        name: 'ActivityManageBuyGiftList',
        meta: {
          title: '买赠活动',
          path: '/activityManage/buyGift/list',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/BuyGift/List.vue'),
      },
      {
        path: '/activityManage/buyGift/add',
        name: 'ActivityManageBuyGiftAdd',
        meta: {
          title: '新建赠品',
          path: '/activityManage/buyGift/add',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/BuyGift/Add.vue'),
      },
      {
        path: '/activityManage/buyGift/detail',
        name: 'ActivityManageBuyGiftDetail',
        meta: {
          title: '查看赠品',
          path: '/activityManage/buyGift/detail',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/BuyGift/Detail.vue'),
      },
      /** 主图打标 */
      {
        path: '/activityManage/goodsBadge/list',
        name: 'ActivityManageGoodsBadgeList',
        meta: {
          title: '主图打标',
          path: '/activityManage/goodsBadge/list',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/GoodsBadge/List.vue'),
      },
      {
        path: '/activityManage/goodsBadge/add',
        name: 'ActivityManageGoodsBadgeAdd',
        meta: {
          title: '新建活动',
          path: '/activityManage/goodsBadge/add',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/GoodsBadge/Add.vue'),
      },
      {
        path: '/activityManage/goodsBadge/detail',
        name: 'ActivityManageGoodsBadgeDetail',
        meta: {
          title: '查看活动',
          path: '/activityManage/goodsBadge/detail',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/GoodsBadge/Detail.vue'),
      },
      /** 秒杀活动 */
      {
        path: '/activityManage/flash/list',
        name: 'ActivityManageFlashList',
        meta: {
          title: '秒杀活动',
          path: '/activityManage/flash/list',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/Flash/List.vue'),
      },
      {
        path: '/activityManage/flash/add',
        name: 'ActivityManageFlashAdd',
        meta: {
          title: '新建秒杀活动',
          path: '/activityManage/flash/add',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/Flash/Add.vue'),
      },
      {
        path: '/activityManage/flash/detail',
        name: 'ActivityManageFlashDetail',
        meta: {
          title: '查看秒杀活动',
          path: '/activityManage/flash/detail',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/Flash/Detail.vue'),
      },
      /** 猜你喜欢商品池 */
      {
        path: '/activityManage/guess/list',
        name: 'ActivityManageGuessList',
        meta: {
          title: '猜你喜欢商品池',
          path: '/activityManage/guess/list',
          affix: true,
        },
        component: () => import('@/views/ActivityManage/Guess/List.vue'),
      },
    ],
  },
];


export default ActivityManage;