/**
  * 商品折扣
  **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 创建折扣活动 */
  addDiscount(data) {
    return http2({
      method: 'post',
      url: '/shop/goods/discount',
      data,
    });
  },
  /** 修改折扣活动 */
  updateDiscount(data) {
    return http2({
      method: 'put',
      url: '/shop/goods/discount',
      data,
    });
  },
  /** 关闭折扣活动 */
  closeDiscount(data) {
    return http2({
      method: 'post',
      url: '/shop/goods/discount/close',
      data,
    });
  },
  /** 暂停折扣活动 */
  pauseDiscount(data) {
    return http2({
      method: 'post',
      url: '/shop/goods/discount/pause',
      data,
    });
  },
  /** 重启折扣活动 */
  restartDiscount(data) {
    return http2({
      method: 'post',
      url: '/shop/goods/discount/restart',
      data,
    });
  },
  /** 获取折扣活动详情 */
  getDiscountDetail(params) {
    return http({
      method: 'get',
      url: '/shop/goods/discount/detail',
      params,
    });
  },
  /** 修改折扣活动配置 */
  updateDiscountConfigList(data) {
    return http2({
      method: 'put',
      url: '/shop/goods/discount/items',
      data,
    });
  },
};