
/**
 * @Module Layer
 * @Describe 弹出层
 **/

import {
    MessageBox
} from 'element-ui';

export default {
  /**
   * 确认弹框
   * @parse {String}} msg
   * @parse {Function} affirm
   * @parse {Function} cancel
   **/
  confirm(msg, affirm, cancel) {
    MessageBox.confirm(msg, '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      dangerouslyUseHTMLString: true,
    })
      .then(() => {
        affirm && affirm();
      })
      .catch(() => {
        cancel && cancel();
      });
  },
  /**
   * 确认弹框
   * 没有取消按钮
   * @parse {String}} msg
   * @parse {Function} affirm
   **/
  alert(msg, affirm) {
    MessageBox.alert(msg, '系统提示', {
      confirmButtonText: '确定',
      type: 'warning',
      dangerouslyUseHTMLString: true,
    }).then(() => {
      affirm && affirm();
    });
  },
  msgbox({ title, message, showCancelButton, confirmButtonText, cancelButtonText }, affirm, cancel) {
    MessageBox({
      title,
      message,
      showCancelButton: showCancelButton || true,
      confirmButtonText,
      cancelButtonText,
    })
      .then(() => {
        affirm && affirm();
      })
      .catch(() => {
        cancel && cancel();
      });
  },
};