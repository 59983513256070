<template>
  <div class="moe-popup">
      <el-input placeholder="请输入操作密码" v-model="modelValue"></el-input>
      <div class="df jcfe aic w-100 mt-20">
        <el-button size="small" @click="dialogClose()">取消</el-button>
        <el-button size="small" type="primary" @click="dialogConfirm()">确定</el-button>
      </div>
  </div>
</template>

<script>
export default {
  name: 'moe-popup',
  model: {
    event: 'dialogClose',
  },
  data() {
    return {
      modelValue: '',
    }
  },
  methods: {
    dialogClose() {
      this.$emit('dialogClose');
    }
  }
}
</script>

<style lang="scss">
.moe-dialog {

}
</style>