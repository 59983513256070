<template>
  <el-card class="moe-card" :class="{'moe-card__autoheight': autoHeight }" :body-style="{ 'overflowY': 'auto' }">
    <div slot="header" v-if="$slots.header">
      <slot name="header"></slot>
    </div>
    <div slot="header" class="df aic" v-else-if="!$slots.header && title">
      <div class="font-20 fwb">{{ title }}</div>
    </div>
    <slot>

    </slot>
  </el-card>
</template>

<script>
export default {
  name: 'moe-card',
  props: {
    autoHeight: {
      typeof: Boolean,
      default: false,
    },
    title: {
      typeof: String,
      default: '',
    }
  }
}
</script>

<style lang="scss">
.moe-card {
  &__autoheight {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-card__body {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>