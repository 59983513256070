/**
 * 登录API
 **/

import http from '@/utils/http'
import qs from 'qs'
import ossHttp from '@/utils/ossHttp'

/* export API */
export default {
    /* 系统登录 */
    toLogin(data) {
        return http({
            method: 'post',
            url: '/user/login',
            data:qs.stringify(data)
        })
    },

    /* 获取用户信息 */
    userInfo(params){
        return http({
            method: 'get',
            url: '/user/getOwnInfo',
            params
        })
    },

    /* 获取用户权限 */
    userAuthority(params){
        return http({
            method: 'get',
            url: '/user/getMenus',
            params
        })
    },

    /* 修改用户密码 */
    amendPassword(data){
        return http({
            method: 'post',
            url: '/user/updatePwd',
            data:qs.stringify(data)
        })
    },

    /* 获取用户权限 */
    getUserFileURL(params){
        return ossHttp({
            method: 'get',
            url: params.baseUrl,
            params
        })
    },
}