/**
 * 宠物API
 **/

import http from '@/utils/http'
import qs from 'qs'

/* export API */
export default {
    /* 宠物类别列表 */
    petCategoryList(params) {
        return http({
            method: 'get',
            url: '/petsType/pageList',
            params
        })
    },

    /* 宠物类别列表 */
    petCategoryDetail(params) {
        return http({
            method: 'get',
            url: '/petsType/getInfo',
            params
        })
    },

    /* 宠物类别添加 */
    petCategoryAdd(data) {
        return http({
            method: 'post',
            url: '/petsType/add',
            data: qs.stringify(data)
        })
    },

    /* 宠物类别修改 */
    petCategoryAmend(data) {
        return http({
            method: 'post',
            url: '/petsType/update',
            data: qs.stringify(data)
        })
    },

    /* 宠物类别删除 */
    petCategoryDelete(data) {
        return http({
            method: 'post',
            url: '/petsType/delete',
            data: qs.stringify(data)
        })
    },

    /* 宠物粮食列表 */
    petCerealList(params) {
        return http({
            method: 'get',
            url: '/petsFood/pageList',
            params
        })
    },

    /* 宠物粮食详情 */
    petCerealDetail(params) {
        return http({
            method: 'get',
            url: '/petsFood/getInfo',
            params
        })
    },

    /* 宠物粮食添加 */
    petCerealAdd(data) {
        return http({
            method: 'post',
            url: '/petsFood/add',
            data
        })
    },

    /* 宠物粮食修改 */
    petCerealAmend(data) {
        return http({
            method: 'post',
            url: '/petsFood/update',
            data
        })
    },

    /* 宠物粮食删除 */
    petCerealDelete(data) {
        return http({
            method: 'post',
            url: '/petsFood/delete',
            data: qs.stringify(data)
        })
    },

    /* 宠物事项列表 */
    petMatterList(params) {
        return http({
            method: 'get',
            url: '/remind/pageList',
            params
        })
    },

    /* 宠物事项详情 */
    petMatterDetail(params) {
        return http({
            method: 'get',
            url: '/remind/getInfo',
            params
        })
    },

    /* 宠物事项添加 */
    petMatterAdd(data) {
        return http({
            method: 'post',
            url: '/remind/add',
            data: qs.stringify(data)
        })
    },

    /* 宠物事项修改 */
    petMatterAmend(data) {
        return http({
            method: 'post',
            url: '/remind/update',
            data: qs.stringify(data)
        })
    },

    /* 宠物事项删除 */
    petMatterDelete(data) {
        return http({
            method: 'post',
            url: '/remind/delete',
            data: qs.stringify(data)
        })
    },

    /* 宠物事项状态 */
    petMatterStatus(data) {
        return http({
            method: 'post',
            url: '/remind/updateState',
            data: qs.stringify(data)
        })
    },

    /* 获取所有宠物类别列表 */
    getPetsTypeAllList() {
        return http({
            method: 'get',
            url: '/petsType/allList',
        })
    },
}