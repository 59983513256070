/**
 * 用户API
 **/


import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

/* export API */
export default {
  /* 用户列表 */
  userList(params) {
    return http({
      method: 'get',
      url: '/appUser/pageList',
      params,
    });
  },

  /* 用户详情 */
  userDetail(params) {
    return http({
      method: 'get',
      url: '/appUser/getInfo',
      params,
    });
  },

  /* 重置密码 */
  resetPassword(data) {
    return http({
      method: 'post',
      url: '/appUser/resetPwd',
      data: qs.stringify(data),
    });
  },

  /** 用户精准搜索 */
  strictSearch(params) {
    return http({
      method: 'get',
      url: '/appUser/strictSearch',
      params,
    });
  },

  /** 敏感操作验证 */
  sensitiveCheck(data) {
    return http({
      method: 'post',
      url: '/user/sensitiveCheck',
      data: qs.stringify(data),
    });
  },

  /** 分页查询用户标签 */
  getUserTagList(params) {
    return http({
      method: 'get',
      url: '/tag/list',
      params,
    });
  },

  /** 获取所有用户标签 */
  getUserTagListAll(params) {
    return http({
      method: 'get',
      url: '/tag/all',
      params,
    });
  },

  /** 创建用户标签 */
  addUserTag(data) {
    return http2({
      method: 'post',
      url: '/tag',
      data,
    });
  },

  /** 修改用户标签 */
  updateUserTag(data) {
    return http2({
      method: 'put',
      url: '/tag',
      data,
    });
  },

  /** 获取用户标签详情 */
  getUserTagDetail(params) {
    return http({
      method: 'get',
      url: '/tag/detail',
      params,
    });
  },

  /** 删除用户标签 */
  deleteUserTag(params) {
    return http({
      method: 'delete',
      url: '/tag',
      params,
    });
  },

  /** 更新列表用户标签 */
  updateListUserTag(data) {
    return http2({
      method: 'post',
      url: '/tag/updateUser',
      data,
    });
  },
};
