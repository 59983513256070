<template>
  <!-- 登背景 -->
  <div class="login-bg">
    <!-- 背景轮播 -->
    <div class="carousel">
      <div class="carousel-item" style="opacity: 1">
        <img src="https://cloudpets-dev.oss-cn-shenzhen.aliyuncs.com/Management/pet_1.jpg" width="100%" height="100%" alt="" />
      </div>
      <div class="carousel-item">
        <img src="https://cloudpets-dev.oss-cn-shenzhen.aliyuncs.com/Management/pet_2.jpg" width="100%" height="100%" alt="" />
      </div>
      <div class="carousel-item">
        <img src="https://cloudpets-dev.oss-cn-shenzhen.aliyuncs.com/Management/pet_3.jpg" width="100%" height="100%" alt="" />
      </div>
      <div class="carousel-item">
        <img src="https://cloudpets-dev.oss-cn-shenzhen.aliyuncs.com/Management/pet_4.jpg" width="100%" height="100%" alt="" />
      </div>
      <div class="carousel-item">
        <img src="https://cloudpets-dev.oss-cn-shenzhen.aliyuncs.com/Management/pet_5.jpg" width="100%" height="100%" alt="" />
      </div>
    </div>

    <!-- 背景动画 -->
    <div class="animation">
      <!-- <vue-particles
        color="#dedede"
        :particleOpacity="0.7"
        :particlesNumber="80"
        shapeType="circle"
        :particleSize="4"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
        style="height: 100%"
      /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginBg',
  data() {
    return {
      slideTimer: null
    };
  },
  mounted() {
    this.bgSlide();
  },
  methods: {
    /* 加载背景动画 */
    bgSlide() {
      let slideList = document
        .querySelector('.login-bg')
        .querySelectorAll('.carousel-item');
      let index = 0;
      this.slideTimer = setInterval(() => {
        slideList[index].style.opacity = 0;
        index++;
        index = index > slideList.length - 1 ? 0 : index;
        slideList[index].style.opacity = 1;
      }, 10000);
    },
  },
  destroyed() {
    clearInterval(this.slideTimer);
  },
};
</script>

<style lang="scss" scoped>
.login-bg,
.carousel,
.carousel-item,
.animation {
  position: absolute;
  width: inherit;
  height: inherit;
}

.login-bg {
  z-index: 1;
  top: 0;
  left: 0;

  // 轮播
  .carousel {
    &-item {
      opacity: 0;
      transition: opacity 10s;
      margin: 10px 0;
      img {
        filter: blur(10px);
        transform: scale(1);
        overflow: hidden;
        object-fit: cover;
      }
    }
  }

  // 动画
  .animation {
    z-index: 1;
  }
}
</style>
