/**
  -- Created by Lichuangwei on 2023/01/26
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 评价管理
***/

import BlankView from '@/views/BlankView.vue';

const ReviewManage = [
  {
    path: 'reviewManage',
    name: 'ReviewManage',
    meta: {
      title: '评价管理',
      path: '/reviewManage/review/list',
    },
    component: BlankView,
    children: [
      {
        path: '/reviewManage/review/list',
        name: 'ReviewManageReviewList',
        meta: {
          title: '评价管理列表',
          path: '/reviewManage/review/list',
          affix: true,
        },
        component: () => import('@/views/ReviewManage/Review/List.vue'),
      },
    ],
  },
];

export default ReviewManage;
