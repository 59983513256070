<template>
  <el-radio
    class="moe-radio"
    v-model="defaultValue"
    :label="label"
    :disabled="disabled"
    :border="border"
    :size="size"
    @change="handleChange">
    <slot />
  </el-radio>
</template>

<script>
/**
 * 下拉框 - moe-radio
 * @description 基于el-radio组件的二次封装
 * @property {String | Number | Boolean} value 选中value
 * @property {Boolean} border 是否显示边框
 * @property {Boolean} disabled 是否禁用
 * @property {String, Number, Boolean} label Radio 的 value
 * @property {String} size Radio 的尺寸，仅在 border 为真时有效 medium / small / mini
 * @event {Function} change 当绑定值变化时触发的事件
 **/
export default {
  name: 'moe-radio',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String, Number, Boolean],
      default: '',
    },
    size: {
      type: String,
      default: '-',
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultValue: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.defaultValue = newValue
    }
  },
  methods: {
    handleChange(e) {
      this.$emit('change', e)
    },
  }
}
</script>

<style lang="scss">
</style>