/**
 * 供应商管理
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 分页查询供应商列表 */
  getVendorList(params) {
    return http({
      method: 'get',
      url: '/shop/vendor/list',
      params,
    });
  },

  /** 创建供应商 */
  addVendor(data) {
    return http2({
      method: 'post',
      url: '/shop/vendor',
      data,
    });
  },

  /** 查询供应商详情 */
  getVendorDetail(params) {
    return http({
      method: 'get',
      url: '/shop/vendor/detail',
      params,
    });
  },

  /** 修改供应商 */
  updateVendor(data) {
    return http2({
      method: 'put',
      url: '/shop/vendor',
      data,
    });
  },

  /** 删除供应商 */
  delVendor(params) {
    return http({
      method: 'delete',
      url: '/shop/vendor',
      params,
    });
  },

  /** 更新供应商状态 */
  updateVendorStatus(data) {
    return http2({
      method: 'put',
      url: '/shop/vendor/status',
      data,
    });
  },
};