/**
 * @Name Routes
 * @Describe 路由表
 **/
import Index from '@/views/Index.vue';
import BlankView from '@/views/BlankView.vue';
import systemManage from './modules/SystemManage';
import smartEquipment from './modules/smart-equipment';
import advertManage from './modules/advertManage';
import activityManage from './modules/ActivityManage';
import fqaManage from './modules/fqaManage';
import hardwareTest from './modules/hardwareTest';
import contentManage from './modules/ContentManage';
// import brandManage from './modules/BrandManage';
import supplierManage from './modules/SupplierManage';
import MallManage from './modules/MallManage';
import FinanceManage from './modules/FinanceManage';
import PushManage from './modules/PushManage';
import UserManage from './modules/UserManage';
import BillingManage from './modules/BillingManage';
import LotteryManage from './modules/LotteryManage';
import QuestionnaireSurvey from './modules/QuestionnaireSurvey';
import ReviewManage from './modules/ReviewManage';
import SignManage from './modules/SignManage';

/**
 * 需权限才能访问路由
 * 路由配置列表与左侧菜单栏一一对应
 * 参数配置
 *  path: 跳转地址
 *  name: 名称
 *  redirect: 重定向地址
 *  meta:{
 *      title: 标题
 *      icon: 图标
 *      path: 地址
 *      affix: 是否固定至tab栏
 *      back: 显示返回按钮
 *  }
 *  component: 组件
 *  children : 嵌套路由列表
 * */
export const dynamicRoutes = [
  {
    path: '/',
    name: 'Index',
    meta: {
      title: '',
    },
    component: Index,
    children: [
      {
        path: 'welcome',
        name: 'Welcome',
        meta: {
          title: '欢迎━(*｀∀´*)ノ亻!',
          path: '/welcome',
        },
        // component: () => import('@/views/Admin/Welcome.vue')
        component: () => import('@/views/Dashboard/Dashboard.vue'),
      },

      /* -- 数据总览 -- */
      {
        path: 'dashboard',
        name: 'DashManage',
        redirect: '/dashboard',
        meta: {
          title: '数据总览',
          path: '/dashboard',
        },
        component: BlankView,
        children: [
          {
            path: '/dashboard',
            name: 'Dashboard',
            meta: {
              title: '数据总览',
              path: '/dashboard',
              affix: true,
            },
            component: () => import('@/views/Dashboard/Dashboard.vue'),
          },
          {
            path: '/petBoardPie',
            name: 'PetBoardPie',
            meta: {
              title: '宠物数据（饼状图）',
              path: '/petBoardPie',
              affix: true,
            },
            component: () => import('@/views/Dashboard/PetBoardPie.vue'),
          },
          {
            path: '/petBoardBar',
            name: 'PetBoardBar',
            meta: {
              title: '宠物数据（柱状图）',
              path: '/petBoardBar',
              affix: true,
            },
            component: () => import('@/views/Dashboard/PetBoardBar.vue'),
          },
          {
            path: '/Dashboard/DashboardUser',
            name: 'DashboardUser',
            meta: {
              title: '用户统计',
              path: '/Dashboard/DashboardUser',
              affix: true,
            },
            component: () => import('@/views/Dashboard/DashboardUser.vue'),
          },
        ],
      },

      /* -- 账号管理 -- */
      {
        path: 'account',
        name: 'AccountManage',
        redirect: '/account/list',
        meta: {
          title: '后台账号管理',
          path: '/account/list',
        },
        component: BlankView,
        children: [
          {
            path: 'list',
            name: 'AccountList',
            meta: {
              title: '后台账号管理',
              path: '/account/list',
              affix: true,
            },
            component: () => import('@/views/AccountManage/AccountList.vue'),
          },
          {
            path: 'add',
            name: 'AccountAdd',
            meta: {
              title: '账号添加',
              path: '/account/add',
              back: true,
            },
            component: () => import('@/views/AccountManage/AccountAdd.vue'),
          },
          {
            path: 'amend',
            name: 'AccountAmend',
            meta: {
              title: '账号修改',
              path: '/account/amend',
              back: true,
            },
            component: () => import('@/views/AccountManage/AccountAmend.vue'),
          },
        ],
      },

      /* -- 权限管理 -- */
      {
        path: 'role',
        name: 'RoleManage',
        redirect: '/role/list',
        meta: {
          title: '权限管理',
          path: '/role/list',
        },
        component: BlankView,
        children: [
          {
            path: 'list',
            name: 'RoleList',
            meta: {
              title: '权限管理',
              path: '/role/list',
              affix: true,
            },
            component: () => import('@/views/RoleManage/RoleList.vue'),
          },
          {
            path: 'add',
            name: 'RoleAdd',
            meta: {
              title: '权限添加',
              path: '/role/add',
              back: true,
            },
            component: () => import('@/views/RoleManage/RoleAdd.vue'),
          },
          {
            path: 'amend',
            name: 'RoleAmend',
            meta: {
              title: '权限修改',
              path: '/role/amend',
              back: true,
            },
            component: () => import('@/views/RoleManage/RoleAmend.vue'),
          },
        ],
      },
      /* -- 系统管理 -- */
      {
        path: 'menu',
        name: 'MenuManage',
        redirect: '/menu/list',
        meta: {
          title: '系统管理',
          path: '/menu/list',
        },
        component: BlankView,
        children: [
          {
            path: 'list',
            name: 'MenuList',
            meta: {
              title: '菜单管理',
              path: '/menu/list',
              affix: true,
            },
            component: () => import('@/views/AccountManage/MenuList.vue'),
          },
          {
            path: 'add',
            name: 'MenuAdd',
            meta: {
              title: '菜单添加',
              path: '/account/add',
              back: true,
            },
            component: () => import('@/views/AccountManage/MenuAdd.vue'),
          },
          {
            path: 'amend',
            name: 'AccountAmend',
            meta: {
              title: '菜单修改',
              path: '/account/amend',
              back: true,
            },
            component: () => import('@/views/AccountManage/MenuAmend.vue'),
          },
          {
            path: 'express',
            name: 'MenuList',
            meta: {
              title: '物流公司',
              path: '/menu/express',
              affix: true,
            },
            component: () => import('@/views/General/Express.vue'),
          },
          {
            path: 'expressAdd',
            name: 'ExpressAdd',
            meta: {
              title: '物流公司新增',
              path: '/menu/expressAdd',
              affix: true,
            },
            component: () => import('@/views/General/ExpressAdd.vue'),
          },
        ],
      },

      /* -- 用户管理 -- */
      {
        path: 'user',
        name: 'UserManage',
        redirect: '/user/list',
        meta: {
          title: '用户管理',
          path: '/user/list',
        },
        component: BlankView,
        children: [
          {
            path: 'list',
            name: 'UserList',
            meta: {
              title: '用户管理',
              path: '/user/list',
              affix: true,
            },
            component: () => import('@/views/UserManage/UserList.vue'),
          },
          {
            path: 'detail',
            name: 'UserDetail',
            meta: {
              title: '用户详情',
              path: '/user/detail',
              back: true,
            },
            component: () => import('@/views/UserManage/UserDetail.vue'),
          },
        ],
      },

      /* -- 用户管理 -- */
      ...UserManage,

      /* -- 宠物类别管理 -- */
      {
        path: 'pet-category',
        name: 'PetCategoryManage',
        redirect: '/pet-category/list',
        meta: {
          title: '宠物类别管理',
          path: '/pet-category/list',
        },
        component: BlankView,
        children: [
          {
            path: 'list',
            name: 'PetCategoryList',
            meta: {
              title: '宠物类别管理',
              path: '/pet-category/list',
              affix: true,
            },
            component: () => import('@/views/PetManage/PetCategoryList.vue'),
          },
          {
            path: 'add',
            name: 'PetCategoryAdd',
            meta: {
              title: '宠物类别添加',
              path: '/pet-category/add',
              back: true,
            },
            component: () => import('@/views/PetManage/PetCategoryAdd.vue'),
          },
          {
            path: 'amend',
            name: 'PetCategoryAmend',
            meta: {
              title: '宠物类别修改',
              path: '/pet-category/amend',
              back: true,
            },
            component: () => import('@/views/PetManage/PetCategoryAmend.vue'),
          },
          {
            path: 'detail',
            name: 'PetCategoryDetail',
            meta: {
              title: '宠物类别详情',
              path: '/pet-category/detail',
              back: true,
            },
            component: () => import('@/views/PetManage/PetCategoryDetail.vue'),
          },
        ],
      },

      /* -- 宠物粮食管理 -- */
      {
        path: 'pet-cereal',
        name: 'PetCerealManage',
        redirect: '/pet-cereal/list',
        meta: {
          title: '宠物粮食管理',
          path: '/pet-cereal/list',
        },
        component: BlankView,
        children: [
          {
            path: 'list',
            name: 'PetCerealList',
            meta: {
              title: '宠物粮食管理',
              path: '/pet-cereal/list',
              affix: true,
            },
            component: () => import('@/views/PetManage/PetCerealList.vue'),
          },
          {
            path: 'add',
            name: 'PetCerealAdd',
            meta: {
              title: '宠物粮食添加',
              path: '/pet-cereal/add',
              back: true,
            },
            component: () => import('@/views/PetManage/PetCerealAdd.vue'),
          },
          {
            path: 'amend',
            name: 'PetCerealAmend',
            meta: {
              title: '宠物粮食修改',
              path: '/pet-cereal/amend',
              back: true,
            },
            component: () => import('@/views/PetManage/PetCerealAmend.vue'),
          },
          {
            path: 'detail',
            name: 'PetCerealDetail',
            meta: {
              title: '宠物粮食详情',
              path: '/pet-cereal/detail',
              back: true,
            },
            component: () => import('@/views/PetManage/PetCerealDetail.vue'),
          },
        ],
      },

      /* -- 宠物事项管理 -- */
      {
        path: 'pet-matter',
        name: 'PetMatterManage',
        redirect: '/pet-matter/list',
        meta: {
          title: '提醒事项管理',
          path: '/pet-matter/list',
        },
        component: BlankView,
        children: [
          {
            path: 'list',
            name: 'PetMatterList',
            meta: {
              title: '提醒事项管理',
              path: '/pet-matter/list',
              affix: true,
            },
            component: () => import('@/views/PetManage/PetMatterList.vue'),
          },
          {
            path: 'add',
            name: 'PetMatterAdd',
            meta: {
              title: '提醒事项添加',
              path: '/pet-matter/add',
              back: true,
            },
            component: () => import('@/views/PetManage/PetMatterAdd.vue'),
          },
          {
            path: 'amend',
            name: 'PetMatterAmend',
            meta: {
              title: '提醒事项修改',
              path: '/pet-matter/amend',
              back: true,
            },
            component: () => import('@/views/PetManage/PetMatterAmend.vue'),
          },
          {
            path: 'detail',
            name: 'PetMatterDetail',
            meta: {
              title: '提醒事项详情',
              path: '/pet-matter/detail',
              back: true,
            },
            component: () => import('@/views/PetManage/PetMatterDetail.vue'),
          },
        ],
      },

      /* -- 用户设备管理 -- */
      {
        path: 'user-equipment',
        name: 'UserEquipmentManage',
        redirect: '/user-equipment/list',
        meta: {
          title: '用户设备管理',
          path: '/user-equipment/list',
        },
        component: BlankView,
        children: [
          {
            path: 'list',
            name: 'UserEquipmentList',
            meta: {
              title: '用户设备管理',
              path: '/user-equipment/list',
              affix: true,
            },
            component: () =>
              import('@/views/EquipmentManage/UserEquipmentList.vue'),
          },
          {
            path: 'detail',
            name: 'UserEquipmentDetail',
            meta: {
              title: '用户设备详情',
              path: '/user-equipment/detail',
              back: true,
            },
            component: () =>
              import('@/views/EquipmentManage/UserEquipmentDetail.vue'),
          },
        ],
      },

      /* -- 智能设备管理 -- */
      ...smartEquipment,

      /* -- 推送管理 -- */
      // {
      //   path: 'push',
      //   name: 'PushManage',
      //   redirect: '/push/list',
      //   meta: {
      //     title: '推送管理',
      //     path: '/push/list',
      //   },
      //   component: BlankView,
      //   children: [
      //     {
      //       path: 'list',
      //       name: 'PushList',
      //       meta: {
      //         title: '推送管理',
      //         path: '/push/list',
      //         affix: true,
      //       },
      //       component: () => import('@/views/PushManage/PushList.vue'),
      //     },
      //     {
      //       path: 'add',
      //       name: 'PushAdd',
      //       meta: {
      //         title: '推送添加',
      //         path: '/push/add',
      //         affix: true,
      //       },
      //       component: () => import('@/views/PushManage/PushAdd.vue'),
      //     },
      //     {
      //       path: 'detail',
      //       name: 'PushDetail',
      //       meta: {
      //         title: '推送详情',
      //         path: '/push/detail',
      //         affix: true,
      //       },
      //       component: () => import('@/views/PushManage/PushDetail.vue'),
      //     },
      //   ],
      // },

      /* -- 日志管理 -- */
      {
        path: 'journal',
        name: 'JournalManage',
        redirect: '/journal-list',
        meta: {
          title: '日志管理',
          path: '/journal-list',
        },
        component: BlankView,
        children: [
          {
            path: '/user-log',
            name: 'userLog',
            meta: {
              title: '用户日志',
              path: '/user-log',
              affix: true,
            },
            component: () => import('@/views/JournalManage/userLog.vue'),
          },
          {
            path: '/journal-list',
            name: 'JournalList',
            meta: {
              title: '日志管理',
              path: '/journal-list',
              affix: true,
            },
            component: () => import('@/views/JournalManage/JournalList.vue'),
          },
          {
            path: '/device-log',
            name: 'DeviceLog',
            meta: {
              title: '设备日志',
              path: '/device-log',
              affix: true,
            },
            component: () => import('@/views/JournalManage/DeviceLog.vue'),
          },
        ],
      },
      /* -- 订单管理 -- */
      {
        path: 'Order',
        name: 'Order',
        redirect: '/order',
        meta: {
          title: '订单管理',
          path: '/order',
        },
        component: BlankView,
        children: [
          {
            path: '/order',
            name: 'Order',
            meta: {
              title: '订单列表',
              path: '/order-list',
              affix: true,
            },
            component: () => import('@/views/Order/Order.vue'),
          },
          {
            path: '/order/detail',
            name: 'OrderDetail',
            meta: {
              title: '订单详情',
              path: '/order/detail',
              affix: true,
            },
            component: () => import('@/views/Order/OrderDetail.vue'),
          },
          {
            path: '/order/shipment',
            name: 'Shipment',
            meta: {
              title: '发货',
              path: '/order/shipment',
              affix: true,
            },
            component: () => import('@/views/Order/Shipment.vue'),
          },
          {
            path: '/refundOrder',
            name: 'RefundOrder',
            meta: {
              title: '售后订单',
              path: '/refundOrder',
              affix: true,
            },
            component: () => import('@/views/Order/Refund/list.vue'),
          },
          {
            path: '/refundOrder/detail',
            name: 'RefundOrderDetail',
            meta: {
              title: '售后详情',
              path: '/refundOrder/detail',
              back: true,
            },
            component: () => import('@/views/Order/Refund/detail.vue'),
          },
        ],
      },
      /* -- 商品管理 -- */
      {
        path: 'Goods',
        name: 'Goods',
        redirect: '/goods/list',
        meta: {
          title: '商品管理',
          path: '/goods/list',
        },
        component: BlankView,
        children: [
          {
            path: '/activity',
            name: 'Activity',
            meta: {
              title: '活动管理',
              path: '/activity',
              affix: true,
            },
            component: () => import('@/views/Goods/Activity.vue'),
          },
          {
            path: '/activity/add',
            name: 'ActivityAdd',
            meta: {
              title: '活动添加',
              path: '/activity/add',
              affix: true,
            },
            component: () => import('@/views/Goods/ActivityAdd.vue'),
          },
          {
            path: '/activity/amend',
            name: 'ActivityAmend',
            meta: {
              title: '活动修改',
              path: '/activity/amend',
              affix: true,
            },
            component: () => import('@/views/Goods/ActivityAmend.vue'),
          },
          {
            path: '/activity/goods',
            name: 'ActivityGoods',
            meta: {
              title: '活动商品',
              path: '/activity/goods',
              affix: true,
            },
            component: () => import('@/views/Goods/ActivityGoods.vue'),
          },
          {
            path: '/goods/list',
            name: 'GoodsList',
            meta: {
              title: '商品列表',
              path: '/goods/list',
              affix: true,
            },
            component: () => import('@/views/Goods/GoodsList.vue'),
          },
          {
            path: '/goods/detail',
            name: 'GoodsDetail',
            meta: {
              title: '商品详情',
              path: '/goods/detail',
              back: true,
            },
            component: () => import('@/views/Goods/GoodsDetail.vue'),
          },
          {
            path: '/goods/add',
            name: 'GoodsAdd',
            meta: {
              title: '商品添加',
              path: '/goods/add',
              back: true,
            },
            component: () => import('@/views/Goods/GoodsAdd.vue'),
          },
          {
            path: '/goods/amend',
            name: 'GoodsAmend',
            meta: {
              title: '商品修改',
              path: '/goods/amend',
              back: true,
            },
            component: () => import('@/views/Goods/GoodsAmend.vue'),
          },
          {
            path: '/goods/device',
            name: 'GoodsDevice',
            meta: {
              title: '关联设备',
              path: '/goods/device',
              affix: true,
            },
            component: () => import('@/views/Goods/GoodsDevice.vue'),
          },
          {
            path: '/goods/recommend',
            name: 'GoodsRecommend',
            meta: {
              title: '商品推荐',
              path: '/goods/recommend',
              affix: true,
            },
            component: () => import('@/views/Goods/GoodsRecommend.vue'),
          },
          {
            path: '/goods/recycle',
            name: 'GoodsRecycle',
            meta: {
              title: '回收站',
              path: '/goods/recycle',
              affix: true,
            },
            component: () => import('@/views/Goods/GoodsRecycle.vue'),
          },
          {
            path: '/specs/list',
            name: 'SpecsList',
            meta: {
              title: '规格管理',
              path: '/specs/list',
              affix: true,
            },
            component: () => import('@/views/Goods/SpecsList.vue'),
          },
          {
            path: '/specs/add',
            name: 'SpecsAdd',
            meta: {
              title: '通用规格添加',
              path: '/specs/add',
              back: true,
            },
            component: () => import('@/views/Goods/SpecsAdd.vue'),
          },
          {
            path: '/specs/amend',
            name: 'SpecsAmend',
            meta: {
              title: '通用规格值管理',
              path: '/specs/amend',
              back: true,
            },
            component: () => import('@/views/Goods/SpecsAmend.vue'),
          },
          {
            path: '/category',
            name: 'Category',
            meta: {
              title: '分类管理',
              path: '/category',
              affix: true,
            },
            component: () => import('@/views/Goods/Category.vue'),
          },
          {
            path: '/category/add',
            name: 'CategoryAdd',
            meta: {
              title: '分类添加',
              path: '/category',
              back: true,
            },
            component: () => import('@/views/Goods/CategoryAdd.vue'),
          },
          {
            path: '/category/amend',
            name: 'CategoryAmend',
            meta: {
              title: '分类修改',
              path: '/category',
              back: true,
            },
            component: () => import('@/views/Goods/CategoryAmend.vue'),
          },
          {
            path: '/category/detail',
            name: 'CategoryDetail',
            meta: {
              title: '分类管理',
              path: '/category',
              back: true,
            },
            component: () => import('@/views/Goods/CategoryDetail.vue'),
          },
          {
            path: '/hotSearch',
            name: 'HotSearch',
            meta: {
              title: '热门搜索',
              path: '/hotSearch',
              affix: true,
            },
            component: () => import('@/views/Goods/HotSearch.vue'),
          },
          {
            path: '/hotSearch/add',
            name: 'HotSearchAdd',
            meta: {
              title: '热门搜索添加',
              path: '/hotSearch',
              back: true,
            },
            component: () => import('@/views/Goods/HotSearchAdd.vue'),
          },
        ],
      },
      /** -- 版本管理 -- */
      {
        path: 'versionManage',
        name: 'VersionManage',
        redirect: '/appVersion/list',
        meta: {
          title: '版本管理',
          path: '/appVersion/list',
        },
        component: BlankView,
        children: [
          {
            path: '/appVersion/list',
            name: 'AppVersion',
            meta: {
              title: '应用版本管理',
              path: '/appVersion/list',
              affix: true,
            },
            component: () =>
              import('@/views/VersionManage/AppVersion/list.vue'),
          },
          {
            path: '/appVersion/add',
            name: 'AppVersionAdd',
            meta: {
              title: '添加应用版本',
              path: '/appVersion/add',
              back: true,
            },
            component: () => import('@/views/VersionManage/AppVersion/add.vue'),
          },
          {
            path: '/appVersion/amend',
            name: 'AppVersionAmend',
            meta: {
              title: '修改应用版本',
              path: '/appVersion/amend',
              back: true,
            },
            component: () =>
              import('@/views/VersionManage/AppVersion/amend.vue'),
          },
        ],
      },
      /** -- 资源包更新 -- */
      {
        path: 'updateAsset',
        name: 'UpdateAsset',
        redirect: '/update/asset',
        meta: {
          title: '资源包',
          path: '/update/asset',
        },
        component: BlankView,
        children: [
          {
            path: '/update/asset',
            name: 'UpdateAsset',
            meta: {
              title: '资源包更新',
              path: '/update/asset',
              affix: true,
            },
            component: () => import('@/views/AccountManage/UpdateAsset.vue'),
          },
        ],
      },
      /** 系统管理 */
      ...systemManage,
      /** 推送管理 */
      ...PushManage,
      /** -- 广告管理 -- */
      ...advertManage,
      /** -- 活动管理 -- */
      ...activityManage,
      /** -- 内容管理 -- */
      ...contentManage,
      /** -- 常见问题解答 -- */
      ...fqaManage,
      /** -- 硬件产品测试 -- */
      ...hardwareTest,
      /** 品牌管理 */
      // ...brandManage,
      /** 供应商管理 */
      ...supplierManage,
      /** 商城管理 */
      ...MallManage,
      /** 财务管理 */
      ...FinanceManage,
      /** 账单管理 */
      ...BillingManage,
      /** 抽奖管理 */
      ...LotteryManage,
      /** 问卷调查 */
      ...QuestionnaireSurvey,
      /** 评价管理 */
      ...ReviewManage,
      /** 签到活动 */
      ...SignManage,
    ],
  },
];
