
/**
 * @Module Verify
 * @Describe 数据验证
 **/

// 表单验证
const verifyForm = {
    /**
     * 验证数据是否为空
     * @parse {String} msg
     * @parse {Boolean} required
     * */
    isEmpty(msg, required = true) {
        return [{
            required,
            validator: (rule, value, callback) => {
                if (verifyData.isEmpty(value) || verifyData.isArray(value)) {
                    callback(msg);
                } else {
                    callback();
                }
            }
        }];
    },

    /**
     * 验证数据长度
     * @parse {Array} msg
     * @parse {String} len
     * @parse {Boolean} required
     * */
    isExtent(msg, len, required = true) {
        return [{
            required,
            validator: (rule, value, callback) => {
                let [min, max] = len.split('~'),
                    vLen = value.length;
                if (verifyData.isEmpty(value) && required) {
                    callback(msg[0]);
                } else if ((vLen < min || vLen > max) && value) {
                    callback(msg[1]);
                } else {
                    callback()
                }
            }
        }];
    },

    /**
     * 验证账号
     * @parse {Array} msg
     * @parse {Boolean} required
     * */
    isAccount(msg, required = true) {
        return [{
            required,
            validator: (rule, value, callback) => {
                if (verifyData.isEmpty(value) && required) {
                    callback(msg[0]);
                } else if (verifyData.isAccount(value)) {
                    callback(msg[1]);
                } else {
                    callback()
                }
            }
        }];
    },

    /**
     * 验证密码
     * @parse {Array} msg
     * @parse {Boolean} required
     * */
    isPassword(msg, required = true) {
        return [{
            required,
            validator: (rule, value, callback) => {
                if (verifyData.isEmpty(value) && required) {
                    callback(msg[0]);
                } else if (!verifyData.isEmpty(value)&&verifyData.isPassword(value)) {
                    callback(msg[1] || msg[0]);
                } else {
                    callback()
                }
            }
        }];
    },

    /**
     * 验证版本号
     * @parse {Array} msg
     * @parse {Boolean} required
     * */
    isVersion(msg, required = true) {
        return [{
            required,
            validator: (rule, value, callback) => {
                if (verifyData.isEmpty(value) && required) {
                    callback(msg[0]);
                } else if (verifyData.isVersion(value)) {
                    callback(msg[1]);
                } else {
                    callback()
                }
            }
        }];
    }
}

// 数据验证
const verifyData = {
    /**
     * 验证数据是否为空
     * @parse {Any} val
     * */
    isEmpty(val) {
        return val === undefined || val === null || val === '';
    },

    /**
     * 验证手机号
     * @parse {Any} val
     * */
    isMobile(val) {
        return !/^1[3456789]{10}$/.test(val);
    },

    /**
     * 验证数组是否为空
     * @parse {Any} val
     * */
    isArray(val) {
        return Array.isArray(val) && val.length == 0;
    },

    /**
     * 验证账号(数字英文组成)
     * @parse {Any} val
     * */
    isAccount(val) {
        const r = /^[0-9a-zA-Z]{1,30}$/;
        return !r.test(val);
    },

    /**
     * 验证密码(数字英文组成)
     * @parse {Any} val
     * */
    isPassword(val) {
        const r = /^[0-9a-zA-Z]{6,16}$/;
        return !r.test(val);
    },

    /**
     * 验证版本号
     * @parse {Any} val
     * */
    isVersion(val) {
        let r = /^\d{1,}.\d{1,}.\d{1,}$/;
        return !r.test(val);
    },
}

/* export verify */
export default {
    verifyForm,
    verifyData
}