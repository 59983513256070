<template>
  <!-- 首页 -->
  <el-container class="l-layout">
    <moe-aside />
    <el-container style="display: block">
      <moe-header />
      <moe-main />
    </el-container>

    <!-- 修改密码弹框 -->
    <moe-dialog :show="pwdShow" title="修改密码" width="500px" @close="pwdClose">
      <el-form class="pwd-form" label-width="100px" label-position="top">
        <el-form-item label="原密码" required>
          <el-input
            v-model.trim="pwd.oPwd"
            type="password"
            maxlength="16"
            placeholder="请输入原密码"
            clearable
            show-password>
          </el-input>
        </el-form-item>
        <el-form-item label="新密码" required>
          <el-input
            v-model.trim="pwd.nPwd"
            type="password"
            maxlength="16"
            placeholder="请输入新密码"
            clearable
            show-password>
          </el-input>
          <div class="form-hint_text">
            密码为数字或字母、组成，长度为 6 ~ 16
          </div>
        </el-form-item>
        <el-form-item label="确认密码" required>
          <el-input
            v-model.trim="pwd.aPwd"
            type="password"
            maxlength="16"
            placeholder="请再次输入新密码"
            clearable
            show-password
            @keyup.enter.native="amendPwd">
          </el-input>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button
          style="width: 80%"
          size="default"
          type="primary"
          round
          :loading="pwdLoad"
          @click="amendPwd"
          slot="footer">
          {{ pwdLoad ? '正在提交' : '提交信息' }}
        </el-button>
      </template>
    </moe-dialog>
  </el-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import moeHeader from '@/layout/moe-header';
import moeAside from '@/layout/moe-aside';
import moeMain from '@/layout/moe-main';
import moeFooter from '@/layout/moe-footer';

export default {
  name: 'Index',
  components: {
    [moeHeader.name]: moeHeader,
    [moeAside.name]: moeAside,
    [moeMain.name]: moeMain,
    [moeFooter.name]: moeFooter,
  },
  data() {
    return {
      loading: true,
      settingShow: false,
      pwd: {
        oPwd: '', //原密码
        nPwd: '', //新密码
        aPwd: '', //确认密码
      },
      pwdLoad: false, //修改密码加载状态
    };
  },
  methods: Object.assign(
    {
      /**
       * 修改密码
       **/
      amendPwd() {
        const pwd = this.pwd,
          reg = /^[0-9a-zA-Z._]{6,16}$/;
        if (pwd.oPwd === '') {
          this.$moe_msg.warning('请输入原密码');
        } else if (pwd.nPwd === '') {
          this.$moe_msg.warning('请输入新密码');
        } else if (!reg.test(pwd.nPwd)) {
          this.$moe_msg.warning('新密码由数字、字母、._组成，长度为 6 ~ 16');
        } else if (pwd.aPwd === '') {
          this.$moe_msg.warning('请再次输入新密码');
        } else if (pwd.nPwd !== pwd.aPwd) {
          this.$moe_msg.warning('两次密码输入不一致');
        } else {
          this.pwdLoad = true;
          this.$moe_api.LOGIN_API.amendPassword({
            oldPwd: pwd.oPwd,
            newPwd: pwd.nPwd,
          })
            .then((data) => {
              if (data.code == 200) {
                this.setPwdShow(false);
                this.$moe_msg.success('修改成功，请重新登录 !');
                setTimeout(() => {
                  this.$router.push('/login');
                }, 500);
              } else {
                this.$moe_msg.error(data.message);
              }
            })
            .finally(() => {
              this.pwdLoad = false;
            });
        }
      },

      /**
       * 修改密码弹出框关闭
       **/
      pwdClose() {
        //重置密码信息
        this.pwd = {
          oPwd: '', //原密码
          nPwd: '', //新密码
          aPwd: '', //确认密码
        };

        this.setPwdShow(false);
      },
    },

    mapMutations('setting', ['addTabList', 'setPwdShow', 'setMenuList']),

    mapMutations('user', ['setUserInfo'])
  ),

  /**
   * 获取系统数据
   * pwdShow: 修改密码弹框显示
   */
  computed: mapState('setting', ['pwdShow']),

  watch: {
    /**
     * 监听路由
     **/
    $route(route) {
      const meta = route.meta;
      if (meta.affix) this.addTabList(route.meta);
    },
  },
};
</script>

<style lang="scss" scoped>
.l-layout {
  height: 100vh;
}

.pwd-form {
  .el-input {
    height: 45px;

    ::v-deep .el-input__inner {
      line-height: 45px;
      height: 45px;
      border-radius: 45px !important;

      &::placeholder {
        font-size: 13px;
      }
    }
  }
}
</style>
