/**
 * Created by zed on 2022/3/14
 * Copyright © 2017 云宠智能 All rights reserved.
 *
 * 【智能设备路由】
 */
import BlankView from '@/views/BlankView.vue'

const smartEquipment = [
  {
    path: 'smart-equipment',
    name: 'SmartEquipmentManage',
    redirect: '/smart-equipment/list',
    meta: {
      title: '设备管理',
      path: '/smart-equipment/list',
    },
    component: BlankView,
    children: [
      {
        path: 'list',
        name: 'SmartEquipmentList',
        meta: {
          title: '设备型号管理',
          path: '/smart-equipment/list',
          affix: true,
        },
        component: () => import('@/views/EquipmentManage/SmartEquipmentList.vue')
      },
      {
        path: 'add',
        name: 'SmartEquipmentAdd',
        meta: {
          title: '设备型号添加',
          path: '/smart-equipment/add',
          back: true
        },
        component: () => import('@/views/EquipmentManage/SmartEquipmentAdd.vue')
      },
      {
        path: 'amend',
        name: 'SmartEquipmentAmend',
        meta: {
          title: '设备型号修改',
          path: '/smart-equipment/amend',
          back: true
        },
        component: () => import('@/views/EquipmentManage/SmartEquipmentAmend.vue')
      },
      {
        path: 'detail',
        name: 'SmartEquipmentDetail',
        meta: {
          title: '设备型号详情',
          path: '/smart-equipment/detail',
          back: true
        },
        component: () => import('@/views/EquipmentManage/SmartEquipmentDetail.vue')
      },
      {
        path: 'params',
        name: 'SmartEquipmentParams',
        meta: {
          title: '设备型号参数配置',
          path: '/smart-equipment/params',
          back: true
        },
        component: () => import('@/views/EquipmentManage/SmartEquipmentParams.vue')
      },
      {
        path: 'firmware',
        name: 'SmartEquipmentFirmware',
        meta: {
          title: '设备型号固件上传',
          path: '/smart-equipment/firmware',
          back: true
        },
        component: () => import('@/views/EquipmentManage/SmartEquipmentFirmware.vue')
      }
    ]
  }
]
export default smartEquipment
