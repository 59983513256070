/**
 * 运费模板
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

/* export API */
export default {
  /* 分页查询运费模板 */
  getFreightTplList(params) {
    return http({
      method: 'get',
      url: '/shop/freightTpl/list',
      params,
    });
  },

  /* 创建运费模板 */
  addFreightTpl(data) {
    return http2({
      method: 'post',
      url: '/shop/freightTpl',
      data,
    });
  },

  /* 获取运费模板详情 */
  getFreightTplDetail(params) {
    return http({
      method: 'get',
      url: '/shop/freightTpl/detail',
      params,
    });
  },

  /** 修改运费模板 */
  updateFreightTpl(data) {
    return http2({
      method: 'put',
      url: '/shop/freightTpl',
      data,
    });
  },

  /** 删除运费模板 */
  delFreightTplDetail(params) {
    return http({
      method: 'delete',
      url: '/shop/freightTpl',
      params,
    });
  },

  /** 设为默认运费模板 */
  asDefaultFreightTpl(data) {
    return http2({
      method: 'post',
      url: '/shop/freightTpl/asDefault',
      data,
    });
  },

  /** 复制运费模板 */
  copyFreightTpl(data) {
    return http2({
      method: 'post',
      url: '/shop/freightTpl/copy',
      data,
    });
  },
};