<!--
  /**
    * Created by Lichuangwei on 2023/3/16
    * Copyright © 2017 www.moemone.com. All rights reserved.
    */
 -->
<template>

  <div :class="['moe-upload', hideUpload ? 'moe-upload--hide-upload' : '']">
    <el-upload
      ref="moeUpload"
      :auto-upload="true"
      action=""
      :accept="acceptComputed"
      :limit="limit"
      :multiple="multiple"
      :file-list="fileList"
      :list-type="listType"
      :disabled="disabled"
      :on-remove="onRemove"
      :http-request="httpRequest"
      :on-change="onChange"
      :before-upload="beforeUpload"
      :on-exceed="onExceed">
      <div class="moe-upload-file" slot="file" slot-scope="{ file }">
        <el-image
          class="moe-upload-file__img"
          :src="getFile(file)"
          width="100%"
          height="100%"
          fit="contain"
          alt="" />
        <el-progress
          class="moe-upload-file__progress"
          type="circle"
          :width="100"
          :percentage="Math.round(file.percentage)"
          v-if="file.status === 'uploading'" />

        <span class="el-upload-list__item-actions" v-if="file.status === 'success'">
          <!-- <span
            v-if="!disabled"
            class="el-upload-list__item-preview"
            @click="handleFile('preview', file)">
            <i class="el-icon-zoom-in"></i>
          </span> -->
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleFile('download', file)">
            <i class="el-icon-download"></i>
          </span>
          <span
            v-if="!disabled && !readonly"
            class="el-upload-list__item-delete"
            @click="handleFile('remove', file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>

      <div slot="trigger" class="moe-upload__text">{{ uploadText }}</div>

      <div slot="tip" v-if="showTip && suffixList.length != 0 && fileList.length < limit" class="moe-upload__tip df aic">
        <slot name="tip" v-if="$slots.tip">
          <!-- 插槽 -->
        </slot>

        <template v-else>
          请上传 {{ suffixList.join(' / ') }} 格式的文件，限
          <div class="color-primary">{{ limit }}</div>
          个
        </template>
      </div>
    </el-upload>
  </div>
</template>

<script>
/**
 * 文件上传到oss - moe-upload-file
 * @property {String} ossKey         oss业务key 必传，详见props里注释
 * @property {Array} defaultFileList 默认图片回显
 * @property {String} suffix          上传文件类型 与 type 2选1 （suffix权重最高）
 * @property {String} type           上传文件类型 接受参数 image | video
 * @property {Number} size           上传文件大小 默认10 单位m (不需要传单位)
 * @property {Number} limit          文件数量
 *
 **/
import BMF from 'browser-md5-file';
import ossHttp from '@/utils/ossHttp';
import { reductionTo } from '@/utils/math';
export default {
  name: 'moe-upload-file',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    // 上传成功地址
    value: [String, Array],
    //文件列表，name、url
    defaultFileList: {
      type: Array,
      default: () => [],
    },
    //文件数量
    limit: {
      type: Number,
      default: 1,
    },

    //是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },

    //上传文件类型 image/video 目前这两种
    type: {
      type: String,
      default: 'image',
    },

    //文件大小
    size: {
      type: Number,
      default: 10,
    },

    //文件显示类型
    listType: {
      type: String,
      default: 'picture-card',
    },

    //是否可以多选，选取多个文件，但还是单个文件上传的，调用多次接口
    multiple: {
      type: Boolean,
      default: false,
    },

    //上传文件类型
    suffix: {
      type: Array,
      default: () => [],
    },

    //上传文件文本
    uploadText: {
      type: String,
      default: '上传',
    },

    showTip: {
      type: Boolean,
      default: true
    },

    /**
      * oss业务key
      * USER_AVATAR: 用户头像
      * PET_AVATAR: 宠物头像
      * PET_TYPE: 宠物类型图片
      * PET_CASE: 宠物病例图片
      * PET_FOOD_BRAND: 宠物粮食品牌图片
      * DEVICE_MODEL: 设备模型图片
      * DEVICE_SOFTWARE: 设备固件文件
      * SHOP_MEDIA: 商城图片
      * ICON: ICON图标 提醒事项
      * APP_RELEASE: 应用安装包
      * APP_LOG: 应用日志文件
      * AD_MEDIA: 广告媒体文件
      * ASSET: 资源包
      * PUSH: 推送资源文件
      * FACTORY_TEST_LOG: 厂测设备日志
      * SHOP_FAPIAO: 商城发票图片
      */
    ossKey: {
      type: String,
      default: '',
    },

    // 是否读取文件的比率
    getFileRatio: {
      type: Boolean,
      default: false
    },
    ratio: {
      type: Array,
      default: () => []
    },

    // 是否读取安卓apk文件的MD5值
    getAndroidApkMd5: {
      type: Boolean,
      default: false
    },
    md5: {
      type: String,
      default: ''
    },

    // 只读
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileList: this.defaultFileList,
      ratioList: this.ratio,  //比率列表
    }
  },
  watch: {
    defaultFileList(n) {
      this.fileList = n
    },
    ratio(n) {
      this.ratioList = n
    }
  },
  computed: {
    /** 上传文件类型 */
    suffixList() {
      let suffix = []
      if (this.suffix.length) {
        suffix = this.suffix
      } else {
        if (this.type === 'image') {
          suffix = ['jpg', 'png', 'jpeg', 'webp', 'gif']
        } else if (this.type === 'video') {
          suffix = ['swf', 'flv', 'mp4', 'rmvb', 'avi', 'mpeg', 'ra', 'ram', 'mov', 'wmv']
        }
      }
      return suffix
    },
    /** 接受上传的文件类型（thumbnail-mode 模式下此参数无效） */
    acceptComputed() {
      let string = ''
      if (this.suffix.length) {
        string = this.suffix.map((item) => {
          return `.${item}`
        }).join(',')
      } else {
        string = this.suffixList.map((item) => {
          return `.${item}`
        }).join(',')
      }
      return string
    },
    hideUpload() {
      return this.fileList.length >= this.limit
    },
  },
  methods: {
    /**
     * 获取文件信息
     **/
    getFile(file) {
      // 获取文件类型
      let suffix = file.name || file.url
      let type = suffix.substr(suffix.lastIndexOf('.') + 1)

      // 验证文件类型
      if (/(swf|flv|mp4|rmvb|avi|mpeg|ra|ram|mov|wmv)$/i.test(type)) return require('./img/video.svg')
      else if (/(mp3|wav|wma|ogg|ape|aac|m4a)$/i.test(type)) return require('./img/audio.svg')
      else if (/(png|jpeg|jpg|gif|webp)$/i.test(type)) return file.url
      else if (/(xls|xlsx)$/i.test(type)) return require('./img/excel.svg')
      else if (/(zip)$/i.test(type)) return require('./img/zip.svg')
      else if (/(pdf)$/i.test(type)) return require('./img/pdf.svg')
      else if (/(doc)$/i.test(type)) return require('./img/word.svg')
      else if (/(txt)$/i.test(type)) return require('./img/txt.svg')
      else return require('./img/file.svg')
    },
    /**
      * 自定义处理文件
      * @parse {String} mode 处理模式
      * @parse {Object} file 处理文件
      **/
    handleFile(mode, file) {
      switch (mode) {
        // 下载文件
        case 'download':
          var link = document.createElement('a');
          link.href = file.url;
          link.download = '';
          link.target = '_black';
          link.click();
          break
        case 'remove':
          if (this.getAndroidApkMd5) {
            this.readFileMd5("");
          }
          if (this.getFileRatio) {
            var findIndex = this.fileList.findIndex(({ uid }) => uid === file.uid);
            if (findIndex > -1) {
              this.ratioList.splice(findIndex, 1);
              this.$emit('update:ratio', this.ratioList);
            }
          }
          this.$refs.moeUpload.handleRemove(file);
          this.updateModel();
          break;
      }
    },
    updateModel() {
      this.$emit('change', this.fileList.map(({ tempFilePath }) => tempFilePath).join(','));
    },
    /** 读取文件的MD5值 */
    readFileMd5(file) {
      if (file) {
        const bmf = new BMF();
        bmf.md5(file, (err, md5) => { this.$emit('update:md5', md5) }, progress => { });
      } else {
        this.$emit('update:md5', '');
      }
    },
    /**
      * 文件列表移除文件
      * file, fileList
      */
    onRemove(file, fileList) {
      this.fileList = fileList;
    },
    /**
     * 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
     **/
    onChange(file, fileList) {
      this.fileList = fileList;
    },
    /** 覆盖默认的上传行为，可以自定义上传的实现 */
    async httpRequest({ file }) {
      let findFileItem = this.fileList.find(({ uid }) => uid === file.uid);
      findFileItem.status = 'uploading';

      const result = await this.$moe_yunHelper.getCompressorFile(file);

      const { region, accessKeyId, accessKeySecret, stsToken, bucket, path } = await this.$moe_yunHelper.getStsRequest(this.ossKey);

      let name = this.$moe_yunHelper.getuuidFormFileName(result || file);
      const formData = new FormData();
      formData.append('key', `${path}${name}`);
      formData.append('OSSAccessKeyId', accessKeyId);
      const policy = this.$moe_yunHelper.getpolicy();
      formData.append('policy', policy);
      const signature = this.$moe_yunHelper.computeSignature(accessKeySecret, policy);
      formData.append('signature', signature);
      formData.append('x-oss-security-token', stsToken);
      formData.append('file', result || file);

      await ossHttp({
        method: 'post',
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        //允许为上传处理进度事件 浏览器专属
        onUploadProgress: (progressEvent) => {
          let percentage = (progressEvent.loaded / progressEvent.total * 100 | 0);
          findFileItem.percentage = percentage;
        }
      }).then(() => {
        findFileItem.url = `${process.env.VUE_APP_OSS_UPLOAD}/${path}${name}`;
        findFileItem.tempFilePath = `${path}${name}`;
        this.updateModel();

        if (this.getAndroidApkMd5) {
          this.readFileMd5(file);
        }
      })
    },
    /**
      * 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
      **/
    beforeUpload(file) {
      //验证上传文件类型
      let type = file.name.substr(file.name.lastIndexOf('.') + 1)
      if (this.suffixList.length != 0 && !this.suffixList.includes(type)) {
        this.$moe_msg.warning(`请上传${this.suffixList.join(' / ')}类型的文件 !`);
        return false;
      }

      //判断文件大小
      let n_size = file.size //文件大小
      let o_size = this.size * 1024 ** 2 //限制大小

      if (n_size > o_size) {
        let unit = ['B', 'KB', 'MB', 'GB', 'TB'];
        let msg = '';
        unit.forEach((k, i) => {
          let num = o_size / 1024 ** i
          if (num > 1 && num < 1024) {
            msg = `${num} ${k}`
          }
        })
        this.$moe_msg.warning(`文件大小不能超过${msg}`);
        return false
      }

      // 是否获取文件比率
      if (this.getFileRatio) {
        if (file.type.indexOf('video') > -1) {
          const url = URL.createObjectURL(file)
          let video = document.createElement("video")
          video.onloadedmetadata = () => {
            URL.revokeObjectURL(url)
            let result = reductionTo(video.videoWidth, video.videoHeight)
            this.ratioList.push({
              ratio: `${result[0]}:${result[1]}`,
              wh: `${video.videoWidth}:${video.videoHeight}`
            })
            this.$emit('update:ratio', this.ratioList)
          }
          video.src = url
          video.load()
        } else if (file.type.indexOf('image') > -1) {
          let reader = new FileReader();
          reader.onload = (e) => {
            let txt = e.target.result
            let img = document.createElement("img")
            img.src = txt
            img.onload = () => {
              let result = reductionTo(img.width, img.height)
              this.ratioList.push({
                ratio: `${result[0]}:${result[1]}`,
                wh: `${img.width}:${img.height}`
              })
              this.$emit('update:ratio', this.ratioList)
            }
          }
          reader.readAsDataURL(file);
        }
      }
    },
    /**
      * 文件超出个数限制时的钩子
      **/
    onExceed() {
      this.$moe_msg.warning(`只能上传${this.limit}个文件`)
    }
  }
}
</script>

<style lang="scss" scoped>
.moe-upload {
  ::v-deep .el-upload-list {
    .el-upload-list__item {
      width: 100px;
      height: 100px;
    }
  }

  ::v-deep .el-upload {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    transition-property: opacity;
    transition-duration: 0.5s;
  }

  .moe-upload-file {
    width: 100%;
    height: 100%;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &__progress {
      width: 100%;
      height: 100%;
      background-color: #fff;
    }
  }

  .moe-upload__text {
    font-size: 12px;
    color: #999;
  }

  .moe-upload__tip {
    color: #999;
    font-size: 12px;
  }

  &.moe-upload--hide-upload ::v-deep .el-upload {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}
</style>