/**
 * 设备API
 **/

import http from '@/utils/http'
import qs from 'qs'

/* export API */
export default {

    /* 广告添加 */
    bannerAdd(data) {
        return http({
            method: 'post',
            url: '/shop/advert/insertShopAdvert',
            data: qs.stringify(data)
        })
    },

    /* 广告删除 */
     bannerDelete(params) {
        return http({
            method: 'get',
            url: '/shop/advert/deleteShopAdvert',
            params
        })
    },

    /* 广告详情 */
    bannerDetail(params) {
        return http({
            method: 'get',
            url: '/shop/advert/queryshopAdvertById',
            params
        })
    },


     /* 广告状态修改 */
    bannerStatus(data) {
        return http({
            method: 'post',
            url: '/shop/advert/shopAdvertShelf',
            data: qs.stringify(data)
        })
    },

     /* 广告修改 */
     bannerAmend(data) {
        return http({
            method: 'post',
            url: '/shop/advert/updateShopAdvert',
            data:qs.stringify(data)
        })
    },

}
