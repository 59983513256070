<template>
  <!-- 详情列表 -->
  <div class="moe-describe-list">
    <div class="moe-describe__title">{{ title }}</div>
    <div class="moe-describe__inner"><slot /></div>
  </div>
</template>

<script>
/**
 * 描述列表 - moe-describe-list
 * @description 用于显示数据详情信息
 * @property {String} title 标题
 * @property {Number} col 列数量
 **/
export default {
  name: 'moe-describe-list',
  provide() {
    return {
      ldescribeList: this,
    }
  },
  props: {
    //标题
    title: String,

    //列数量
    col: {
      type: Number,
      default: 4,
    },
  },
}
</script>

<style lang="scss" scoped>
.moe-describe-list {
  background-color: #fff;
  border-radius: 3px;
  padding: 15px;
  margin-bottom: 15px;
  // overflow: hidden;
}

.moe-describe__title {
  color: #333;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;
}

.moe-describe__inner {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .hidden-xs-only {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .hidden-sm-and-up {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hidden-sm-only {
    display: none !important;
  }
}
@media only screen and (max-width: 991px) {
  .hidden-sm-and-down {
    display: none !important;
  }
}
@media only screen and (min-width: 992px) {
  .hidden-md-and-up {
    display: none !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hidden-md-only {
    display: none !important;
  }
}
@media only screen and (max-width: 1199px) {
  .hidden-md-and-down {
    display: none !important;
  }
}
@media only screen and (min-width: 1200px) {
  .hidden-lg-and-up {
    display: none !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  .hidden-lg-only {
    display: none !important;
  }
}
@media only screen and (max-width: 1919px) {
  .hidden-lg-and-down {
    display: none !important;
  }
}
@media only screen and (min-width: 1920px) {
  .hidden-xl-only {
    display: none !important;
  }
}
</style>