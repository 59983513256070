/**
  * Created by Lichuangwei on 2023/1/5
  * Copyright © 2017 www.moemone.com. All rights reserved.
  *
  * 阿里云服务辅助工具
  */

import http from '@/utils/http'

/**
  * 获取STS临时访问凭证
  */
export const getSecurityToken = () => {
  return http({
    method: 'get',
    url: `/external/sts`,
  })
}

/**
  * 获取STS临时访问凭证
  */
export const getStsRequest = (params) => {
  return http({
    method: 'get',
    url: '/oss/getSts',
    params
  })
}