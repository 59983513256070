export const uploadHelper = {
  /**
    * 生成指定长度的随机名称
    * @param len         名称的长度
    * @return {string}   生成的随机名称
    */
  getRandomName(len) {
    const date = new Date()
    let month = date.getMonth() + 1
    if (month >= 1 && month <= 9) { month = '0' + month }
    let strDate = date.getDate()
    if (strDate >= 0 && strDate <= 9) { strDate = '0' + strDate }
    const currentDate = `${date.getFullYear()}${month}${strDate}`

    len = len || 32
    const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
    const maxPos = chars.length
    let pwd = ''
    for (let i = 0; i < len; i++) {
      pwd += chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return currentDate + pwd
  },

  /**
    * 获取文件后缀名
    * @return {string}   文件后缀名
    * @param filePath
    */
  getFileSuffixFromPath(filePath) {
    const pos = filePath.lastIndexOf('.')
    let suffix = ''
    if (pos !== -1) { suffix = filePath.substring(pos) }
    return suffix
  },

  /**
    * 根据文件类型生成随机文件名
    * @param file
    */
  getRandomNameFromFile(file) {
    let randomName = this.getRandomName(8)
    randomName += this.getFileSuffixFromPath(file.name)
    return randomName
  },

  /**
    * 图片上传前属性参数校验
    * @param file      待校验的图片
    * @return object   {isValid: 是否校验成功，message: 提示消息}
    */
  validateImageAttribute(file) {
    const validityType = ['image/svg+xml', 'image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'image/gif']
    if (validityType.indexOf(file.type) === -1) {
      return {
        isValid: false,
        message: '上传图片只能是 PNG、SVG、JPG 格式!'
      }
    }
    const maxSizeInMb = 10
    if (file.size > maxSizeInMb * 1024 * 1024) {
      return {
        isValid: false,
        message: '上传图片大小不能超过' + maxSizeInMb + 'MB!'
      }
    }
    return {
      isValid: true,
      message: '图片属性校验合格'
    }
  },

  /**
    * 视频上传前属性参数校验
    * @param file      待校验的图片
    * @return object   {isValid: 是否校验成功，message: 提示消息}
    */
  validateVideoAttribute(file) {
    const validityType = ['video/mp4', 'video/quicktime']
      if (validityType.indexOf(file.type) === -1) {
        return {
          isValid: false,
          message: '仅支持 MP4 格式的视频!'
        }
      }
      const maxSizeInMb = 300
      if (file.size > maxSizeInMb * 1024 * 1024) {
        return {
          isValid: false,
          message: '上传的视频大小不能超过' + maxSizeInMb + 'MB!'
        }
      }
    return {
      isValid: true,
      message: '视频格式校验合格'
    }
  },
  /**
    * 音频上传前属性参数校验
    * @param file      待校验的图片
    * @return object   {isValid: 是否校验成功，message: 提示消息}
    */
  validateAudioAttribute(file) {
    console.log(file.type)
    const validityType = ['audio/mpeg']
    if (validityType.indexOf(file.type) === -1) {
      return {
        isValid: false,
        message: '仅支持 MP3 格式的音频!'
      }
    }
    const maxSizeInMb = 100
    if (file.size > maxSizeInMb * 1024 * 1024) {
      return {
        isValid: false,
        message: '上传的音频大小不能超过' + maxSizeInMb + 'MB!'
      }
    }
    return {
      isValid: true,
      message: '音频格式校验合格'
    }
  },
}