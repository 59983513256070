/** -- 广告管理 -- */

import BlankView from '@/views/BlankView.vue';

const advertManage = [
  // {
  //   path: 'advertManage',
  //   redirect: 'noredirect',
  //   meta: {
  //     title: '广告管理',
  //     icon: 'banner-list',
  //   },
  //   component: BlankView,
  //   children: [
  //     {
  //       path: '/appAdvert',
  //       redirect: '/appAdvert/list',
  //       meta: {
  //           title: 'APP广告',
  //           affix: true,
  //           icon: 'banner-list',
  //       },
  //       component: BlankView,
  //       children: [
  //         {
  //           path: 'list',
  //           meta: {
  //               title: 'APP广告列表',
  //               affix: true,
  //           },
  //           component: () => import('@/views/AdvertManage/AppAdvert/list.vue'),
  //         },
  //         {
  //           path: 'add',
  //           meta: {
  //               title: 'APP广告添加',
  //               affix: true,
  //           },
  //           component: () => import('@/views/AdvertManage/AppAdvert/add.vue'),
  //         }
  //       ]
  //     }
  //   ]
  // }
  {
    path: 'advertManage',
    name: 'AdvertManage',
    redirect: '/appAdvert/list',
    meta: {
      title: '广告管理',
      path: '/appAdvert/list',
    },
    component: BlankView,
    children: [
      {
        path: '/appAdvert/list',
        name: 'AppAdvertList',
        meta: {
          title: 'APP广告',
          path: '/appAdvert/list',
          affix: true,
        },
        component: () => import('@/views/AdvertManage/AppAdvert/list.vue'),
      },
      {
        path: '/appAdvert/add',
        name: 'AppAdvertAdd',
        meta: {
          title: 'APP广告添加',
          path: '/appAdvert/add',
          affix: true,
        },
        component: () => import('@/views/AdvertManage/AppAdvert/add.vue'),
      },
      {
        path: '/appAdvert/amend',
        name: 'AppAdvertAmend',
        meta: {
          title: 'APP广告修改',
          path: '/appAdvert/amend',
          affix: true,
        },
        component: () => import('@/views/AdvertManage/AppAdvert/amend.vue'),
      },
      {
        path: '/appAdvert/detail',
        name: 'AppAdvertDetail',
        meta: {
          title: 'APP广告详情',
          path: '/appAdvert/detail',
          affix: true,
        },
        component: () => import('@/views/AdvertManage/AppAdvert/detail.vue'),
      },
    ],
  },
  {
    path: 'goodsAdvert',
    name: 'GoodsAdvert',
    // redirect: '/goodsAdvert/list',
    meta: {
      title: '广告管理',
      path: '/goodsAdvert/list',
    },
    component: BlankView,
    children: [
      {
        path: '/goodsAdvert/list',
        name: 'GoodsAdvertList',
        meta: {
          title: '商城广告',
          path: '/goodsAdvert/list',
          affix: true,
        },
        component: () => import('@/views/AdvertManage/GoodsAdvert/list.vue'),
      },
      {
        path: '/goodsAdvert/add',
        name: 'GoodsAdvertAdd',
        meta: {
          title: '商城广告添加',
          path: '/goodsAdvert/add',
          affix: true,
        },
        component: () => import('@/views/AdvertManage/GoodsAdvert/add.vue'),
      },
      {
        path: '/goodsAdvert/amend',
        name: 'GoodsAdvertAmend',
        meta: {
          title: '商城广告修改',
          path: '/goodsAdvert/amend',
          affix: true,
        },
        component: () => import('@/views/AdvertManage/GoodsAdvert/amend.vue'),
      },
      {
        path: '/goodsAdvert/detail',
        name: 'GoodsAdvertDetail',
        meta: {
          title: '商城广告详情',
          path: '/goodsAdvert/detail',
          affix: true,
        },
        component: () => import('@/views/AdvertManage/GoodsAdvert/detail.vue'),
      },
    ],
  },
];

export default advertManage;
