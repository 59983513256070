/**
  * 商品管理
  **/

import http from '@/utils/http'
import http2 from '@/utils/http2'
import qs from 'qs'

/* export API */
export default {
    /* 物流公司添加 */
    expressAdd(data) {
        return http({
            method: 'post',
            url: '/shop/express/insertShopExpress',
            data:qs.stringify(data)
        })
    },

    /* 物流公司修改 */
    updateShopExpress(data) {
        return http({
            method: 'post',
            url: '/shop/express/updateShopExpress',
            data:qs.stringify(data)
        })
    },

    /* 物流公司详情 */
    queryShopExpressById(params) {
        return http({
            method: 'get',
            url: '/shop/express/queryShopExpressById',
            params
        })
    },

    /* 物流公司删除 */
    deleteShopExpress(params) {
        return http2({
            method: 'get',
            url: '/shop/express/deleteShopExpress',
            params
        })
    },
    /* 所有物流公司*/
    allExpressList() {
        return http({
            method: 'get',
            url: '/shop/express/queryShopExpressList'
        })
    },
}
