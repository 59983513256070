<template>
  <!-- 登录页面 -->
  <div class="login-page">
    <!-- 登录内容 -->
    <login-main />

    <!-- 登录背景 -->
    <login-bg />
  </div>
</template>

<script>
import LoginBg from './components/LoginBg.vue';
import LoginMain from './components/LoginMain.vue';
export default {
  name: 'Login',
  components: {
    LoginBg,
    LoginMain,
  }
};
</script>

<style lang="scss" scoped>
.login-page {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
</style>
