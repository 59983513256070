<template>
  <div :class="['moe-upload', hideUpload ? 'moe-upload--hide-upload' : '']">
    <el-upload
      ref="moeUpload"
      :action="$moe_config.serveUrl + action"
      :data="data"
      :name="name"
      :accept="accept"
      :limit="limit"
      :multiple="multiple"
      :headers="headers"
      :show-file-list="showFileList || listType !== 'custom'"
      :list-type="listType"
      :auto-upload="autoUpload"
      :file-list="defaultFileList"
      :disabled="disabled"
      :on-preview="onPreview"
      :on-remove="onRemove"
      :on-success="onSuccess"
      :on-error="onError"
      :on-progress="onProgress"
      :on-change="onChange"
      :before-upload="beforeUpload"
      :before-remove="beforeRemove"
      :on-exceed="onExceed">
      <div class="moe-upload-file" slot="file" slot-scope="{ file }">
        <el-image
          class="moe-upload-file__img"
          :src="getFile(file)"
          width="100%"
          height="100%"
          fit="contain"
          alt="" />
        <el-progress
          class="moe-upload-file__progress"
          type="circle"
          :width="100"
          :percentage="Math.round(file.percentage)"
          v-if="file.status === 'uploading'" />

        <span class="el-upload-list__item-actions" v-if="file.status === 'success'">
          <!-- <span
            v-if="!disabled"
            class="el-upload-list__item-preview"
            @click="handleFile('preview', file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span> -->
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleFile('download', file)">
            <i class="el-icon-download"></i>
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleFile('remove', file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>

      <div slot="trigger" class="moe-upload__text">{{ uploadText }}</div>

      <div
        slot="tip"
        class="moe-upload__tip"
        v-if="showTip"
        v-show="suffix.length != 0 && fileList.length < limit">
        请上传 {{ suffix.join(' / ') }} 格式的文件，限{{ limit }}个
        <slot name="tip" v-if="$slots.tip"></slot>
      </div>
    </el-upload>
  </div>
</template>

<script>
import { reductionTo } from '@/utils/math'
/**
 * 文件上传 - moe-upload
 * @description 基于el-upload组件的二次封装，文件上传
 * @property {String Object} value 上传成功后的文件地址，双向绑定
 * @property {String} action 上传地址
 * @property {Object} data 上传数据
 * @property {String} name 上传文件字段名
 * @property {String} headers 请求头
 * @property {String} accept 打开文件夹，筛选文件类型，逗号隔开
 * @property {Number} limit 文件数量
 * @property {Boolean} multiple 是否可以多选，选取多个文件，但还是单个文件上传的，调用多次接口
 * @property {Boolean} showFileList 是否显示文件列表
 * @property {String} listType 文件显示类型
 * @property {Boolean} autoUpload 是否自动上传
 * @property {Array} defaultFileList 文件列表，name、url
 * @property {Boolean} disabled 是否禁用
 * @property {String} uploadText 上传文件文本
 * @property {Array} suffix 上传文件类型
 * @property {Number} size 文件大小
 * @event {Function} change 监听文件地址变化，触发父组件的表单验证
 **/
export default {
  name: 'moe-upload',
  components: {},
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    // 上传成功地址
    value: [String, Array],

    //自定义数据格式，传递一个函数，该函数返回一个对象，格式为{url,name}
    customFormat: Function,

    //上传地址
    action: {
      type: String,
      default: '',
    },

    //上传数据
    data: {
      type: Object,
      default: () => {},
    },

    //上传文件字段名
    name: {
      type: String,
      default: 'file',
    },

    //请求头
    headers: {
      type: Object,
      default: () => ({ authorization: sessionStorage.getItem('token') }),
    },

    //上传文件类型
    accept: String,

    //文件数量
    limit: {
      type: Number,
      default: 3,
    },

    //是否可以多选，选取多个文件，但还是单个文件上传的，调用多次接口
    multiple: {
      type: Boolean,
      default: false,
    },

    //是否显示文件列表
    showFileList: {
      type: Boolean,
      default: true,
    },

    //文件显示类型
    listType: {
      type: String,
      default: 'picture-card',
    },

    //是否自动上传
    autoUpload: {
      type: Boolean,
      default: true,
    },

    //文件列表，name、url
    defaultFileList: {
      type: Array,
      default: () => [],
    },

    //是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },

    //上传文件文本
    uploadText: {
      type: String,
      default: '上传',
    },

    //上传文件类型
    suffix: {
      type: Array,
      default: () => [],
    },

    //文件大小
    size: {
      type: Number,
      default: 5,
    },

    ratio: {
      type: Array,
      default: () => []
    },

    showTip: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      previewShow: false, //预览文件
      fileList: this.defaultFileList, //文件列表
      ratioList: [],
    }
  },
  computed: {
    hideUpload() {
      return this.fileList.length >= this.limit
    },
  },
  watch: {
    defaultFileList(n) {
      this.fileList = n
    },
  },
  methods: {
    /**
     * 获取文件信息
     **/
    getFile(file) {
      // 获取文件类型
      let suffix = file.name || file.url
      let type = suffix.substr(suffix.lastIndexOf('.') + 1)

      // 验证文件类型
      if (/(swf|flv|mp4|rmvb|avi|mpeg|ra|ram|mov|wmv)$/i.test(type))
        return require('./img/video.svg')
      else if (/(mp3|wav|wma|ogg|ape|aac|m4a)$/i.test(type))
        return require('./img/audio.svg')
      else if (/(png|jpeg|jpg|gif)$/i.test(type)) return file.url
      else if (/(xls|xlsx)$/i.test(type)) return require('./img/excel.svg')
      else if (/(zip)$/i.test(type)) return require('./img/zip.svg')
      else if (/(pdf)$/i.test(type)) return require('./img/pdf.svg')
      else if (/(doc)$/i.test(type)) return require('./img/word.svg')
      else if (/(txt)$/i.test(type)) return require('./img/txt.svg')
      else return require('./img/file.svg')
    },
    /**
     * 自定义处理文件
     * @parse {String} mode 处理模式
     * @parse {Object} file 处理文件
     **/
    handleFile(mode, file) {
      switch (mode) {
        // 预览文件
        case 'preview':
          break
        // 下载文件
        case 'download':
          var link = document.createElement('a')
          link.href = file.url
          link.download = ''
          link.target = '_black'
          link.click()
          break
        // 删除文件
        case 'remove':
          var findIndex = this.fileList.findIndex(({ uid }) => uid === file.uid)
          if (findIndex > -1) {
            this.ratioList.splice(findIndex, 1)
            this.$emit('update:ratio', this.ratioList)
          }
          this.$refs.moeUpload.handleRemove(file)
          if (Array.isArray(this.value)) {
            this.updateModel(this.fileList.filter(({ uid }) => uid !== file.uid).map(({ url }) => url))
          } else {
            this.updateModel(this.fileList.filter(({ uid }) => uid !== file.uid).map(({ url }) => url)[0])
          }
          break
        default:
          break
      }
    },

    /**
     * 更新绑定数据
     **/
    updateModel(imgUrl) {
      if (Array.isArray(this.value)) {
        let list = []
        this.fileList.forEach(({ url, raw, response }) => {
          list.push(raw ? response && response.result : url)
        })
        this.$emit('change', list)
      } else {
        this.$emit('change', imgUrl)
      }
    },

    /**
     * 关闭预览
     **/
    previewClose() {
      this.previewShow = false
    },

    /**
     * 点击文件列表中已上传的文件
     **/
    onPreview() {},

    /**
     * 文件列表移除文件
     * file, fileList
     **/
    onRemove(file, fileList) {
      this.fileList = fileList
    },

    /**
     * 文件上传成功
     **/
    onSuccess(data) {
      if (data.code == 401) {
        // 删除文件
        this.$refs.moeUpload.clearFiles()

        this.$moe_msg.error('登录过期，请重新登录 !')
        this.$router.go(-1);
      } else if (data.code == 200) {
        let url = data.result
        this.updateModel(url)
      } else {
        // 删除文件
        this.$refs.moeUpload.clearFiles()

        this.$moe_msg.error(data.message)
        this.updateModel('')
      }

    },

    /**
     * 文件上传失败
     * err, file, fileList
     **/
    onError() {
      this.$moe_msg.error('上传失败，请重试 !')
    },

    /**
     * 文件上传进度
     * event, file, fileList
     **/
    onProgress() {},

    /**
     * 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
     **/
    onChange(file, fileList) {
      this.fileList = fileList
    },

    /**
     * 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
     **/
    beforeUpload(file) {
      //验证上传文件类型
      let type = file.name.substr(file.name.lastIndexOf('.') + 1)
      let suffix = this.suffix
      if (suffix.length != 0 && !suffix.includes(type)) {
        this.$moe_msg.warning(`请上传${suffix.join(' / ')}类型的文件 !`)
        return false
      }

      //判断文件大小
      let n_size = file.size //文件大小
      let o_size = this.size * 1024 ** 2 //限制大小
      if (n_size > o_size) {
        let unit = ['B', 'KB', 'MB', 'GB', 'TB']
        let msg = ''
        unit.forEach((k, i) => {
          let num = o_size / 1024 ** i
          if (num > 1 && num < 1024) {
            msg = `${num} ${k}`
          }
        })
        this.$moe_msg.warning(`文件大小不能超过${msg}`)
        return false
      }
      if (file.type.indexOf('video') > -1) {
        const url = URL.createObjectURL(file)
        let video = document.createElement("video")
        video.onloadedmetadata = () => {
          URL.revokeObjectURL(url)
          let result = reductionTo(video.videoWidth, video.videoHeight)
          this.ratioList.push({
            ratio: `${result[0]}:${result[1]}`,
            wh: `${video.videoWidth}:${video.videoHeight}`
          })
          this.$emit('update:ratio', this.ratioList)
        }
        video.src = url
        video.load()
      } else {

        let reader = new FileReader();
        reader.onload = (e) => {
          let txt = e.target.result
          let img = document.createElement("img")
          img.src = txt
          img.onload = () => {
            let result = reductionTo(img.width, img.height)
            this.ratioList.push({
              ratio: `${result[0]}:${result[1]}`,
              wh: `${img.width}:${img.height}`
            })
            this.$emit('update:ratio', this.ratioList)
          }
        }
        reader.readAsDataURL(file);
      }
    },

    /**
     * 删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除。
     **/
    beforeRemove() {},

    /**
     * 文件超出个数限制时的钩子
     **/
    onExceed() {
      this.$moe_msg.warning(`只能上传${this.limit}个文件`)
    }
  },
}
</script>

<style lang="scss" scoped>
.moe-upload {
  ::v-deep .el-upload-list {
    .el-upload-list__item {
      width: 100px;
      height: 100px;
    }
  }

  ::v-deep .el-upload {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    transition-property: opacity;
    transition-duration: 0.5s;
  }

  .moe-upload-file {
    width: 100%;
    height: 100%;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &__progress {
      width: 100%;
      height: 100%;
      background-color: #fff;
    }
  }

  .moe-upload__text {
    font-size: 12px;
    color: #999;
  }

  .moe-upload__tip {
    color: #999;
    font-size: 12px;
  }
}

.moe-upload--hide-upload ::v-deep .el-upload {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
</style>