<template>
  <el-footer class="moe-footer"></el-footer>
</template>

<script>
export default {
  name: 'moe-footer',
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
</style>
