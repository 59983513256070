<template>
  <el-radio-group class="moe-radio-group" v-model="radioList" @change="changeHandle" :disabled="disabled">
    <el-radio :label="item.value" :border="border" v-for="(item, index) in radioBoxList" :key="index">{{ item.label }}</el-radio>
  </el-radio-group>
</template>

<script>
export default {
  name: 'moeRadioGroup',
  model: {
    props: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    radioBoxList: {
      type: Array,
      default: () => []
    },
    border: { //是否显示边框
      type: Boolean,
      default: false
    },
    disabled: { //是否禁用
      type: Boolean,
      default: false,
    }
  },
  watch: {
    value(val) {
      this.radioList = val;
    }
  },
  data() {
    return {
      radioList: this.value
    }
  },
  methods: {
    changeHandle(radioBoxList) {
      this.$emit('input', radioBoxList);
      this.$emit('change', radioBoxList);
    },
  },
}
</script>

<style lang="scss" scoped>
</style>