<template>
  <!-- 首页头部 -->
  <el-header class="moe-header" height="auto">
    <nav-view />
    <!-- <tab-view /> -->
  </el-header>
</template>

<script>
import navView from './nav-view.vue';
import tabView from './tab-view.vue';
export default {
  name: 'moe-header',
  components: {
    navView,
    tabView
  },
};
</script>

<style lang="scss" scoped>
.moe-header {
  width: 100%;
  position: relative;
  z-index: 100;
  padding: 0;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 21, 41, 0.15);
}
.nav-view{
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  .nav-view__item[data-v-54ace8a4]:hover {
    background-color: red;
  }
  .el-dropdown{
    color: #fff;
  }
}
</style>
