/**
  -- Created by Lichuangwei on 2024/1/19
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 问卷调查
* */

import BlankView from '@/views/BlankView.vue';

const QuestionnaireSurvey = [
  {
    path: 'questionnaireSurvey',
    name: 'QuestionnaireSurvey',
    meta: {
      title: '问卷调查',
      path: '/questionnaireSurvey/list',
    },
    component: BlankView,
    children: [
      {
        path: '/questionnaireSurvey/list',
        name: 'QuestionnaireSurveyList',
        meta: {
          title: '问卷调查',
          path: '/questionnaireSurvey/list',
          affix: true,
        },
        component: () => import('@/views/QuestionnaireSurvey/List.vue'),
      },
      {
        path: '/questionnaireSurvey/add',
        name: 'QuestionnaireSurveyAdd',
        meta: {
          title: '新建问卷',
          path: '/questionnaireSurvey/add',
          affix: true,
        },
        component: () => import('@/views/QuestionnaireSurvey/Add.vue'),
      },
      {
        path: '/questionnaireSurvey/detail',
        name: 'QuestionnaireSurveyDetail',
        meta: {
          title: '查看问卷',
          path: '/questionnaireSurvey/detail',
          affix: true,
        },
        component: () => import('@/views/QuestionnaireSurvey/Detail.vue'),
      },
      {
        path: '/questionnaireSurvey/record',
        name: 'QuestionnaireSurveyRecord',
        meta: {
          title: '查看结果',
          path: '/questionnaireSurvey/record',
          affix: true,
        },
        component: () => import('@/views/QuestionnaireSurvey/Record.vue'),
      },
    ],
  },
];

export default QuestionnaireSurvey;