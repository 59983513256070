<template>
  <el-switch
    class="moe-switch"
    v-model="defaultValue"
    :active-value="activeValue"
    :inactive-value="inactiveValue"
    :active-color="activeColor"
    :inactive-color="inactiveColor"
    :active-text="activeText"
    :inactive-text="inactiveText"
    :disabled="disabled"
    @change="onChange">
  </el-switch>
</template>

<script>
/**
 * 下拉框 - moe-switch
 * @description 基于el-switch组件的二次封装
 * @property {String} activeValue   switch 打开时的值
 * @property {String} inactiveValue switch 关闭时的值
 * @property {String} activeColor   switch 打开时的背景色
 * @property {String} inactiveColor switch 关闭时的背景色
 * @property {String} activeText    switch 打开时的文字描述
 * @property {String} inactiveText  switch 关闭时的文字描述
 * @property {String} disabled      switch 是否禁用
 * @event {Function} change         switch 状态发生变化时的回调函数
 **/
export default {
  name: 'moe-switch',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [Boolean, String, Number],
      default: '',
    },
    activeValue: {
      type: [Boolean, String, Number],
      default: true
    },
    inactiveValue: {
      type: [Boolean, String, Number],
      default: false
    },
    activeColor: {
      type: String,
      default: '#dfa663'
    },
    inactiveColor: {
      type: String,
      default: ''
    },
    activeText: {
      type: String,
      default: '',
    },
    inactiveText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      defaultValue: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.defaultValue = newValue;
    },
  },
  methods: {
    onChange(e) {
      this.$emit('change', e)
    },
  },
}
</script>

<style lang="scss">
.moe-switch {

}
</style>