/**
  -- Created by Lichuangwei on 2023/6/5
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 硬件产品测试
  **/

import BlankView from '@/views/BlankView.vue';

const hardwareTest = [
  {
    path: 'hardwareTest',
    name: 'HardwareTest',
    redirect: '/hardwareTest/testRecord/list',
    meta: {
      title: '硬件产品测试',
      path: '/hardwareTest/testRecord/list',
    },
    component: BlankView,
    children: [
      {
        path: '/hardwareTest/testRecord/list',
        name: 'HardwareTestTestRecordList',
        meta: {
          title: '硬件测试记录',
          path: '/hardwareTest/testRecord/list',
          affix: true,
        },
        component: () => import('@/views/HardwareTest/testRecord/list.vue'),
      },
      {
        path: '/hardwareTest/testWhiteList/list',
        name: 'HardwareTestTestWhiteListList',
        meta: {
          title: '测试白名单',
          path: '/hardwareTest/testWhiteList/list',
          affix: true,
        },
        component: () => import('@/views/HardwareTest/testWhiteList/list.vue'),
      },
    ],
  },
];

export default hardwareTest;