import { app } from '../main';

/** 路由重定向 */
export const redirectTo = (path) => {
  app.$router.push(path);
};

/** 新窗口打开的页面 */
export const navigateTo = (path, query) => {
  let routeData = app.$router.resolve({
    path,
    query: query || {},
  });

  window.open(routeData.href, '_blank');
};

/** 只有通过window.open()打开的页面才可以通过window.close()关闭 */
export const navigateBack = () => {
  window.close();
}

export default {
  redirectTo,
  navigateTo,
  navigateBack,
};
