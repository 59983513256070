<!-- 表格 ~ moe-table -->
<template>
  <!-- 表格 -->
  <div class="moe-table">
    <!-- 表格工具栏 -->
    <div class="moe-table__tool" v-if="toolShow">
      <slot name="tool" />
    </div>

    <!-- 表格信息 -->
    <div
      class="moe-table__cont"
      v-loading="loading"
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255,255,255,.5)">
      <!-- 表格数据 -->
      <el-table
        ref="moeTable"
        :data="mode ? tableData : data"
        :row-key="rowKey"
        :header-row-class-name="`moe-table__head ${multiple ? 'multiple' : ''}`"
        :row-class-name="tableRowClassName"
        cell-class-name="moe-table__cell"
        :height="height"
        :maxHeight="maxHeight ? maxHeight : '100%'"
        :border="false"
        :select-on-indeterminate="true"
        :stripe="stripe"
        :empty-text="emptyText"
        :tree-props="{ children: children }"
        @select="select"
        @select-all="selectAll"
        @selection-change="selectChange">
        <!-- 表格空数据状态 -->
        <template slot="empty">
          <div class="moe-table__empty" v-show="status == 'finish'">
            <moe-icon class="moe-table__empty-icon" name="empty" size="40" />
            <div class="moe-table__empty-text">{{ tableText }}</div>
          </div>
          <div class="moe-table__empty" v-show="status == 'error'">
            <moe-icon class="moe-table__empty-icon" name="fail" size="40"/>
            <div class="moe-table__empty-text">{{ tableText }}</div>
          </div>
        </template>
        <!-- 序列号 -->
        <el-table-column
          type="index"
          :index="getIndex"
          width="70"
          label="序号"
          fixed="left"
          v-if="numberShow"
        />
        <slot />
      </el-table>

      <!-- 表格分页 -->
      <!--  v-if="mode" -->
      <div class="moe-table__page" v-if="showPage" :style="mode?'':'visibility: hidden;'">
        <el-pagination
          layout="total,prev,pager,next,sizes,jumper"
          :current-page="params[pageOption.current]"
          :page-size="params[pageOption.size]"
          :page-sizes="[10, 20, 30, 50, 100, 500]"
          :total="total"
          :hide-on-single-page="false"
          background
          @current-change="currentChange"
          @size-change="sizeChange" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 表格 - moe-table
 * @description 基于el-table组件的二次封装，数据表格
 * @property {String} url 表格请求地址
 * @property {String} method 请求方式
 * @property {String} rowKey row-key值
 * @property {Object} params 筛选信息
 * @property {String} loadingText 加载文字
 * @property {Boolean} numberShow 是否显示序列号
 * @property {Boolean} toolShow 是否显示工具栏
 * @property {String} height 高度
 * @property {Object} pageOption 表格分页筛选字段名
 * @property {Array} defaultSelectIds 默认选中ID
 * @property {Boolean} mode 表格类型
 * @property {Array} data 默认表格数据
 * @property {String} emptyText 空数据文本
 * @property {String} stripe 是否为斑马纹 table
 * @event {Function} select 监听表格复选框
 * @event {Function} selectAll 监听表格全选复选框
 * @event {Function} selectChange 监听表格复选框选项
 **/
export default {
  name: 'moe-table',
  props: {
    //api地址
    url: {
      type: String,
      default: '',
    },

    //请求方式
    method: {
      type: String,
      default: 'get',
    },

    //row-key值
    rowKey: {
      type: String,
      default: '',
    },

    //筛选信息
    params: {
      type: Object,
      default: () => ({
        'pageNum': 1
      }),
    },

    //加载提示
    loadingText: {
      type: String,
      default: '',
    },

    //是否显示序列号
    numberShow: {
      type: Boolean,
      default: true,
    },

    //是否显示工具栏
    toolShow: {
      type: Boolean,
      default: true,
    },

    //高度
    height: {
      type: String,
      default: '53vh',
    },

    maxHeight: {
      type: [String, Number],
      default: 0,
    },

    //分页筛选current & size 字段名
    pageOption: {
      type: Object,
      default: () => ({
        current: 'pageNum',
        size: 'pageSize',
      }),
    },

    // 默认选中ID
    defaultSelectIds: {
      type: Array,
      default: () => [],
    },

    // 表格类型
    mode: {
      type: Boolean,
      default: true,
    },

    //默认表格数据
    data: {
      type: Array,
      default: () => [],
    },

    // 空数据文本
    emptyText: {
      type: String,
      default: '暂无数据',
    },

    children: {
      type: String,
      default: 'children'
    },

    showPage: {
      type: Boolean,
      default: true
    },

    tableRowClassName: {
      type: Function,
      default: () => {}
    },

    stripe: {
      type: Boolean,
      default: false
    },

    /** 是否可以多选 */
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    tableData() {
      if (this.defaultSelectIds.length) {
        this.defaultSelectIds.forEach((id) => {
          this.tableData.forEach((row) => {
            if (id === row[`${this.rowKey}`]) {
              let flag = this.tableRef.selection.some((item) => item[`${this.rowKey}`] === id);
              if (!flag) {
                this.tableRef.toggleRowSelection(row, true);
              }
            }
          })
        })
      } else {
        this.clearSelect();
      }
    },
  },
  data() {
    return {
      tableRef: null, //表格ref
      tableData: [], //表格数据
      loading: this.mode, //加载表格信息
      status: this.mode ? 'loading' : 'finish', //表格状态
      total: 0, //表格数据总数
      tableText: this.emptyText, //空文本信息
      selectionData: [], //复选框选中数据
      selectionRow: null, //当前选中行
    };
  },
  created() {
    //加载表格数据
    // this.mode && this.loadingData();
    if (this.mode) {
      this.loadingData();
    } else {
      this.tableData = this.data;
    }
  },
  mounted() {
    //获取表格ref
    this.tableRef = this.$refs.moeTable;
  },
  methods: {
    /**
     * 获取表格索引
     **/
    getIndex(index) {
      let current = this.params[this.pageOption.current];
      let size = this.params[this.pageOption.size];
      return (current - 1) * size + index + 1;
    },

    /**
     * 加载表格数据
     **/
    loadingData() {
      //清空表格数据，重新加载
      this.tableData = [];
      this.loading = true;
      this.status = 'loading';
      this.tableText = this.emptyText;

      //获取表格数据
      const getData = () => {
        //创建请求
        const { url, method } = this.$props;
        let params = {}
        for (let key in this.params) {
          if (this.params[key] !== '') {
            params[key] = this.params[key]
          }
        }
        this.$moe_http({
          url, //请求接口地址
          method, //请求方式
          params, //请求参数
        })
          .then((data) => {
            if (data.code == 200) {
              // 表格数据
              this.status = 'finish';
              /**
                * V2版本后把返回值 改为result.list
                * result: { list: Array, next: null || number, prev: null || number, total: number }
                * else 兼容旧版本
                */
              if (Array.isArray(data.result)) {
                this.tableData = data.result.map(item => {
                  return {
                    ...item,
                    children: item.childs || item.children,
                    expanded: true,
                    edit: false,
                  }
                }) || [];
                this.total = data.total;
              } else {
                this.tableData = data.result.list.map((item) => {
                  return {
                    ...item,
                    children: item.childs || item.children,
                    expanded: true,
                    edit: false,
                  }
                }) || [];
                this.total = data.result.total;
              }
            } else {
              //处理状态非200的情况
              this.tableText = data.message;
              this.status = 'error';
            }
          })
          .catch(() => {
            this.tableText = '加载失败';
            this.status = 'error';
          })
          .finally(() => {
            this.loading = false;
          });
      };

      // 延迟200毫秒加载
      setTimeout(getData, 100);
    },

    /**
     * 查询表格数据
     **/
    searchData() {
      this.params[this.pageOption.current] = 1;
      this.loadingData();
    },

    /**
     * 监听表格分页变化
     **/
    currentChange(current) {
      this.params[this.pageOption.current] = current;
      this.loadingData();
    },

    /**
     * 监听表格每页数据条数变化
     **/
    sizeChange(size) {
      this.params[this.pageOption.size] = size;
      this.loadingData();
    },

    /**
     * 监听表格复选框
     **/
    select(selection, row) {
      this.selectionRow = row;
      this.$emit('select', selection, row);
    },

    /**
     * 监听表格全选复选框
     **/
    selectAll(selection) {
      this.$emit('selectAll', selection);
    },

    /**
     * 监听表格复选框选项
     **/
    selectChange(selection) {
      this.selectionData = selection;
      this.$emit('selectChange', selection);
    },

    /**
     * 获取复选框id数组
     **/
    getSelectId() {
      let idArr = [...this.defaultSelectIds];
      this.selectionData.forEach((item) => {
        idArr.push(item[this.rowKey]);
      });
      let list = Array.from(new Set(idArr));
      return list;
    },

    /**
     * 清除复选框选中
     **/
    clearSelect() {
      this.tableRef.clearSelection();
    },

    /**
     * 清除选中且加载
     **/
    clearLoadData() {
      this.tableRef.clearSelection();
      this.loadingData();
    },

    /**
     * 设置选择行
     **/
    setSelectRow(row, selected) {
      this.tableRef.toggleRowSelection(row, selected);
    },

    /**
     * 查询渲染表格
     **/
    doLayout() {
      this.tableRef.doLayout();
    },
  },
};
</script>

<style scoped lang="scss">
/* 表格 */
.moe-table {
  background: #fff;
  // padding: 10px 5px 0;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  flex: 1;
  /* 表格工具栏 */
  &__tool {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    // border-bottom: 1px solid #f2f6fc;
  }

  /* 表格内容 */
  &__cont {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* 表格单元格 */
    ::v-deep th,
    ::v-deep td {
      text-align: center;
      background: transparent;
    }

    /* 表头 */
    ::v-deep tr.moe-table__head {
      color: #666;
      background-color: #f2f2f2;
    }

    /* 单元格 */
    // ::v-deep .moe-table_cell {}
  }

  /* 表格分页 */
  &__page {
    text-align: right;
    padding: 10px;
    background-color: #fff;
  }

  /* el-table列数据为空自动显示提示-- */
  ::v-deep .cell:empty::before {
    content: '-';
  }

  &__empty {
    line-height: 35px;
    padding-top: 20px;
    &-icon {
      font-size: 50px;
    }

    &-text {
      font-size: 14px;
    }
  }

  ::v-deep .el-tag {
    cursor: pointer;
  }

  ::v-deep .el-table__body-wrapper {
    overflow-y: auto;
  }
}
</style>

<style lang="scss">
.el-tooltip__popper {
  max-width: 30vw !important;
  line-height: 1.5em;
  letter-spacing: 1px;
}
.moe-table .el-icon-loading{
  font-size: 40px;
}
.el-table--fit{
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);
  border: 0;
  border-top: 1px solid #f2f6fc;
}
.el-table::before,
.el-table__fixed::before,
.el-table__fixed-right::before{
  height: 0;
}
.el-table__header,
.el-table__body{
  border-left: 1px solid #EBEEF5;
  border-right: 1px solid #EBEEF5;
}
.el-table {
  .disable-row {
    background-color: #fbfbfb;
  }
  //隐藏表格全选按钮
  thead {
    .el-table-column--selection {
      .cell {
        display: none;
      }
    }
    .multiple {
      .el-table-column--selection {
        .cell {
          display: block;
        }
      }
    }
  }
}
</style>
