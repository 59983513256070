/**
  -- Created by Lichuangwei on 2023/10/30
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 财务管理
  -- 1.开票申请
* */

import BlankView from '@/views/BlankView.vue';

const FinanceManage = [
  {
    path: 'financeManage',
    name: 'FinanceManage',
    meta: {
      title: '财务管理',
      path: '/financeManage/invoice/list',
    },
    component: BlankView,
    children: [
      {
        path: '/financeManage/invoice/list',
        name: 'FinanceManageInvoiceList',
        meta: {
          title: '开票申请',
          path: '/financeManage/invoice/list',
        },
        component: () => import('@/views/FinanceManage/Invoice/List.vue'),
      },
    ],
  },
];

export default FinanceManage;