/**
 * N元任选
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 创建N元任选 */
  addOptional(data) {
    return http2({
      method: 'post',
      url: '/shop/optional',
      data,
    });
  },
  /** 获取N元任选详情 */
  getOptionalDetail(params) {
    return http({
      method: 'get',
      url: '/shop/optional/detail',
      params,
    });
  },
  /** 修改N元任选 */
  updateOptional(data) {
    return http2({
      method: 'put',
      url: '/shop/optional',
      data,
    });
  },
  /** 暂停N元任选 */
  pauseOptional(data) {
    return http2({
      method: 'post',
      url: '/shop/optional/pause',
      data,
    });
  },
  /** 重启N元任选 */
  restartOptional(data) {
    return http2({
      method: 'post',
      url: '/shop/optional/restart',
      data,
    });
  },
  /** 关闭N元任选 */
  closeOptional(data) {
    return http2({
      method: 'post',
      url: '/shop/optional/close',
      data,
    });
  },
  /** 删除N元任选 */
  delOptional(params) {
    return http({
      method: 'delete',
      url: '/shop/optional',
      params,
    });
  },
};