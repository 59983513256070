<template>
  <div class="moe-tabs">
    <el-tabs
      v-model="activeName"
      :type="type"
      @tab-click="tabhandle">
        <el-tab-pane
          class="moe-tab-pane"
          v-for="(item, index) in tabsList"
          :key="index"
          :label="item.label"
          :disabled="item.disabled"
          :name="String(index)">
            <slot :name="String(index)"></slot>
        </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'moeTabs',
  model: {
    props: 'value',
    event: 'change',
  },
  props: {
    /** 选中数据 */
    value: {
      type: [String, Number, Array],
      default: '',
    },
    tabsList: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ''
    },
    tabHandle: {
      type: Function,
      default: () => {}
    }
  },
  watch: {
    value(val) {
      this.activeName = val
    }
  },
  data() {
    return {
      activeName: this.value
    }
  },
  methods: {
    tabhandle(val) {
      this.$emit('tabHandle', val)
      this.$emit('change', val)
    }
  },
}
</script>

<style lang="scss">
.moe-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .el-tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    .el-tabs__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
      .el-tab-pane {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        overflow-y: scroll;
      }
    }
  }
}
</style>