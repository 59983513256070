<template>
  <!-- 下拉框 -->
  <el-select class="moe-select"
    v-model="defaultValue"
    :placeholder="placeholder"
    clearable
    :multiple="isMultiple"
    :disabled="disabled"
    @change="onChange">
    <el-option
      :label="item.label"
      :value="item.value"
      :disabled="item.disabled"
      v-for="(item, index) of list"
      :key="index" />
  </el-select>
</template>

<script>
/**
 * 下拉框 - moe-select
 * @description 基于el-select组件的二次封装，用于一些静态数据的下拉框
 * @property {String} type 类型
 * @property {String} value 选中value
 * @property {String} placeholder 占位符
 * @event {Function} change 监听下拉框变化
 **/
export default {
  name: 'moe-select',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    // 下拉框类型
    type: {
      type: String,
      require: true,
    },

    // 选中数据
    value: {
      type: [String, Number, Array, Boolean],
      default: '',
    },

    // 占位文本
    placeholder: {
      type: String,
      default: '',
    },

    // 多选开关
    isMultiple: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false
    }

  },
  data() {
    // 获取下拉框列表
    const list = require('@/utils/data')[this.type]
    return {
      defaultValue: this.value,
      list,
    }
  },
  methods: {
    /**
     * 监听下拉框
     **/
    onChange(e) {
      this.$emit('change', e)
    },
  },
  watch: {
    value(newValue) {
      this.defaultValue = newValue
    },
  },
}
</script>

<style scoped lang="scss">

</style>
