/**
  -- Created by Lichuangwei on 2023/11/8
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 推送管理
***/

import BlankView from '@/views/BlankView.vue';

const SystemManage = [
  {
    path: 'pushManage',
    name: 'PushManage',
    meta: {
      title: '推送管理',
      path: '/pushManage/system/list',
    },
    component: BlankView,
    children: [
      /* -- 系统推送 -- */
      {
        path: '/pushManage/system/list',
        name: 'PushManageSystemList',
        meta: {
          title: '推送管理',
          path: '/pushManage/system/list',
          affix: true,
        },
        component: () => import('@/views/PushManage/System/List.vue'),
      },
      {
        path: '/pushManage/system/add',
        name: 'PushManageSystemAdd',
        meta: {
          title: '推送添加',
          path: '/pushManage/system/add',
          affix: true,
        },
        component: () => import('@/views/PushManage/System/Add.vue'),
      },
      {
        path: '/pushManage/system/detail',
        name: 'PushManageSystemDetail',
        meta: {
          title: '推送详情',
          path: '/pushManage/system/detail',
          affix: true,
        },
        component: () => import('@/views/PushManage/System/Detail.vue'),
      },
      /* -- 短信推送 -- */
      {
        path: '/pushManage/message/list',
        name: 'PushManageMessageList',
        meta: {
          title: '短信管理',
          path: '/pushManage/message/list',
          affix: true,
        },
        component: () => import('@/views/PushManage/Message/List.vue'),
      },
      {
        path: '/pushManage/message/add',
        name: 'PushManageMessageAdd',
        meta: {
          title: '短信添加',
          path: '/pushManage/message/add',
          affix: true,
        },
        component: () => import('@/views/PushManage/Message/Add.vue'),
      },
      {
        path: '/pushManage/message/detail',
        name: 'PushManageMessageDetail',
        meta: {
          title: '短信详情',
          path: '/pushManage/message/detail',
          affix: true,
        },
        component: () => import('@/views/PushManage/Message/Detail.vue'),
      },
    ],
  },
];

export default SystemManage;
