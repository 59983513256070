/**
  -- Created by Lichuangwei on 2024/1/13
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 抽奖管理
  -- 1.九宫格抽奖
* */

import BlankView from '@/views/BlankView.vue';

const LotteryManage = [
  {
    path: 'lotteryManage',
    name: 'LotteryManage',
    meta: {
      title: '抽奖管理',
      path: '/lotteryManage/luckyGrid/list',
    },
    component: BlankView,
    children: [
      /** 九宫格抽奖 */
      {
        path: '/lotteryManage/luckyGrid/list',
        name: 'LotteryManageLuckyGridList',
        meta: {
          title: '九宫格抽奖',
          path: '/lotteryManage/luckyGrid/list',
          affix: true,
        },
        component: () => import('@/views/LotteryManage/LuckyGrid/List.vue'),
      },
      {
        path: '/lotteryManage/luckyGrid/add',
        name: 'LotteryManageLuckyGridAdd',
        meta: {
          title: '九宫格抽奖',
          path: '/lotteryManage/luckyGrid/add',
          affix: true,
        },
        component: () => import('@/views/LotteryManage/LuckyGrid/Add.vue'),
      },
      {
        path: '/lotteryManage/luckyGrid/detail',
        name: 'LotteryManageLuckyGridDetail',
        meta: {
          title: '九宫格抽奖',
          path: '/lotteryManage/luckyGrid/detail',
          affix: true,
        },
        component: () => import('@/views/LotteryManage/LuckyGrid/Detail.vue'),
      },
      {
        path: '/lotteryManage/luckyGrid/record',
        name: 'LotteryManageLuckyGridRecord',
        meta: {
          title: '抽奖记录',
          path: '/lotteryManage/luckyGrid/record',
          affix: true,
        },
        component: () => import('@/views/LotteryManage/LuckyGrid/Record.vue'),
      },
    ],
  },
];

export default LotteryManage;