/**
 * 发票
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 分页查询发票列表 */
  getOrderFapiaoList(params) {
    return http({
      method: 'get',
      url: '/shop/orderFapiao/list',
      params,
    });
  },

  /** 查询发票详情 */
  getOrderFapiaoDetail(params) {
    return http({
      method: 'get',
      url: '/shop/orderFapiao/detail',
      params,
    });
  },

  /** 发票冲红 */
  redOrderFapiao(data) {
    return http({
      method: 'post',
      url: '/shop/orderFapiao/red',
      data: qs.stringify(data),
    });
  },

  /** 开票驳回 */
  rejectOrderFapiao(data) {
    return http({
      method: 'post',
      url: '/shop/orderFapiao/reject',
      data: qs.stringify(data),
    });
  },

  /** 上传发票 */
  uploadOrderFapiao(data) {
    return http({
      method: 'post',
      url: '/shop/orderFapiao/upload',
      data: qs.stringify(data),
    });
  },
};