/**
 * Created by zed on 2022/3/14
 * Copyright © 2017 云宠智能 All rights reserved.
 *
 * 【公共路由】无需权限可访问
 */
import Login from '@/views/Login/Login.vue'

/* 无需权限可访问路由 */
const publicRoute = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录'
    },
    component: Login
  },
  {
    path: '/not-found',
    name: 'NotFound',
    meta: {
      title: '404 - 找不到页面'
    },
    component: () => import('@/views/Admin/NotFound.vue')
  },
];
export default publicRoute
