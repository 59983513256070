/**
  * 预售活动
  * 2023-06-28
  **/

import http from '@/utils/http';
import http2 from '@/utils/http2';

export default {
  /** 创建预售活动 */
  addPresale(data) {
    return http2({
      method: 'post',
      url: '/shop/presale',
      data,
    });
  },
  /** 查询预售活动详情 */
  getPresaleDetail(params) {
    return http({
      method: 'get',
      url: '/shop/presale/detail',
      params,
    });
  },
  /** 更新预售活动信息 */
  updatePresale(data) {
    return http2({
      method: 'put',
      url: '/shop/presale',
      data,
    });
  },
  /** 更新预售活动商品配置信息 */
  updatePresaleConfigList(data) {
    return http2({
      method: 'put',
      url: '/shop/presale/items',
      data,
    });
  },
  /** 更新预售活动商品配置信息 */
  delPresaleActivity(params) {
    return http2({
      method: 'delete',
      url: '/shop/presale',
      params,
    });
  },
};