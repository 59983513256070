/**
 * Created by lichuangwei on 2022/11/14
 * @Module $moe_time
 * @Describe 时间处理器
 * 依赖dayjs
 **/

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/zh-cn';

dayjs.extend(duration);
dayjs.locale("zh-cn");

export default {
  /**
   * 时间戳转时间格式
   * timestamp 时间戳
   * dateType 时间格式
   */
  getTimeStamp(timestamp, dateType) {
    if (!timestamp) return '-';
    return dayjs(timestamp).format(dateType || 'YYYY-MM-DD HH:mm:ss');
  },

  timestamp(date, dateType){
    const formatTime = dayjs(date, dateType || 'YYYY-MM-DD HH:mm:ss').format()
    return dayjs(formatTime).valueOf()
  },

  /**
    * 时间倒计时插件
    * endTime 结束时间 时间戳
    * startTime 开始时间 时间戳
    * dateType 时间格式 enum { minutes, seconds }
    */
  countDown(endTime, startTime, dateType) {
    const diff = dayjs(endTime).diff(startTime ? dayjs(startTime) : dayjs());
    const diffTime = dayjs.duration(diff);

    // const year = diffTime.years();//年
    // const day = diffTime.days(); //天
    // const hours = diffTime.hours(); //小时
    // const minutes = diffTime.minutes(); //分钟
    const AllSeconds = diffTime.asSeconds(); //秒

    var day = Math.floor(AllSeconds / (3600 * 24));
    var hours = Math.floor((AllSeconds % (3600 * 24)) / 3600);
    var minutes = Math.floor((AllSeconds % 3600) / 60);
    var seconds = AllSeconds % 60;


    if (dateType === 'minutes') {
      return `${day}天${hours}小时${minutes}分`;
    }

    return `${day}天${hours}小时${minutes}分${seconds}秒`;
  },

  /**
   * 返回指定单位下两个日期时间之间的差异。
   * 小时
   */
  dateDiff({ endDate, startDate }) {
    return (endDate ? dayjs(endDate, 'YYYY-MM-DD HH:mm:ss') : dayjs()).diff(
      startDate ? dayjs(startDate) : dayjs(),
      'day'
    );
  },

  /** 这表示 Day.js 对象是否在另一个提供的日期时间之前。 */
  dateBefore({ startDate, endDate }) {
    return dayjs(startDate ? dayjs(startDate) : dayjs()).isBefore(
      dayjs(endDate)
    );
  },

  /** 这表示 Day.js 对象是否和另一个提供的日期时间相同。 */
  dateSame({ startDate, endDate }) {
    return dayjs(startDate ? dayjs(startDate) : dayjs()).isSame(dayjs(endDate));
  },
};
