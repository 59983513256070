/**
 * 硬件产品测试
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 新增厂测白名单 */
  addFactoryTest(data) {
    return http2({
      method: 'post',
      url: '/factoryTest/user',
      data,
    });
  },

  /** 删除厂测白名单 */
  delFactoryTest(params) {
    return http({
      method: 'delete',
      url: '/factoryTest/user',
      params
    });
  },
};