/**
 * @Name: ui
 * @Describe: ui组件配置
 **/

import Vue from 'vue'

// import { MoeTabs } from 'moe-ui'

import moeSpecs from '@/components/moe-ui/moe-specs/moe-specs.vue';
import moeSteps from '@/components/moe-ui/moe-steps/moe-steps.vue';
import moeTabs from '@/components/moe-ui/moe-tabs/moe-tabs.vue';
import moeCheckbox from '@/components/moe-ui/moe-checkbox/moe-checkbox.vue';
import moeCheckboxGroup from '@/components/moe-ui/moe-checkbox-group/moe-checkbox-group.vue';
import moeSelect from '@/components/moe-ui/moe-select/moe-select.vue';
import moeOption from '@/components/moe-ui/moe-select/moe-option.vue';
import moeDialog from '@/components/moe-ui/moe-dialog/moe-dialog.vue';
import moeTinymce from '@/components/moe-ui/moe-tinymce/moe-tinymce.vue';
import moeIcon from '@/components/moe-ui/moe-icon/moe-icon.vue';
import moeImage from '@/components/moe-ui/moe-image/moe-image.vue';
import moeBreadcrumb from '@/components/moe-ui/moe-breadcrumb/moe-breadcrumb.vue';
import moeInquire from '@/components/moe-ui/moe-inquire/moe-inquire.vue';
import moePage from '@/components/moe-ui/moe-page/moe-page.vue';
import moeDescribeList from '@/components/moe-ui/moe-describe/moe-describe-list.vue';
import moeDescribeItem from '@/components/moe-ui/moe-describe/moe-describe-item.vue';
import moeForm from '@/components/moe-ui/moe-form/moe-form.vue';
import moeTable from '@/components/moe-ui/moe-table/moe-table.vue';
import moeUpload from '@/components/moe-ui/moe-upload/moe-upload.vue';
import moeUploadFile from '@/components/moe-ui/moe-upload-file/moe-upload-file.vue';
import moeCard from '@/components/moe-ui/moe-card/moe-card.vue';
import moeRadioGroup from '@/components/moe-ui/moe-radio-group/moe-radio-group.vue';
import moeCascader from '@/components/moe-ui/moe-cascader/moe-cascader.vue';
import moeTag from '@/components/moe-ui/moe-tag/moe-tag.vue';
import moeTooltip from '@/components/moe-ui/moe-tooltip/moe-tooltip.vue';
import moePopup from '@/components/moe-ui/moe-popup/moe-popup.vue';
import moeSwitch from '@/components/moe-ui/moe-switch/moe-switch.vue';
import moeTextarea from '@/components/moe-ui/moe-textarea/moe-textarea.vue';
import moeAddress from '@/components/moe-ui/moe-address/moe-address.vue';
import moeGrid from '@/components/moe-ui/moe-grid/moe-grid.vue';
import moeInputNumberRange from '@/components/moe-ui/moe-input-number-range/moe-input-number-range.vue';
import moeRadio from '@/components/moe-ui/moe-radio/moe-radio.vue';

Vue.component(moeSpecs.name, moeSpecs);
Vue.component(moeSteps.name, moeSteps);
Vue.component(moeTabs.name, moeTabs);
Vue.component(moeCheckbox.name, moeCheckbox);
Vue.component(moeCheckboxGroup.name, moeCheckboxGroup);
Vue.component(moeSelect.name, moeSelect);
Vue.component(moeOption.name, moeOption);
Vue.component(moeDialog.name, moeDialog);
Vue.component(moeTinymce.name, moeTinymce);
Vue.component(moeIcon.name, moeIcon);
Vue.component(moeImage.name, moeImage);
Vue.component(moeBreadcrumb.name, moeBreadcrumb);
Vue.component(moeInquire.name, moeInquire);
Vue.component(moePage.name, moePage);
Vue.component(moeDescribeList.name, moeDescribeList);
Vue.component(moeDescribeItem.name, moeDescribeItem);
Vue.component(moeForm.name, moeForm);
Vue.component(moeTable.name, moeTable);
Vue.component(moeUpload.name, moeUpload);
Vue.component(moeUploadFile.name, moeUploadFile);
Vue.component(moeCard.name, moeCard);
Vue.component(moeRadioGroup.name, moeRadioGroup);
Vue.component(moeCascader.name, moeCascader);
Vue.component(moeTag.name, moeTag);
Vue.component(moeTooltip.name, moeTooltip);
Vue.component(moePopup.name, moePopup);
Vue.component(moeSwitch.name, moeSwitch);
Vue.component(moeTextarea.name, moeTextarea);
Vue.component(moeAddress.name, moeAddress);
Vue.component(moeGrid.name, moeGrid);
Vue.component(moeInputNumberRange.name, moeInputNumberRange);
Vue.component(moeRadio.name, moeRadio);