/**
 * 系统设置状态模块
 **/

// 获取本地存储信息
const session = sessionStorage;

export default {
	namespaced: true,
	state: {
		//左侧菜单列表
		menuList: [],

		//tab列表
		tabList: session.tabList ? JSON.parse(session.tabList) : [],

		//修改密码弹框
		pwdShow: false,

		//页面加载状态
		pageLoad: true,

		//子页面加载状态
		childPageLoad: false,

		//左侧菜单收缩
		collapse: false,

		//修改密码弹框显示
		passwordShow: false,

		//子页面刷新
		childPageRefresh: false,
	},
	getters: {},
	mutations: {
		/**
		 * 设置页面加载状态
		 **/
		setPageLoad(state, data) {
			state.pageLoad = data;
		},

		/**
		 * 设置子页面加载状态
		 **/
		setChildPageLoad(state, data) {
			state.childPageLoad = data;
		},

		/**
		 * 设置左侧菜单列表
		 **/
		setMenuList(state, data) {
			state.menuList = data;
		},

		/**
		 * 设置修改密码弹框显示
		 **/
		setPwdShow(state, data) {
			state.pwdShow = data;
		},

		/**
		 * 设置顶部tab列表
		 **/
		setTabList(state, data) {
			state.tabList = data;
			sessionStorage.setItem('tabList', JSON.stringify(data));
		},

		/**
		 * 新增tab列表
		 **/
		addTabList(state, data) {
			const isExist = state.tabList.some((k) => k.path === data.path);
			if (!isExist) {
				state.tabList.push(data);
				sessionStorage.setItem('tabList', JSON.stringify(state.tabList));
			}
		},

		/**
		 * 设置左侧菜单收缩
		 **/
		setCollapse(state, data) {
			state.collapse = data;
		},
	},
	actions: {},
	modules: {}
}
