/**
  -- Created by Lichuangwei on 2023/08/18
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 内容管理
***/

import BlankView from '@/views/BlankView.vue';

const ContentManage = [
  {
    path: 'contentManage',
    name: 'ContentManage',
    meta: {
      title: '内容管理',
      path: '/contentManage/couponManage/list',
    },
    component: BlankView,
    children: [
      {
        path: '/contentManage/couponManage/list',
        name: 'ContentManageCouponManageList',
        meta: {
          title: '优惠券入口管理',
          path: '/contentManage/couponManage/list',
          affix: true,
        },
        component: () => import('@/views/ContentManage/couponManage/list.vue'),
      },
      {
        path: '/contentManage/couponManage/add',
        name: 'ContentManageCouponManageAdd',
        meta: {
          title: '新增优惠券',
          path: '/contentManage/couponManage/Add',
          affix: true,
        },
        component: () => import('@/views/ContentManage/couponManage/add.vue'),
      },
      {
        path: '/contentManage/frontCategory/list',
        name: 'ContentManageFrontCategoryList',
        meta: {
          title: '前台分类',
          path: '/contentManage/frontCategory/list',
          affix: true,
        },
        component: () => import('@/views/ContentManage/frontCategory/list.vue'),
      },
      {
        path: '/contentManage/frontCategory/add',
        name: 'ContentManageFrontCategoryAdd',
        meta: {
          title: '新增前台分类',
          path: '/contentManage/frontCategory/add',
          affix: true,
        },
        component: () => import('@/views/ContentManage/frontCategory/add.vue'),
      },
      {
        path: '/contentManage/brandManage/list',
        name: 'ContentManageBrandManageList',
        meta: {
          title: '品牌管理列表',
          path: '/contentManage/brandManage/list',
          affix: true,
        },
        component: () => import('@/views/BrandManage/list.vue'),
      },
      {
        path: '/contentManage/brandManage/add',
        name: 'ContentManageBrandManageAdd',
        meta: {
          title: '新增品牌',
          path: '/contentManage/brandManage/add',
          affix: true,
        },
        component: () => import('@/views/BrandManage/add.vue'),
      },
    ],
  },
];

export default ContentManage;