<template>
  <div class="moe-input-number-range">
    <el-input-number
      class="w-70"
      v-model="num"
      :disabled="disabled"
      :min="min"
      :max="(max - 1)"
      :controls="false"
      :step-strictly="stepStrictly"
      @change="changeNum"
      @blur="blurNum" />
    <div class="ml-10 mr-10">-</div>

    <el-input-number
      class="w-70"
      v-model="num2"
      :disabled="disabled"
      :min="min"
      :max="max"
      :controls="false"
      :step-strictly="stepStrictly"
      @change="changeNum2"
      @blur="blurNum2" />
    <div class="ml-10">
      <slot name="append"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'moe-input-number-range',
  props: {
    //是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    //设置计数器允许的最小值
    min: {
      type: Number,
      default: 1
    },
    //设置计数器允许的最大值
    max: {
      type: Number,
      default: 10000
    },
    //严格遵循 输入框2 > 输入框1
    checkStrictly: {
      type: Boolean,
      default: true,
    },
    //是否只能输入 step 的倍数
    stepStrictly: {
      type: Boolean,
      default: true
    },
    value: {
      type: [Number, String],
      default: 1,
    },
    value2: {
      type: [Number, String],
      default: 2,
    }
  },
  watch: {
    value(newValue) {
      this.num = newValue;
    },
    value2(newValue) {
      this.num2 = newValue;
    }
  },
  data() {
    return {
      num: this.value,
      num2: this.value2,
      validatorList: ['', null, NaN, undefined],
      timer: null
    }
  },
  methods: {
    changeNum(currentValue, oldValue) {
      this.$emit('update:value', currentValue);
    },
    changeNum2(currentValue, oldValue) {
      this.$emit('update:value2', currentValue);
    },
    blurNum() {
      if (this.validatorList.includes(this.num)) {
        this.num = this.min;
        this.$emit('update:value', this.num);
      } else {
        this.$emit('update:value', this.num);
        if (this.checkStrictly) {
          if (this.$moe_math.mathLessThanOrEqualTo(this.num2, this.num)) {
            this.$emit('update:value2', this.num + 1);
          }
        } else {
          if (this.$moe_math.mathLessThan(this.num2, this.num)) {
            this.$emit('update:value2', this.num);
          }
        }
      }
    },
    blurNum2() {
      if (this.validatorList.includes(this.num2)) {
        this.num2 = this.num + 1;
        this.$emit('update:value2', this.num2);
      } else {
        this.$emit('update:value2', this.num2);
        if (this.checkStrictly) {
          if (this.$moe_math.mathLessThanOrEqualTo(this.num2, this.num)) {
            this.$emit('update:value2', this.num + 1);
          }
        } else {
          if (this.$moe_math.mathLessThan(this.num2, this.num)) {
            this.$emit('update:value2', this.num);
          }
        }
      }
    }
  },
}
</script>

<style lang="scss">
.moe-input-number-range {
  display: flex;
  align-items: center;
}
</style>