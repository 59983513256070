/**
 * 问卷调查
 **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 创建问卷 */
  addSurvey(data) {
    return http2({
      method: 'post',
      url: '/shop/survey',
      data,
    });
  },
  /** 修改问卷 */
  updateSurvey(data) {
    return http2({
      method: 'put',
      url: '/shop/survey',
      data,
    });
  },
  /** 查询问卷详情 */
  getSurveyDetail(params) {
    return http({
      method: 'get',
      url: '/shop/survey/detail',
      params,
    });
  },
  /** 关闭问卷 */
  closeSurvey(data) {
    return http2({
      method: 'post',
      url: '/shop/survey/close',
      data,
    });
  },
  /** 问卷结果报告 */
  getSurveyResultReport(params) {
    return http({
      method: 'get',
      url: '/shop/survey/resultReport',
      params,
    });
  },
  /** 问卷结果详情 */
  getSurveyResultDetail(params) {
    return http({
      method: 'get',
      url: '/shop/survey/resultDetail',
      params,
    });
  },
};