/**
 * 设备API
 **/

import http from '@/utils/http'
import http2 from '@/utils/http2'
import qs from 'qs'

/* export API */
export default {
    /** 可用参数 */
    getDeviceModelParams() {
        return http({
            method: 'get',
            url: '/deviceModel/params',
        })
    },

    /** 设备型号列表 */
    smartEquipmentList(params) {
        return http({
            method: 'get',
            url: '/deviceType/pageList',
            params
        })
    },

    /** 设备型号详情 */
    smartEquipmentDetail(params) {
        return http({
            method: 'get',
            url: `/deviceModel/${params.id}`,
            params
        })
    },

    /** 设备型号添加 */
    smartEquipmentAdd(data) {
        return http2({
            method: 'post',
            url: '/deviceModel',
            data
        })
    },

    /** 设备型号修改 */
    smartEquipmentAmend(data) {
        return http2({
            method: 'put',
            url: '/deviceModel',
            data
        })
    },

    /** 设备型号固件上传 */
    sfirmwareUpload(data) {
        return http({
            method: 'post',
            url: '/deviceModel/firmware',
            data: qs.stringify(data)
        })
    },

    /** 用户设备列表 */
    userEquipmentList(params) {
        return http({
            method: 'get',
            url: '/device/pageList',
            params
        })
    },

    /** 用户设备详情 */
    userEquipmentDetail(params) {
        return http({
            method: 'get',
            url: '/device/getInfo',
            params
        })
    },

    /**
      * 可用参数规范
      * type 设备类型,可用值:0:FEEDER,1:BUCKET,4:WALTER_BOWL,5:TOY_CAT,6:CALLER,7:TOILET
      * wireless 设备无线类型,可用值:1:BLE,2:WIFI,3:VIDEO
      */
    paramsSpec(params) {
        return http({
            method: 'get',
            url: '/deviceModel/paramsSpec',
            params
        })
    },

    /** 查看所有设备型号 */
    readAll() {
        return http({
            method: 'get',
            url: '/deviceModel/readAll',
        })
    },

    /** 关联设备型号 */
    relationDeviceModel(data) {
        return http2({
            method: 'post',
            url: '/shop/goods/relationDeviceModel',
            data
        })
    },

    /** 获取关联设备型号详情 */
    relationDeviceModelDetail(params) {
        return http({
            method: 'get',
            url: '/shop/goods/relationDeviceModelDetail',
            params
        })
    }
}
