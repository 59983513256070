/**
  * 拼团活动
  **/

import http from '@/utils/http';
import http2 from '@/utils/http2';
import qs from 'qs';

export default {
  /** 创建拼团活动 */
  addGroupActivity(data) {
    return http2({
      method: 'post',
      url: '/shop/group',
      data,
    });
  },

  /** 修改拼团活动 */
  updateGroupActivity(data) {
    return http2({
      method: 'put',
      url: '/shop/group',
      data,
    });
  },

  /** 删除拼团活动 */
  delGroupActivity(params) {
    return http({
      method: 'delete',
      url: '/shop/group',
      params,
    });
  },

  /** 获取拼团活动详情 */
  getGroupActivityDetail(params) {
    return http({
      method: 'get',
      url: '/shop/group/detail',
      params,
    });
  },

  /** 更新拼团活动状态 */
  updateGroupActivityState(data) {
    return http2({
      method: 'post',
      url: '/shop/group/updateState',
      data,
    });
  },

  /** 团单子订单列表 */
  getGroupOrderItemList(params) {
    return http({
      method: 'get',
      url: '/shop/group/order/itemList',
      params,
    });
  },

  /** 确认返现 */
  groupOrderCashback(data) {
    return http2({
      method: 'post',
      url: '/shop/group/order/cashback',
      data,
    });
  },
};