<template>
  <!-- 首页侧边栏 -->
  <el-aside class="moe-aside" :width="collapse ? '64px' : '250px'">
    <!-- 系统title -->
    <div :class="['title']">
      <router-link to="/welcome">
        <img src="@/assets/image/logo.png" alt="logo" title="logo" />
        <h1>云宠智能后台管理系统</h1>
      </router-link>
    </div>

    <!-- 菜单栏 -->
    <el-menu class="moe-aside__menu" mode="vertical" :collapse="collapse" :collapse-transition="false" :default-active="$route.path" unique-opened background-color="#363638" text-color="#eee" router>
      <el-submenu :index="index + ''" v-for="(item, index) in menuList" :key="index">
        <template slot="title">
          <moe-icon :name="item.icon" size="20px"/>
          <span class="moe-aside__menu-text">{{ item.title }}</span>
        </template>
        <el-menu-item :index="items.path" v-for="(items, indexs) of item.childs" :key="indexs">
          <moe-icon :name="items.icon" size="18px" />
          <span class="moe-aside__menu-text">{{ items.title }}</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </el-aside>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'moe-aside',

  /**
   * 获取系统数据
   * menuList: 左侧菜单栏列表
   * collapse: 左侧菜单栏是否收缩
   */
  computed: mapState('setting', ['menuList', 'collapse']),
};
</script>

<style lang="scss" scoped>
@import 'index';
.moe-aside__menu-text{
  font-size: 14px;
}
</style>