<template>
  <div class="moe-grid">
    <div
      class="moe-grid-container"
      :style="{
        'gridTemplateColumns': `repeat(${column}, 1fr)`,
        'gridTemplateRows': 'repeat(auto-fit, 1fr)',
        'rowGap': `${itemSpace}`,
        'columnGap': `${lineSpace}`,
      }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'moe-grid',
  props: {
    column: {
      typeof: Number,
      default: 1,
    },
    itemSpace: {
      typeof: String,
      default: '10px',
    },
    lineSpace: {
      typeof: '10px',
      default: 1,
    }
  }
}
</script>

<style lang="scss">
.moe-grid-container {
  display: grid;
  width: 100%;
}
</style>