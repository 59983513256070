<template>
  <el-steps
    :class="['moe-steps', className]"
    :active="active"
    align-center
    finish-status="success"
    :direction="direction">
    <el-step
      v-for="(item, index) in stepsList"
      :key="index"
      :title="item.title"
      :icon="item.icon"
      :status="isStatus ? item.status : ''">
      <template v-slot:description>
        <div v-if="Array.isArray(item.description)">
          <div class="df" v-for="(desItem, desIndex) in item.description" :key="desIndex">
            <div class="wsn" v-if="desItem.label">{{ `${desItem.label}：` }}</div>
            <div v-if="desItem.value">
              <div class="df aic" v-if="Array.isArray(desItem.value)">
                <moe-image class="mr-5" :src="imageItem" v-for="(imageItem, imageIndex) in desItem.value" :key="imageIndex" width="50px" height="50px" />
              </div>
              <div v-else>{{ desItem.value }}</div>
            </div>
          </div>
        </div>
        <div v-else>{{ item.description }}</div>
      </template>
    </el-step>
  </el-steps>
</template>

<script>
export default {
  name: 'moeSteps',
  props: {
    className: {
      type: String,
      default: '',
    },
    /**
      * 设置结束步骤的状态
      * wait / process / finish / error / success
      */
    finishStatus: {
      type: String,
      default: 'success'
    },
    /**
      * Array<{ title: '', description: '' }>
      */
    stepsList: {
      type: Array,
      default: () => []
    },
    /**
      * 显示方向
      * vertical/horizontal
      */
    direction: {
      type: String,
      default: 'horizontal'
    },
    /**
      * 设置当前激活步骤
      */
    active: {
      type: Number,
      default: 0
    },

    /**
      * 是否开启自定义步骤状态颜色
      */
    isStatus: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.moe-steps {
  margin-bottom: 20px;
  &.logisticsBox {
    .el-step__title.is-process {
      color: $--color-info;
      font-size: 14px;
    }
    .el-step__description {
      color: $--color-info;
      font-size: 14px;
      padding-left: 10px;
      padding-bottom: 20px;
    }
    .el-step__title.is-success {
      color: $--color-gray1;
      font-size: 14px;
      font-weight: bold;
    }
    .el-step__description.is-success {
      color: $--color-gray1;
    }
    .is-success .el-step__icon.is-icon{
      border: 2px solid $--color-gray1;
      border-radius: 50%;
    }
    .el-step__head.is-process{
      color: $--color-info;
    }
    .el-step__icon-inner.is-status{
      color: $--color-gray1;
    }
  }
}
</style>