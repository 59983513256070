<template>
  <div class="moe-specs">
    <moe-form ref="specsForm" :model="form" :showBack="false" :showTool="false" :defaultVerify="false">
      <div v-for="(item, index) in form.goodsSpec" :key="index">
        <el-form-item :prop="`goodsSpec.${index}.name`" :rules="rules.name">
          <div class="df dac fww">
            <div class="df1 mr-10">
              <el-select v-if="item.isEdit" v-model="item.name" filterable placeholder="请选择规格" :disabled="disabled">
                <el-option
                  v-for="(specsItem, specsIndex) in form.goodsSpec[index].optionList"
                  :key="specsIndex"
                  :label="specsItem.label"
                  :value="specsItem.value">
                </el-option>
              </el-select>

              <el-select v-else v-model="item.id" filterable placeholder="请选择规格" @change="(val) => handleChange(val, item, index)" :disabled="disabled">
                <el-option
                  v-for="(specsItem, specsIndex) in specsList"
                  :key="specsIndex"
                  :label="specsItem.label"
                  :value="specsItem.value">
                </el-option>
              </el-select>
            </div>
            <el-button :disabled="disabled" type="warning" size="small" icon="el-icon-edit" @click="specsValueDetail(item.id, item, index)">修改规格值</el-button>
            <el-button v-if="!item.isEdit" type="danger" size="small" icon="el-icon-delete" @click="handleDelete(item, index)">删除</el-button>
          </div>
        </el-form-item>

        <el-form-item :prop="`goodsSpec.${index}.specValue`" :rules="rules.specValue" v-if="item.name">
          <div class="df aic fww">
            <div class="specs-item" v-for="(specValueItem, specValueIndex) in item.specValue" :key="specValueIndex">{{ specValueItem.value }}</div>
          </div>
        </el-form-item>
      </div>
      <!-- <el-form-item prop="specValueList" :rules="rules.specValueList" v-if="!isEdit">
        <el-button :disabled="disabledComputed" class="moe-specs-add" size="medium" icon="el-icon-plus" @click="insert()">新增规格</el-button>
      </el-form-item> -->
    </moe-form>

    <moe-dialog :show="dialogModel.show" title="选择规格值" width="50%" @close="dialogClose()">
      <div class="dialog_content">
        <moe-form class="default-form" :showTool="false" :showBack="false" style="padding: 0;" v-if="form.goodsSpec.length && form.goodsSpec[goodsSpecIndex] && form.goodsSpec[goodsSpecIndex].checkList.length">
          <el-form-item>
            <el-checkbox :indeterminate="dialogModel.isIndeterminate" v-model="dialogModel.checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          </el-form-item>
        </moe-form>

        <div class="dialog_content_checkbox" v-if="form.goodsSpec.length && form.goodsSpec[goodsSpecIndex]">
          <moe-checkbox-group
            @change="handleCheckboxChange"
            v-model="form.goodsSpec[goodsSpecIndex].checkSelectList"
            :checkboxList="form.goodsSpec[goodsSpecIndex].checkList"
            :border="true">
          </moe-checkbox-group>

          <moe-form class="default-form" :model="dialogModel" :showTool="false" :showBack="false" :inline="true" style="padding: 0;">
            <el-form-item prop="addCheckValue" :rules="rules.addCheckValue">
              <el-input style="width: 200px;" clearable v-model="dialogModel.addCheckValue" placeholder="输入规格值" @change="(value) => handleInputChange(value)"></el-input>
            </el-form-item>
          </moe-form>
        </div>
      </div>
      <template slot="footer">
        <el-button @click="dialogClose()">取 消</el-button>
        <el-button type="primary" @click="insertSpecValue()">确 定</el-button>
      </template>
    </moe-dialog>

    <moe-form
      ref="tableForm"
      class="default-form"
      :showBack="false"
      :showTool="false"
      :model="form"
      :defaultVerify="false">
      <div style="height: 500px;min-height: 500px;" class="df fdc">
        <moe-table :numberShow="false" ref="specsTable" :data="form.shopGoodsItem" :mode="false" :params="{ pageNum: 1 }" emptyText="请先完善规格属性">
          <!-- 操作按钮 -->
          <template slot="tool" v-if="form.shopGoodsItem.length">
            <div class="df aic jcfe">
              <el-input :disabled="disabled" class="mr-10 w-150" clearable v-model="form.identifier" placeholder="仓库编码" maxlength="30"></el-input>
              <el-input :disabled="disabled" class="mr-10 w-150" clearable :value="form.stock" placeholder="库存" @input="(value) => form.stock = $moe_formatter.formatterStock(value)" maxlength="10"></el-input>
              <el-input :disabled="disabled" class="mr-10 w-150" clearable :value="form.quota" placeholder="限购" @input="(value) => form.quota = $moe_formatter.formatterInteger(value)" maxlength="10"></el-input>
              <el-input :disabled="disabled" class="mr-10 w-150" clearable :value="form.costPrice" placeholder="成本价(元)" @input="(value) => form.costPrice = $moe_formatter.formatterMoney(value)" maxlength="10"></el-input>
              <el-input :disabled="disabled" class="mr-10 w-150" clearable :value="form.salePrice" placeholder="销售价(元)" @input="(value) => form.salePrice = $moe_formatter.formatterMoney(value)" maxlength="10"></el-input>
              <el-input :disabled="disabled" class="mr-10 w-150" clearable v-model="form.barCode" placeholder="条码" maxlength="10"></el-input>
              <el-input :disabled="disabled" class="mr-10 w-150" clearable v-model="form.volume" placeholder="体积" maxlength="10"></el-input>
              <el-input :disabled="disabled" class="mr-10 w-150" clearable :value="form.weight" placeholder="重量" @input="(value) => form.weight = $moe_formatter.formatterWeight(value)" maxlength="10"></el-input>
              <el-button :disabled="disabledComputed" type="primary" @click="handleBatchFill()">批量填充</el-button>
            </div>
          </template>

          <el-table-column :show-overflow-tooltip="true" v-for="(item, index) in tableHeaderList" :key="index" :label="tableHeaderList[index].attr" :prop="tableHeaderList[index].attr" />

          <el-table-column label="仓库编码">
            <template slot-scope="{ row, $index }">
              <el-form-item :prop="`shopGoodsItem.${$index}.identifier`" :rules="specsRules.identifier">
                <el-input :disabled="disabled" v-model.trim="row.identifier" placeholder="请输入仓库编码" maxlength="30" clearable />
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="库存">
            <template slot-scope="{ row, $index }">
              <el-form-item :prop="`shopGoodsItem.${$index}.stock`" :rules="specsRules.stock">
                <el-input :disabled="disabled" :value="row.stock" @input="(value) => row.stock = $moe_formatter.formatterStock(value)" placeholder="请输入库存" maxlength="10" clearable />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="限购">
            <template slot-scope="{ row, $index }">
              <el-form-item :prop="`shopGoodsItem.${$index}.quota`" :rules="specsRules.quota">
                <el-input :disabled="disabled" :value="row.quota" @input="(value) => row.quota = $moe_formatter.formatterInteger(value)" placeholder="请输入限购" maxlength="10" clearable />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="成本价(元)" min-width="100">
            <template slot-scope="{ row, $index }">
              <el-form-item :prop="`shopGoodsItem.${$index}.costPrice`" :rules="specsRules.costPrice">
                <el-input :disabled="disabled" :value="row.costPrice" @input="(value) => row.costPrice = $moe_formatter.formatterMoney(value)" placeholder="请输入成本价" maxlength="10" clearable />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="销售价(元)" min-width="100">
            <template slot-scope="{ row, $index }">
              <el-form-item :prop="`shopGoodsItem.${$index}.salePrice`" :rules="specsRules.salePrice">
                <el-input :disabled="disabled" :value="row.salePrice" @input="(value) => row.salePrice = $moe_formatter.formatterMoney(value)" placeholder="请输入销售价" maxlength="10" clearable />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="条码">
            <template slot-scope="{ row, $index }">
              <el-form-item :prop="`shopGoodsItem.${$index}.barCode`" :rules="specsRules.barCode">
                <el-input :disabled="disabled" v-model.trim="row.barCode" placeholder="请输入条码" maxlength="10" clearable />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="体积">
            <template slot-scope="{ row, $index }">
              <el-form-item :prop="`shopGoodsItem.${$index}.volume`" :rules="specsRules.volume">
                <el-input :disabled="disabled" v-model.trim="row.volume" placeholder="请输入体积" maxlength="10" clearable />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="重量（KG）" min-width="100">
            <template slot-scope="{ row, $index }">
              <el-form-item :prop="`shopGoodsItem.${$index}.weight`" :rules="specsRules.weight">
                <el-input :disabled="disabled" :value="row.weight" @input="(value) => row.weight = $moe_formatter.formatterWeight(value)" placeholder="请输入重量" maxlength="10" clearable />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="图片" width="250">
            <template slot-scope="{ row, $index }">
              <el-form-item :prop="`shopGoodsItem.${$index}.coverUrl`" :rules="specsRules.coverUrl">
                <moe-upload-file
                  :readonly="disabled"
                  v-model="row.coverUrl"
                  ossKey="SHOP_MEDIA"
                  upload-text="上传规格图"
                  :default-file-list="row.imgsUrlList"
                  :showTip="false"
                  @change="() => $refs.tableForm.validateField(`shopGoodsItem.${$index}.coverUrl`)" />
              </el-form-item>
            </template>
          </el-table-column>
        </moe-table>
      </div>
    </moe-form>
  </div>
</template>

<script>
import { comparisonData } from '@/utils/lodash-es.js'
export default {
  name: 'moeSpecs',
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    const verify = this.$moe_verify.verifyForm
    const checkValueList = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        if (!this.form.goodsSpec.length) {
          return callback();
        }
        if (this.form.goodsSpec[this.goodsSpecIndex].checkList.filter((item) => item.value === value).length >= 1) {
          return callback(new Error(`规格值${value}重复`));
        }
        callback();
      }
    }
    const checkSpecValueList = (rule, value, callback) => {
      if (!this.form.goodsSpec.length) {
        return callback(new Error(`请添加规格值`));
      }
      callback();
    }
    return {
      specsList: [],
      shopGoodsItem: [],
      form: {
        goodsSpec: [],
        shopGoodsItem: [],
        identifier: '',      //仓库编码
        stock: '',          //库存
        quota: '',          //限购
        costPrice: '',      //成本价(元)
        salePrice: '',      //销售价(元)
        barCode: '',        //条码
        volume: '',         //体积
        weight: '',         //重量
      },
      goodsSpecIndex: 0,
      dialogModel: {
        show: false,
        checkAll: false,
        addCheckValue: '',
        isIndeterminate: '',
      },
      rulesDefault: {
        specsList: verify.isEmpty('请新增规格'),
      },
      rules: {
        value: verify.isEmpty('请输入规格名称'),
        name: verify.isEmpty('请输入规格名称'),
        //请添加规格值
        specValue: [{ required: true, message: '请添加规格值', trigger: ['blur', 'change'] }],
        checkList: [{ required: true, message: '请选择规格值', trigger: ['blur', 'change'] }],
        addCheckValue: [{ required: true, validator: checkValueList, trigger: ['blur', 'change'] }],
        specValueList: [{ required: true, validator: checkSpecValueList, trigger: ['blur', 'change'] }],
      },
      specsRules: {
        identifier: verify.isEmpty('请输入仓库编码'),
        stock: verify.isEmpty('请输入库存'),
        quota: verify.isEmpty('请输入限购'),
        costPrice: verify.isEmpty('请输入成本价'),
        salePrice: verify.isEmpty('请输入销售价'),
        barCode: verify.isEmpty('请输入条码'),
        volume: verify.isEmpty('请输入体积'),
        weight: verify.isEmpty('请输入重量'),
        coverUrl: verify.isEmpty('请上传规格图'),
      },
      tableHeaderList: [],
      goodsGoodsItem: [],
      specsLoad: false,
    }
  },
  computed: {
    disabledComputed() {
      if (this.form.identifier || this.form.stock || this.form.quota || this.form.costPrice || this.form.salePrice || this.form.barCode || this.form.volume || this.form.weight) {
        return false
      }
      return true
    }
  },
  methods: {
    //编辑操作 数据回显 父组件$refs调用
    init(goodsSpec) {
      this.form.goodsSpec = goodsSpec.map(({ valueList, name, delFlag, goodsId, id }) => {
        let specValue = valueList.filter(({ delFlag }) => delFlag === 0).map((valueListItem) => {
          return {
            delFlag: valueListItem.delFlag,
            goodsId: valueListItem.goodsId,
            id: valueListItem.id,
            seat: valueListItem.seat,
            specId: valueListItem.specId,
            value: valueListItem.value,
          }
        })

        return {
          delFlag,
          goodsId,
          name,
          id,
          isEdit: true,
          optionList: [{ label: name, value: name }],
          specValue,
          checkList: valueList.map((valueListItem) => {
            return {
              delFlag: valueListItem.delFlag,
              goodsId: valueListItem.goodsId,
              id: valueListItem.id,
              seat: valueListItem.seat,
              specId: valueListItem.specId,
              value: valueListItem.value,
            }
          }),
          checkSelectList: specValue.map(({ value }) => value),
          oldCheckSelectList: specValue.map(({ value }) => value),
        }
      })

      this.formatter()

      this.createdTable(this.form.goodsSpec)
    },
    /** 格式化规格，给新增specValueItem增加占位符sku + index */
    formatter() {
      let skuNum = 1;
      this.form.goodsSpec.forEach(({ specValue }) => {
        specValue.forEach((specValueItem) => {
          if (!specValueItem.id) {
            specValueItem.seat = `sku${specValueItem.value}${skuNum}`;
          } else {
            specValueItem.seat = '';
          }
          skuNum++;
        })
      })
    },
    /** 删除规格 */
    handleDelete(item, index) {
      this.$moe_layer.confirm(`您确定要删除当前选项“${item.name}”吗？`, () => {
        this.form.goodsSpec.splice(index, 1);
        this.$moe_msg.success('删除成功');
      })
    },
    /** 选择规格值 重置 */
    dialogReset(item) {
      let checkedCount = item.checkSelectList.length;
      let total = item.checkList.length;
      this.dialogModel.checkAll = checkedCount === total;
      this.dialogModel.isIndeterminate = checkedCount > 0 && checkedCount < total;
    },
    /** 获取规格值 */
    specsValueDetail(specsId, item, index) {
      this.goodsSpecIndex = index;
      if (item.isEdit) {
        this.dialogReset(item);
        this.dialogModel.show = true;
      } else {
        if (!specsId) {
          return this.$moe_msg.warning('请选择规格')
        }
        if (item.checkList.length) {
          this.dialogReset(item);
          this.dialogModel.show = true
        } else {
          this.$moe_api.GOODS_API.specsValueDetail({ specsId }).then((data) => {
            item.checkList = data.result.map(({ value }) => {
              return {
                delFlag: 0,
                goodsId: '',
                id: '',
                seat: '',
                specId: '',
                value
              }
            })
            this.dialogReset(item);
            this.dialogModel.show = true;
          })
        }
      }
    },
    handleInputChange(value) {
      if (!value) {
        return true;
      }

      if (this.form.goodsSpec[this.goodsSpecIndex].checkList.filter((item) => item.value === value).length >= 1) {
        return false;
      } else {
        this.form.goodsSpec[this.goodsSpecIndex].checkList.push({
          delFlag: '',
          goodsId: this.isEdit ? this.$route.query.id : '',
          id: '',
          seat: '',
          specId: '',
          value,
        });
        this.form.goodsSpec[this.goodsSpecIndex].checkSelectList.push(value);
        this.dialogReset(this.form.goodsSpec[this.goodsSpecIndex]);
        this.dialogModel.addCheckValue = '';
      }
    },
    /** 规格选择 */
    handleChange(value, item, index) {
      if (value) {
        let searchName = this.specsList.find(({ id }) => id === value).name;

        if (this.form.goodsSpec.filter(({ name }) => name === searchName).length >= 1) {
          item.id = item.name = '';
          this.form.goodsSpec.splice(index, 1);
          return this.$moe_msg.warning(`该规格“${searchName}”已经存在`);
        }

        if (item.name && item.name !== searchName) {
          item.checkList.splice(0);
          item.checkSelectList.splice(0);
          item.oldCheckSelectList.splice(0);
          item.specValue.splice(0);
          this.dialogModel.isIndeterminate = false;
          this.dialogModel.checkAll = false;
        }

        item.name = searchName;
        this.specsValueDetail(value, item, index);
      }
    },
    /** 选择规格值 确定 */
    insertSpecValue() {
      this.form.goodsSpec[this.goodsSpecIndex].specValue = this.form.goodsSpec[this.goodsSpecIndex].checkSelectList.map((value) => {
        let findItem = this.form.goodsSpec[this.goodsSpecIndex].checkList.find((item) => value === item.value);
        return {
          delFlag: findItem.delFlag,
          value: findItem.value,
          id: findItem.id,
          categoryId: findItem.categoryId,
          specId: findItem.specId,
          goodsId: findItem.goodsId,
        }
      });

      this.formatter();

      this.form.goodsSpec[this.goodsSpecIndex].oldCheckSelectList = JSON.parse(JSON.stringify(this.form.goodsSpec[this.goodsSpecIndex].checkSelectList));
      this.createdTable(this.form.goodsSpec)
      this.dialogModel.show = false;

      this.$refs['specsForm'].validate(() => {})
    },
    /** 选择规格值 关闭 取消 */
    dialogClose() {
      this.form.goodsSpec[this.goodsSpecIndex].checkSelectList = JSON.parse(JSON.stringify(this.form.goodsSpec[this.goodsSpecIndex].oldCheckSelectList));
      this.dialogModel.addCheckValue = '';
      this.dialogModel.show = false;
    },
    /** checkbox 全选监听 */
    handleCheckAllChange(value) {
      this.form.goodsSpec[this.goodsSpecIndex].checkSelectList = value ? this.form.goodsSpec[this.goodsSpecIndex].checkList.map((item) => item.value) : [];
      this.dialogModel.isIndeterminate = false;
    },
    /** checkbox 单选监听 */
    handleCheckboxChange(value) {
      let checkedCount = value.length;
      this.dialogModel.checkAll = checkedCount === this.form.goodsSpec[this.goodsSpecIndex].checkList.length;
      this.dialogModel.isIndeterminate = checkedCount > 0 && checkedCount < this.form.goodsSpec[this.goodsSpecIndex].checkList.length;
    },
    /** 表格数据批量填充 */
    handleBatchFill() {
      this.form.shopGoodsItem.forEach((item) => {
        item.identifier = this.form.identifier || item.identifier;
        item.stock = this.form.stock || item.stock;
        item.quota = this.form.quota || item.quota;
        item.costPrice = this.form.costPrice || item.costPrice;
        item.salePrice = this.form.salePrice || item.salePrice;
        item.barCode = this.form.barCode || item.barCode;
        item.volume = this.form.volume || item.volume;
        item.weight = this.form.weight || item.weight;
      });

      this.$moe_msg.success(`成功改变${this.form.shopGoodsItem.length}条数据`);

      this.from.identifier = '';
      this.form.stock = '';
      this.form.quota = '';
      this.form.costPrice = '';
      this.form.salePrice = '';
      this.form.barCode = '';
      this.form.volume = '';
      this.form.weight = '';
    },
    /** 生成表格 */
    createdTable(goodsSpec) {
      this.tableHeaderList = goodsSpec.map(({ name, specValue, checkList }) => {
        return {
          attr: name,
          [`${name}specValueIds`]: 'specValueIds',
          [`${name}specValueStr`]: 'specValueStr',
          specValue,
          checkList,
        }
      })

      this.form.shopGoodsItem = this.generateBaseData(this.tableHeaderList).map(item => {
        item.specValueIds = [];
        item.specValueStr = [];
        this.tableHeaderList.forEach(({ attr }) => {
          item.specValueIds.push(item[`${attr}specValueIds`])
          item.specValueStr.push(item[attr])
        })

        if (this.shopGoodsItem.length) {
          let findItem = this.shopGoodsItem.find(({ specValueIds }) => comparisonData(item.specValueIds.map((ids) => String(ids)), specValueIds));
          if (findItem) {
            return {
              ...item,
              ...findItem,
            }
          } else {
            return {
              ...item,
              id: '',
              identifier: '',      //仓库编码
              stock: '',          //库存
              quota: '',          //限购
              costPrice: '',      //成本价
              salePrice: '',      //销售价
              barCode: '',        //条码
              volume: '',         //体积
              weight: '',         //重量
              coverUrl: '',       //图片
              imgsUrlList: [],
              delFlag: 0,         //删除状态 0：正常 1：删除
              status: 0,          //是否展示 0:是,1:否
            }
          }
        } else {
          return {
            ...item,
            id: '',
            identifier: '',      //仓库编码
            stock: '',          //库存
            quota: '',          //限购
            costPrice: '',      //成本价
            salePrice: '',      //销售价
            barCode: '',        //条码
            volume: '',         //体积
            weight: '',         //重量
            coverUrl: '',       //图片
            imgsUrlList: [],
            delFlag: 0,         //删除状态 0：正常 1：删除
            status: 0,          //是否展示 0:是,1:否
          }
        }
      })
    },
    generateBaseData(arr) {
      if (arr.length === 0) return []
      if (arr.length === 1) {
        let [item_spec] = arr
        return item_spec.specValue.map(({ id, value, seat }) => {
          return {
            [item_spec.attr]: value,
            [item_spec.specValueIds]: id || seat,
            [item_spec.specValueStr]: value,
            [`${item_spec.attr}specValueIds`]: [id || seat],
          }
        })
      }
      if (arr.length >= 1) {
        return arr.reduce((accumulator, spec_item) => {
          let acc_value_list = Array.isArray(accumulator.specValue) ? accumulator.specValue : accumulator
          let item_value_list = spec_item.specValue
          let result = []
          for (let i in acc_value_list) {
            for (let j in item_value_list) {
              let temp_data = {}
              // 如果是对象
              if (acc_value_list[i].constructor === Object) {
                temp_data = {
                  ...acc_value_list[i],
                  [accumulator.attr]: acc_value_list[i].value,
                  [spec_item.attr]: item_value_list[j].value,
                  [`${accumulator.attr}specValueIds`]: acc_value_list[i].id || acc_value_list[i].seat,
                  [`${spec_item.attr}specValueIds`]: item_value_list[j].id || item_value_list[j].seat,
                }
                // 否则如果是字符串
              } else {
                temp_data[accumulator.attr] = acc_value_list[i].value
                temp_data[spec_item.attr] = item_value_list[j].value
              }
              result.push(temp_data)
            }
          }
          return result
        })
      }
    },
    /** 商品规格列表 */
    querySpecsList() {
      this.$moe_api.GOODS_API.querySpecsList({ pageNum: 1, pageSize: 9999 }).then((data) => {
        this.specsList = data.result.map((item) => {
          return {
            ...item,
            label: item.name,
            value: item.id,
          }
        })
      })
    },
    /** 新增商品规格 */
    insert() {
      this.form.goodsSpec.push({
        delFlag: 0,
        goodsId: "",
        name: "",
        id: '',
        isEdit: false,
        specValue: [],
        checkList: [],
        checkSelectList: [],
        oldCheckSelectList: []
      })
    },
  },
  mounted() {
    this.querySpecsList();
  }
}
</script>

<style lang="scss" scoped>
.moe-specs {
  flex: 1;
  display: flex;
  flex-flow: column;
  .dialog_content {
    margin: 20px auto;
    width: 80%;

    .dialog_content_checkbox {
      position: relative;
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto;
    }
  }
  .specs-item {
    padding: 7px 10px 7px 10px;
    border: 1px solid $--color-info;
    border-radius: 4px;
    margin: 0 20px 20px 0;
    max-height: 36px;
    font-size: 14px;
    line-height: 1;
  }
  &-list {
    margin-bottom: 0;
  }
  &-checkbox-add {
    height: 36px;
    margin-bottom: 10px;
  }
}
</style>