<template>
  <el-input
    class="moe-textarea"
    type="textarea"
    :rows="rows"
    :autosize="autosize"
    v-model="defaultValue"
    :placeholder="placeholder"
    :readonly="readonly"
    :disabled="disabled"
    @input="onInput">
  </el-input>
</template>

<script>

// 输入框占位文本
/**
 * 文件上传 - moe-textarea
 * @description 基于el-input组件的二次封装
 * @property {String} value             双向绑定
 * @property {String} placeholder       输入框占位文本
 * @property {Boolean} readonly         原生属性，是否只读
 * @property {Boolean} disabled         禁用状态
 * @property {Number} rows              输入框行数
 * @property {Boolean Object} autosize  自适应内容高度 { minRows: 2, maxRows: 6 }
 * @event {Function} input 在 Input 值改变时触发，触发父组件的change
 **/
export default {
  name: 'moe-textarea',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 1
    },
    autosize: {
      type: [Boolean, Object],
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue) {
      this.defaultValue = newValue
    },
  },
  data() {
    return {
      defaultValue: this.value
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    }
  }
}
</script>

<style lang="scss">

</style>