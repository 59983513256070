/**
  * Created by lichuangwei on 2023/3/16
  * Copyright © 2017 www.moemone.com. All rights reserved.
  * 定义了本地存储的字段常量
  */

const STORAGE = {
  OSS_TOKEN: 'STORAGE_TOKEN', //oss token
}
export default STORAGE
