<template>
  <vue-tinymce id="moe-tinymce" class="moe-tinymce" ref="editor" :setting="setting" :setup="setup" @change="update" v-model="content"></vue-tinymce>
</template>

<script>
import { uploadHelper } from "@/utils/upload-helper";
import ossHttp from '@/utils/ossHttp';
import { yunHelper } from "@/utils/yun-helper";
export default {
  name: "moeTinymce",
  model: {
    props: 'value',
  },
  props: {
    value: { type: String, },
    height: { type: [String, Number], default: 500 },
  },
  computed: {
    computedContentCss() {
      return `
        .management-img__default {
          width: 200px;
          height: 200px;
        }
      `;
    },
  },
  data() {
    // fontFamily,js
    const fontFamily = "微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings"

    return {
      content: this.value,
      setting: {
        // inline: true,  // 设置内敛模式
        menubar: false,
        toolbar: "undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | myUploadImage myUploadVideo table | fontselect fontsizeselect styleselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |", //myUpload image media table
        toolbar_drawer: "sliding",
        quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
        plugins: "link image media table lists fullscreen",
        language: 'zh_CN', // 语言，汉化
        branding: false, // 隐藏品牌，隐藏状态栏中显示的“ Powered by Tiny ”链接
        resize: false, // 是否可以缩放编辑器
        elementpath: true, // 在编辑器底部的状态栏中禁用元素路径。
        fontsize_formats: "12px 14px 16px 18px 20px 24px", // 字号
        font_formats: fontFamily, //字体
        style_formats: [
          {
            title: "行高",
            items: [
              { title: "1", block: "p", styles: { "line-height": "1.0" } },
              { title: "1.25", block: "p", styles: { "line-height": "1.25" } },
              { title: "1.5", block: "p", styles: { "line-height": "1.5" } },
              { title: "1.75", block: "p", styles: { "line-height": "1.75" } },
              { title: "2", block: "p", styles: { "line-height": "2" } },
              { title: "3", block: "p", styles: { "line-height": "3" } },
              { title: "4", block: "p", styles: { "line-height": "4" } },
              { title: "5", block: "p", styles: { "line-height": "5" } },
            ],
          },
        ],
        // fixed_toolbar_container: '#tinymceToolbar', // 可以设置元素选择器指定工具栏嵌套在哪个元素里面
        // custom_ui_selector: 'body', // 聚焦的元素
        // noneditable_noneditable_class: 'moeNonEditable', // 使用此选项，您可以指定TinyMCE将使用的类名称，以确定使用noneditable插件时可编辑哪些内容区域。主要用入你想以代码的形式添加某些内容，并给这些内容设置类名，使他们不可编辑，只能整个删除
        // init_instance_callback: editor => {
        //   editor.focus() // 初始化聚焦，让内联模式的编辑器工具显示
        // },
        height: this.height,
        content_style: '.management-img__default { width: 200px; }',
        // deprecation_warnings: false
        // -- 自定义Tinymce的图片上传，上传至OSS
        // images_upload_handler: (blobInfo, succFun, failFun) => {
        //   var file = blobInfo.blob(); //转化为易于理解的file对象
        //   const { isValid, message } = uploadHelper.validateImageAttribute(file)
        //   if (!isValid) {
        //     failFun(message)
        //     return
        //   }

        //   yunHelper.uploadFileToTemporary(this.uploadDirectory, file).then(result => {
        //     if (result) {
        //       succFun(result.temporaryURL)
        //     } else {
        //       failFun('上传失败，请刷新后重新尝试，或联系管理员')
        //     }
        //   })
        // },
        // -- 自定义Tinymce的视频上传，上传至OSS
        // 视频弹窗的本地文件选择按钮图标
        // file_picker_types: 'media',
        // file_picker_callback: (cb, value, meta) => {
        //   if (meta.filetype === 'media') {
        //     //创建一个隐藏的type=file的文件选择input
        //     const input = document.createElement('input')
        //     input.setAttribute('type', 'file')
        //     // 触发点击
        //     input.click()
        //     input.onchange = () => {
        //       // 只选取第一个文件。如果要选取全部，后面注意做修改
        //       const file = input.files[0];
        //       const { isValid, message } = uploadHelper.validateVideoAttribute(file)
        //       if (!isValid) {
        //         // Todo: 提示视频文件格式不匹配
        //         return this.$moe_msg.warning(message);
        //       }

        //       yunHelper.uploadFileToTemporary(this.uploadDirectory, file).then(result => {
        //         if (result) {
        //           cb(result.temporaryURL, { title: file.name })
        //         } else {
        //           // Todo: 错误提示
        //           this.$moe_msg.error('上传失败，请刷新后重新尝试，或联系管理员');
        //         }
        //       })
        //     }
        //   }
        // }
      }
    }
  },
  watch: {
    value(val) {
      this.content = val;
    }
  },
  methods: {
    /**
      * 自定义插件实现自定义工具栏按钮功能
      */
    async setup(editor) {
      editor.ui.registry.addButton('myUploadImage', {
        tooltip: '上传',
        icon: 'image',
        onAction: () => {
          const input = document.createElement('input')
          input.setAttribute('type', 'file')
          input.setAttribute('multiple', 'multiple')
          input.click()
          input.onchange = async() => {
            const fileList = input.files;
            for (const fileItem of fileList) {
              const { isValid, message } = uploadHelper.validateImageAttribute(fileItem)
              if (!isValid) {
                return this.$moe_msg.warning(message);
              }

              const { region, accessKeyId, accessKeySecret, stsToken, bucket, path } = await this.$moe_yunHelper.getStsRequest('SHOP_MEDIA');
              let name = this.$moe_yunHelper.getuuidFormFileName(fileItem);

              const formData = new FormData();
              formData.append('key', `${path}${name}`);
              formData.append('OSSAccessKeyId', accessKeyId);
              const policy = this.$moe_yunHelper.getpolicy();
              formData.append('policy', policy);
              const signature = this.$moe_yunHelper.computeSignature(accessKeySecret, policy);
              formData.append('signature', signature);
              formData.append('x-oss-security-token', stsToken);
              formData.append('file', fileItem);

              await ossHttp({
                method: 'post',
                data: formData,
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }).then(() => {
                this.content += `<img class="management-img__default" style="display: block;" src="${process.env.VUE_APP_OSS_UPLOAD}/${path}${name}" alt="" />`
                editor.setContent(this.content);
                this.$emit('input', this.content);
              }).catch(() => {
                this.$moe_msg.error('上传失败，请刷新后重新尝试，或联系管理员');
              })
            }
          }
        }
      }),
      editor.ui.registry.addButton('myUploadVideo', {
        tooltip: '上传',
        icon: 'embed',
        onAction: async () => {
          //创建一个隐藏的type=file的文件选择input
            const input = document.createElement('input')
            input.setAttribute('type', 'file')
            // 触发点击
            input.click()
            input.onchange = async () => {
              // 只选取第一个文件。如果要选取全部，后面注意做修改
              const file = input.files[0];
              const { isValid, message } = uploadHelper.validateVideoAttribute(file)
              if (!isValid) {
                // Todo: 提示视频文件格式不匹配
                return this.$moe_msg.warning(message);
              }

              const { region, accessKeyId, accessKeySecret, stsToken, bucket, path } = await this.$moe_yunHelper.getStsRequest('SHOP_MEDIA');
              let name = this.$moe_yunHelper.getuuidFormFileName(file);

              const formData = new FormData();
              formData.append('key', `${path}${name}`);
              formData.append('OSSAccessKeyId', accessKeyId);
              const policy = this.$moe_yunHelper.getpolicy();
              formData.append('policy', policy);
              const signature = this.$moe_yunHelper.computeSignature(accessKeySecret, policy);
              formData.append('signature', signature);
              formData.append('x-oss-security-token', stsToken);
              formData.append('file', file);

              await ossHttp({
                method: 'post',
                data: formData,
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }).then(() => {
                this.content += `<div><video controls src="${process.env.VUE_APP_OSS_UPLOAD}/${path}${name}"></video></div>`;
                editor.setContent(this.content);
                this.$emit('input', this.content);
              }).catch(() => {
                this.$moe_msg.error('上传失败，请刷新后重新尝试，或联系管理员');
              })
            }
        }
      })
    },
    update(editor) {
      this.$emit('input', editor)
    }
  },
};
</script>

<style lang="scss">
.moe-tinymce {
  &-file {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
</style>