<template>
  <!-- 首页内容 -->
  <el-main class="moe-main">
    <div
      class="moe-main__inner"
      v-loading="childPageLoad"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0,0,0,.8)"
    >
      <transition name="el-zoom-in-center">
        <router-view v-if="!childPageLoad" />
      </transition>
    </div>
    <div
      :class="['moe-main__back', $route.meta.back ? 'moe-main__back-show' : '']"
      @click="$router.go(-1)"
    >
      <moe-icon name="back" size="60"></moe-icon>
    </div>
  </el-main>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'moe-main',
  /**
   * 系统配置
   * childPageLoad: 子页面刷新状态
   */
  computed: mapState('setting', ['childPageLoad']),
};
</script>

<style lang="scss" scoped>
.moe-main {
  padding: 0;
  color: #333;
  width: 100%;
  // height: calc(100% - 101px);
  height: calc(100% - 61px);
  display: flex;
  flex-direction: column;
  // background-color: #eff1f4;
  background-color: #fff;
  position: relative;
  transition: all 0.3s;

  /* 子页面 */
  &__inner {
    flex: 1;
    overflow: auto;
  }

  .moe-main__back {
    position: fixed;
    top: 160px;
    right: -30px;
    width: 35px;
    height: 35px;
    line-height: 37px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    cursor: pointer;
    z-index: 10;
    opacity: 0;
    color: $--color-primary;
    transition: opacity 250ms, right 350ms;

    &:hover {
      background-color: #fff;
    }

    &-show {
      opacity: 1;
      right: 60px;
    }
  }
}
</style>
