<template>
  <el-tooltip class="moe-tooltip" :effect="effect" :content="content" :placement="placement">
    <slot>
      <!-- 插槽 -->
    </slot>
  </el-tooltip>
</template>

<script>
export default {
  name: 'moe-tooltip',
  props: {
    /**
      * 默认提供的主题
      * dark/light
      */
    effect: {
      type: String,
      default: 'dark'
    },
    // 显示的内容
    content: {
      type: String,
      default: ''
    },
    /**
      * Tooltip 的出现位置
      * top/top-start/top-end/bottom/bottom-start/bottom-end/left/left-start/left-end/right/right-start/right-end
      */
    placement: {
      type: String,
      default: 'bottom'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>