
/**
 * API配置
 **/

/**
 * LOGIN_API: 登录API
 * ACCOUNT_API: 账号API
 * ROLE_API: 角色API
 * USER_API: 用户API
 * EQUIPMENT_API: 设备API
 * PET_API: 宠物API
 * PUSH_API: 推送API
 * STSTEM_API: 系统API
*/

import LOGIN_API from './modules/login'
import ACCOUNT_API from './modules/account'
import ROLE_API from './modules/role'
import USER_API from './modules/user'
import EQUIPMENT_API from './modules/equipment'
import PET_API from './modules/pet'
import PUSH_API from './modules/push'
import STSTEM_API from './modules/system'
import STATISTICS_API from './modules/statistics'
import BANNER_API from './modules/banner'
import GOODS_API from './modules/goods'
import ORDER_API from './modules/order'
import GENERAL_API from './modules/general'
import VERSION_API from './modules/version'
import ADVERT_API from './modules/advert'
import FQA_API from './modules/fqa'
import HARDWARETEST_API from './modules/hardwareTest'
import GROUPACTIVITY_API from './modules/groupActivity'
import PRESALE_API from './modules/presale'
import GOODS_DISCOUNT from './modules/goodsDiscount'
import SHOP_DISCOUNT from './modules/shopDiscount';
import COUPON_API from './modules/coupon';
import CONTENT from './modules/content';
import BRAND_API from './modules/brand';
import NYUANOPTIONAL_API from './modules/nYuanOptional';
import SUPPLIER_API from './modules/supplier';
import SYSTEM_API from './modules/system';
import NEWCOMER_API from './modules/newcomer';
import FREIGHTTPL_API from './modules/freightTpl';
import INVOICE_API from './modules/invoice';
import ASSIST_API from './modules/assist';
import GIFTMANAGE_API from './modules/activity/giftManage';
import BUYGIFT_API from './modules/activity/buyGift';
import GOODSBADGE_API from './modules/activity/goodsBadge';
import SUPPLIERBILLING_API from './modules/billing/supplierBilling';
import FLASH_API from './modules/activity/flash';
import LUCKYGRID_API from './modules/lottery/luckyGrid';
import QUESTIONNAIRESURVEY_API from './modules/questionnaireSurvey';
import REVIEW_API from './modules/review';
import SIGN_API from './modules/sign';
import GUESS_API from './modules/activity/guess';

/* export API */
export default {
  LOGIN_API,
  ACCOUNT_API,
  ROLE_API,
  USER_API,
  EQUIPMENT_API,
  PET_API,
  PUSH_API,
  STSTEM_API,
  STATISTICS_API,
  BANNER_API,
  GOODS_API,
  ORDER_API,
  GENERAL_API,
  VERSION_API,
  ADVERT_API,
  FQA_API,
  HARDWARETEST_API,
  GROUPACTIVITY_API,
  PRESALE_API,
  GOODS_DISCOUNT,
  SHOP_DISCOUNT,
  COUPON_API,
  CONTENT,
  BRAND_API,
  NYUANOPTIONAL_API,
  SUPPLIER_API,
  SYSTEM_API,
  NEWCOMER_API,
  FREIGHTTPL_API,
  INVOICE_API,
  ASSIST_API,
  GIFTMANAGE_API,
  BUYGIFT_API,
  GOODSBADGE_API,
  SUPPLIERBILLING_API,
  FLASH_API,
  LUCKYGRID_API,
  QUESTIONNAIRESURVEY_API,
  REVIEW_API,
  SIGN_API,
  GUESS_API,
};
