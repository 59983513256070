/**
 * 账号API
 **/

import http from '@/utils/http'
import qs from 'qs'

/* export API */
export default {
    /* 账号列表 */
    accountList(params) {
        return http({
            method: 'get',
            url: '/user/pageList',
            params
        })
    },

    /* 账号详情 */
    accountDetail(params) {
        return http({
            method: 'get',
            url: '/user/getInfo',
            params
        })
    },

     /* 账号添加 */
     accountAdd(data) {
        return http({
            method: 'post',
            url: '/user/add',
            data:qs.stringify(data)
        })
    },

     /* 账号修改 */
     accountAmend(data) {
        return http({
            method: 'post',
            url: '/user/update',
            data:qs.stringify(data)
        })
    },

     /* 账号删除 */
     accountDelete(params) {
        return http({
            method: 'get',
            url: '/user/delete',
            params
        })
    },

     /* 账号重置密码 */
     accountResetPwd(params) {
        return http({
            method: 'get',
            url: '/user/resetPwd',
            params
        })
    },

     /* 账号状态修改 */
     accountStatus(data) {
        return http({
            method: 'post',
            url: '/user/updataState',
            data: qs.stringify(data)
        })
    },
}