/**
  -- Created by Lichuangwei on 2024/1/02
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 用户管理
* */

import BlankView from '@/views/BlankView.vue';

const UserManage = [
  {
    path: 'userManage',
    name: 'UserManage',
    meta: {
      title: '用户管理',
      path: '/userManage/tagsManage/list',
    },
    component: BlankView,
    children: [
      /** 标签管理 */
      {
        path: '/userManage/tagsManage/list',
        name: 'UserManageTagsManageList',
        meta: {
          title: '标签列表',
          path: '/userManage/tagsManage/list',
          affix: true,
        },
        component: () => import('@/views/UserManage/TagsManage/List.vue'),
      },
      {
        path: '/userManage/tagsManage/add',
        name: 'UserManageTagsManageAdd',
        meta: {
          title: '新建标签',
          path: '/userManage/tagsManage/add',
          affix: true,
        },
        component: () => import('@/views/UserManage/TagsManage/Add.vue'),
      },
    ],
  },
];

export default UserManage;