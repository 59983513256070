/**
 * @Module Format
 * @Describe 处理数据格式
 **/

export default {
  /* 获取宠物体型 */
  getPetContour(key) {
    const data = {
      0: '玩具',
      1: '茶杯',
      2: '迷你',
      3: '标准',
      4: '小型',
      5: '中型',
      6: '大型',
      7: '巨型',
      default: '',
    };

    let show = '';
    if (key) {
      if (key.indexOf(',') != -1) {
        const keys = key.split(',');
        const names = keys.map((item) => {
          return data[item];
        });
        show = names.join(',');
      } else {
        show = data[key];
      }
    }

    return show || data['default'];
  },

  /* 获取宠物类型 */
  getPetType(key) {
    const data = {
      0: '喵星人',
      1: '汪星人',
      default: '',
    };
    return data[key] || data['default'];
  },

  /* 获取性别 */
  getSex(key) {
    const data = {
      0: '男',
      '-1': '女',
      default: '',
    };
    return data[key] || data['default'];
  },

  /* 获取宠物性别 */
  getPetSex(key) {
    const data = {
      0: 'G',
      1: 'M',
      2: '绝育G',
      3: '绝育M',
      default: '',
    };
    return data[key] || data['default'];
  },

  /* 获取商品上架状态 */
  getPutaway(key) {
    const data = {
      0: '已上架',
      1: '已下架',
      default: '',
    };
    return data[key] || data['default'];
  },

  /* 获取支付状态 */
  getPayType(key) {
    const data = {
      1: '支付宝',
      2: '微信',
      3: '微信',
      default: '',
    };
    return data[key] || data['default'];
  },

  /* 获取商城广告类型 */
  getBannerType(key) {
    const data = {
      1: '首页轮播图',
      2: '拼团轮播图',
      3: '首页广告图1',
      4: '首页广告图2',
      5: '新人专享广告图',
      6: '通知专用页',
      default: '',
    };

    return data[key] || data['default'];
  },

  /* 获取商城广告跳转类型 */
  getBannerFormType(key) {
    const data = {
      0: '无',
      1: '站外链接',
      2: '站内链接',
      3: '商品详情',
      4: '专区商品',
      default: '',
    };

    return data[key] || data['default'];
  },

  /* 获取商城广告类型 */
  getAdvertLink(key) {
    const data = {
      '/pages/mall/index': '商城首页',
      '/pages/mall/detail': '商品详情',
      '/pages/mall/goods': '商品专区',
      '/pages/activity/detail': '通知专用页',
      '/pages/activity/optional/detail': 'N元任选活动页',
      '/pages/activity/newcomer/detail': '新人专享活动页',
      '/pages/coupon/detail': '优惠券详情',
      '/pages/coupon/discount/detail': '店铺折扣详情',
      '/pages/mall/secondary-page?alias=secondaryPage': '二级页面1',
      '/pages/mall/secondary-page?alias=secondaryPage2': '二级页面2',
      '/pages/mall/secondary-page?alias=secondaryPage3': '二级页面3',
      '/pages/activity/assist/detail': '助力拉新活动页',
      '/pages/survey/index': '问卷调查页',
      '/pages/lottery/index': '九宫格抽奖页',
      default: '',
    };

    return data[key] || data['default'];
  },

  /* 获取订单状态 */
  getPayStatus(key) {
    const data = {
      // CANCELLED: '已取消',
      PENDING_PAYMENT: '待付款',
      PENDING_PAYMENT_DEPOSIT: '定金待支付',
      PENDING_PAYMENT_BALANCE: '尾款待支付',
      GROUPING: '拼团中',
      PENDING_SHIPMENT: '待发货',
      PARTIAL_SHIPMENT: '部分发货',
      PENDING_RECEIPT: '待收货',
      COMPLETED: '已完成',
      AFTER_SALES_SERVICE: '售后中',
      REFUNDED: '已退款',
      PARTIAL_REFUND: '部分退款',
      REFUND_REJECTED: '拒绝退款',
      ORDER_CLOSED: '订单关闭',
      default: '',
    };
    return data[key] || data['default'];
  },

  /* 获取支付方式 */
  getpayType(key) {
    const data = {
      1: {
        title: '支付宝',
        icon: 'alipay',
        color: 'color-blue',
      },
      2: {
        title: '微信',
        icon: 'wechat',
        color: 'color-success',
      },
      3: {
        title: '微信小程序',
        icon: 'wechat',
        color: 'color-success',
      },
      '': {
        title: '',
        icon: '',
        color: '',
      },
      default: {
        title: '',
        icon: '',
        color: '',
      },
    };
    return data[key] || data['default'];
  },

  /** 获取售后方式 */
  getRefundType(key) {
    const data = {
      1: '仅退款',
      2: '退货退款',
      default: '',
    };
    return data[key] || data['default'];
  },

  /** 商品详情展示风格 */
  getGoodsStyle(key) {
    const data = {
      default: '常规风格',
      brief: '简约风格',
      '': '',
    };
    return data[key];
  },

  /* 获取硬件产品测试报告状态 */
  getTestRecordstatus(key) {
    const data = {
      true: {
        title: '通过',
        class: 'color-success',
      },
      false: {
        title: '不通过',
        class: 'color-danger',
      },
      '': {
        title: '',
        class: '',
      },
      default: {
        title: '',
        class: '',
      },
    };
    return data[key] || data['default'];
  },

  /** 获取拼团活动状态 */
  getGroupActivityState(key) {
    const data = {
      PENDING: '未发布',
      NOT_STARTED: '未开始',
      IN_PROGRESS: '进行中',
      FINISHED: '已结束',
      CLOSED: '已关闭',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取拼团活动状态颜色 */
  getGroupActivityStateColor(key) {
    const data = {
      PENDING: 'color-danger',
      NOT_STARTED: 'color-green',
      IN_PROGRESS: 'color-success',
      FINISHED: 'color-info',
      CLOSED: 'color-gray2',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取拼团订单状态 */
  getGroupOrderState(key) {
    const data = {
      GOING: '拼团中',
      SUCCESS: '拼团成功',
      FAIL: '拼团失败',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取拼团订单状态 */
  getGroupOrderStateColor(key) {
    const data = {
      GOING: 'color-primary',
      SUCCESS: 'color-success',
      FAIL: 'color-danger',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取拼团订单返现状态 */
  getGroupCashbackState(key) {
    const data = {
      NO: '未返现',
      PENDING: '待返现',
      FINISH: '已返现',
      FAIL: '返现失败',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取预售活动状态 */
  getPresaleState(key) {
    const data = {
      PENDING: '未发布',
      IN_PROGRESS: '进行中',
      FINISHED: '已结束',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取预售活动状态颜色 */
  getPresaleStateColor(key) {
    const data = {
      PENDING: 'color-danger',
      IN_PROGRESS: 'color-success',
      FINISHED: 'color-info',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取商品关联进行中的活动类型 */
  getRunningActivityType(key) {
    const data = {
      GROUP: '拼团',
      PRESALE: '预售',
      GOODS_DISCOUNT: '商品折扣',
      OPTIONAL: 'N元任选',
      NEWCOMER: '新人价',
      FLASH: '秒杀',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取预售活动优惠级别 */
  getPresaleLevel(key) {
    const data = {
      GOOD: '商品级别',
      SKU: 'SKU级别',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取商品折扣活动状态 */
  getDiscountState(key) {
    const data = {
      PENDING: '未发布',
      NOT_STARTED: '未开始',
      PAUSED: '已暂停',
      IN_PROGRESS: '进行中',
      FINISHED: '已结束',
      CLOSED: '已关闭',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取商品折扣活动状态 */
  getDiscountStateColor(key) {
    const data = {
      PENDING: 'color-danger',
      NOT_STARTED: 'color-green',
      PAUSED: 'color-warning',
      IN_PROGRESS: 'color-success',
      FINISHED: 'color-info',
      CLOSED: 'color-gray2',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取商品折扣优惠方式 */
  getDiscountMethod(key) {
    const data = {
      DISCOUNT: '打折',
      REDUCE: '满减',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取店铺折扣优惠方式 */
  getShopDiscountMethod(key) {
    const data = {
      DISCOUNT: '满件（打折）',
      REDUCE: '满元（减钱）',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取优惠类型 */
  getDiscountLevel(key) {
    const data = {
      PART: '自选商品',
      ALL: '全店商品',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取优惠券类型 */
  getCouponLevel(key) {
    const data = {
      ALL: '店铺券',
      PART: '商品券',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取优惠券状态 */
  getCouponState(key) {
    const data = {
      RECEIVING: '领取中',
      FINISHED: '已领完',
      CLOSED: '已结束',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取优惠券状态颜色 */
  getCouponStateColor(key) {
    const data = {
      RECEIVING: 'color-success',
      FINISHED: 'color-info',
      CLOSED: 'color-gray2',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取分类级别 */
  getCategoryLevel(key) {
    const data = {
      1: '一级分类',
      2: '二级分类',
      3: '三级分类',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取广告跳转类型 */
  getAdvertForm(key) {
    const data = {
      0: '无',
      1: '站外链接',
      2: '站内链接',
      3: '商品详情',
      4: '商品专区',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取活动商品配置活动位置 */
  getActivityAliasList(key) {
    const data = {
      home: '商城首页',
      homeDailySpecial: '今日特价',
      homeActivity: '专区自配',
      secondaryPage: '二级页面1',
      secondaryPage2: '二级页面2',
      secondaryPage3: '二级页面3',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取活动商品配置活动展示风格 */
  getActivityAliasStyleList(key) {
    const data = {
      default: '常规风格',
    };

    return data[key] || data['default'];
  },

  /** 获取商城广告状态 */
  getMallContentStatus(key) {
    const data = {
      NOT_START: '未开始',
      RUNNING: '进行中',
      STOP: '已停止',
      EXPIRED: '已过期',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取商城广告状态状态颜色 */
  getMallContentStatusColor(key) {
    const data = {
      NOT_START: 'color-green',
      RUNNING: 'color-success',
      STOP: 'color-info',
      EXPIRED: 'color-gray2',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取通用是否类型 */
  getVisibleList(key) {
    const data = {
      true: '是',
      false: '否',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取N元任选状态 */
  getOptionalStateList(key) {
    const data = {
      NOT_STARTED: '未开始',
      PAUSED: '已暂停',
      IN_PROGRESS: '进行中',
      FINISHED: '已结束',
      CLOSED: '已关闭',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取N元任选状态颜色 */
  getOptionalStateListColor(key) {
    const data = {
      NOT_STARTED: 'color-green',
      PAUSED: 'color-info',
      IN_PROGRESS: 'color-success',
      FINISHED: 'color-gray2',
      CLOSED: 'color-gray2',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取新人专享状态 */
  getNewcomerStateList(key) {
    const data = {
      PENDING: '未发布',
      NOT_STARTED: '未开始',
      PAUSED: '已暂停',
      IN_PROGRESS: '进行中',
      FINISHED: '已结束',
      CLOSED: '已关闭',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取新人专享状态颜色 */
  getNewcomerStateListColor(key) {
    const data = {
      PENDING: 'color-danger',
      NOT_STARTED: 'color-green',
      PAUSED: 'color-info',
      IN_PROGRESS: 'color-success',
      FINISHED: 'color-gray2',
      CLOSED: 'color-gray2',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取消息推送-推送来源 */
  getSendSourceList(key) {
    const data = {
      SYSTEM: '系统推送',
      MANUAL: '手动推送',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取消息推送-消息类型 */
  getPushTypeList(key) {
    const data = {
      SYSTEM: '系统消息',
      DEVICE: '智能设备',
      SHOP_ORDER: '商城订单',
      REMIND: '备忘提醒',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取消息推送-推送目标 */
  getSendTargetList(key) {
    const data = {
      ALL: '全部用户',
      SPECIFY_USER: '指定用户',
      SPECIFY_TAG: '指定标签用户',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取消息推送-发送状态 */
  getSendStatusList(key) {
    const data = {
      PENDING: '待发送',
      DONE: '已发送',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取消息推送-发送时机 */
  getPushOpportunityList(key) {
    const data = {
      NOW: '立即发送',
      TIMING: '定时发送',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取消息推送-站内链接跳转 */
  getNavigateToUrlList(key) {
    const data = {
      '/pages/mall/index': '商城首页',
      '/pages/mall/detail': '商品详情',
      '/pages/mall/goods': '商品专区',
      '/pages/activity/detail': '通知专用页',
      '/pages/activity/optional/detail': 'N元任选活动页',
      '/pages/activity/newcomer/detail': '新人专享活动页',
      '/pages/coupon/detail': '优惠券详情',
      '/pages/coupon/discount/detail': '店铺折扣详情',
      '/pages/mall/secondary-page?alias=secondaryPage': '二级页面1',
      '/pages/mall/secondary-page?alias=secondaryPage2': '二级页面2',
      '/pages/mall/secondary-page?alias=secondaryPage3': '二级页面3',
      '/pages/activity/assist/detail': '助力拉新活动页',
      '/pages/survey/index': '问卷调查页',
      '/pages/lottery/index': '九宫格抽奖页',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取开票状态 */
  getInvoiceStatus(key) {
    const data = {
      PENDING: '待处理',
      REJECTED: '已驳回',
      CANCELED: '已撤销',
      TICKETED: '已开票',
      WAIT_RED: '待处理(已开票)', //待冲红
      BE_RED: '已冲红',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取开票状态颜色 */
  getInvoiceStatusColor(key) {
    const data = {
      PENDING: 'color-primary',
      REJECTED: 'color-danger',
      CANCELED: 'color-gray2',
      TICKETED: 'color-success',
      WAIT_RED: 'color-primary',
      BE_RED: 'color-info',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取开票抬头类型 */
  getInvoiceType(key) {
    const data = {
      PERSONAL: '个人',
      COMPANY: '企业',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取app广告类型 */
  getAppAdvertType(key) {
    const data = {
      1: 'APP首页轮播图',
      2: 'APP启动页广告',
      3: 'APP个人中心广告',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取助力拉新活动助力对象 */
  getAssistTarget(key) {
    const data = {
      NEW: '指定新人',
      ALL: '全部用户',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取助力拉新活动状态 */
  getAssistState(key) {
    const data = {
      NOT_STARTED: '未开始',
      IN_PROGRESS: '进行中',
      FINISHED: '已结束',
      CLOSED: '已关闭',
      default: '',
    };
    return data[key] || data['default'];
  },

  /** 获取助力拉新活动状态颜色 */
  getAssistStateColor(key) {
    const data = {
      NOT_STARTED: 'color-green',
      IN_PROGRESS: 'color-success',
      FINISHED: 'color-info',
      CLOSED: 'color-gray2',
      default: '',
    };
    return data[key] || data['default'];
  },

  /** 获取助力拉新活动记录状态 */
  getAssistRecordState(key) {
    const data = {
      RUNNING: '助力中',
      SUCCESS: '助力成功',
      FAIL: '已结束',
      default: '',
    };
    return data[key] || data['default'];
  },

  /** 获取助力拉新活动记录状态颜色 */
  getAssistStateRecordColor(key) {
    const data = {
      RUNNING: 'color-success',
      SUCCESS: 'color-primary',
      FAIL: 'color-info',
      default: '',
    };
    return data[key] || data['default'];
  },

  /** 获取赠品管理活动状态 */
  getGiftStateList(key) {
    const data = {
      PUBLISHED: '进行中',
      FINISHED: '已结束',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取赠品管理活动状态颜色 */
  getGiftStateListColor(key) {
    const data = {
      IN_PROGRESS: 'color-success',
      FINISHED: 'color-gray2',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取买赠活动状态 */
  getBuyGiftStateList(key) {
    const data = {
      NOT_STARTED: '未开始',
      IN_PROGRESS: '进行中',
      EXPIRED: '已过期',
      FINISHED: '已结束',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取买赠活动状态颜色 */
  getBuyGiftStateListColor(key) {
    const data = {
      NOT_STARTED: 'color-green',
      IN_PROGRESS: 'color-success',
      EXPIRED: 'color-gray2',
      FINISHED: 'color-gray2',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取用户标签年龄 */
  getAgeList(key) {
    const data = {
      1: { min: null, max: 17 },
      2: { min: 18, max: 24 },
      3: { min: 25, max: 30 },
      4: { min: 31, max: 35 },
      5: { min: 36, max: 40 },
      6: { min: 40, max: null },
      default: {},
    };

    return data[key] || data['default'];
  },

  /** 获取商品主图放置位置 */
  getGoodsBadgeStyle(key) {
    const data = {
      1: { top: 0, left: 0 },
      2: { bottom: 0, left: 0 },
      default: {},
    };

    return data[key] || data['default'];
  },

  /** 获取买赠活动状态 */
  getGoodsBadgeStateList(key) {
    const data = {
      NOT_STARTED: '未开始',
      IN_PROGRESS: '进行中',
      // EXPIRED: '已过期',
      FINISHED: '已结束',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取买赠活动状态颜色 */
  getGoodsBadgeStateListColor(key) {
    const data = {
      NOT_STARTED: 'color-green',
      IN_PROGRESS: 'color-success',
      // EXPIRED: 'color-gray2',
      FINISHED: 'color-gray2',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取秒杀活动状态 */
  getFlashStateList(key) {
    const data = {
      NOT_STARTED: '未开始',
      PREHEATING: '预热中',
      IN_PROGRESS: '进行中',
      FINISHED: '已结束',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取秒杀活动状态颜色 */
  getFlashStateListColor(key) {
    const data = {
      NOT_STARTED: 'color-green',
      PREHEATING: 'color-primary',
      IN_PROGRESS: 'color-success',
      FINISHED: 'color-gray2',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取九宫格抽奖活动状态 */
  getLuckyGridStateList(key) {
    const data = {
      PENDING: '待发布',
      NOT_STARTED: '未开始',
      IN_PROGRESS: '进行中',
      FINISHED: '已结束',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取九宫格抽奖活动状态颜色 */
  getLuckyGridStateListColor(key) {
    const data = {
      PENDING: 'color-danger',
      NOT_STARTED: 'color-green',
      IN_PROGRESS: 'color-success',
      FINISHED: 'color-gray2',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取设置奖品类型 */
  getPrizeTypeList(key) {
    const data = {
      COUPON: '优惠券',
      GIFT: '赠品',
      LOSE: '未中奖',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取问卷调查-活动状态 */
  getSurveyStateList(key) {
    const data = {
      PENDING: '未发布',
      NOT_STARTED: '未开始',
      IN_PROGRESS: '进行中',
      FINISHED: '已结束',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取所有状态对应的颜色 */
  getAllStateListColor(key) {
    const data = {
      PENDING: 'color-danger',
      NOT_STARTED: 'color-green',
      IN_PROGRESS: 'color-success',
      FINISHED: 'color-gray2',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取商品评价-置顶状态 */
  getGoodsReviewPinList(key) {
    const data = {
      true: '是',
      false: '否',
      default: '',
    };
    return data[key] || data['default'];
  },

  /** 获取商品评价-是否隐藏 */
  getGoodsReviewStateList(key) {
    const data = {
      SHOW: '显示中',
      HIDE: '已隐藏',
      default: '',
    };
    return data[key] || data['default'];
  },

  /** 获取商品评价-是否隐藏对应的颜色 */
  getGoodsReviewStateListColor(key) {
    const data = {
      SHOW: 'color-success',
      HIDE: 'color-gray2',
      default: '',
    };

    return data[key] || data['default'];
  },

  /** 获取问卷调查-题目类型 */
  getQuestionnaireSurveyTypeList(key) {
    const data = {
      RADIO: '单选题',
      CHECK: '多选题',
      TEXT: '多行文本题',
      default: '',
    };
    return data[key] || data['default'];
  },
};