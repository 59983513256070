<template>
  <!-- 登录内容 -->
  <div class="login-main">
    <div class="login-title">云宠智能后台管理系统 - 登录</div>

    <el-form ref="loginForm">
      <el-form-item>
        <el-tooltip
          v-model="accountTip"
          effect="light"
          content="请输入账号"
          placement="right"
          :hide-after="1"
          :enterable="false"
          manual
        >
          <el-input
            class="form-input"
            v-model="account.account"
            type="text"
            placeholder="输入账号"
            maxlength="30"
            autofocus
            clearable
            @blur="accountTip = false"
            @keyup.enter.native="toLogin"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item label="密码">
        <el-tooltip
          v-model="pwdTip"
          effect="light"
          content="请输入密码"
          placement="right"
          :hide-after="1"
          :enterable="false"
          manual
        >
          <el-input
            class="form-input"
            v-model="account.pwd"
            type="password"
            placeholder="输入密码"
            maxlength="30"
            clearable
            show-password
            @blur="pwdTip = false"
            @keyup.enter.native="toLogin"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item label="">
        <el-button
          class="login-btn"
          icon="el-icon-s-custom"
          :loading="loginLoad"
          @click="toLogin"
        >
          {{ loginLoad ? '正在登录...' : '登录系统' }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'LoginMain',
  data() {
    return {
      // 账号信息
      account: {
        account: '',
        pwd: '',
      },
      loginLoad: false,

      // tip信息
      accountTip: false,
      pwdTip: false,
    };
  },
  created(){
    // 缓存 初始化
    this.store_init()
  },
  methods: {
    // 缓存 初始化
    store_init(){
      // 获取账户
      var account=localStorage.getItem('account');
      this.account.account=account;

      console.log(account)
    },
    /**
     * 登录
     **/
    toLogin() {
      //获取账号密码
      let { account, pwd } = this.account;
      let el = this.$refs.loginForm.$el;
      if (account === '') {
        this.accountTip = true;
        el[0].focus();
      } else if (pwd === '') {
        this.pwdTip = true;
        el[1].focus();
      } else {
        this.loginLoad = true;
        // 延迟500毫秒登录
        setTimeout(() => {
          //调用登录API
          this.$moe_api.LOGIN_API.toLogin(this.account)
            .then((data) => {
              if (data.code == 200) {
                // 重置路由
                this.$router.resetRoutes();

                //设置token
                let token = data.result.token;
                sessionStorage.setItem('token', token);
                // 设置账户
                localStorage.setItem('account',this.account.account);

                //设置权限状态
                this.$store.commit('user/setIsAuth', true);
                this.$router.push('/welcome');

                // 登录提示
                this.$notify({
                  title: '登录成功',
                  type: 'success',
                });
              } else {
                this.$moe_msg.error(data.message);
              }
            })
            .finally(() => {
              this.loginLoad = false;
            });
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-main {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 100px;
  transform: translateY(-50%);
  padding: 30px;
  border-radius: 10px;
  color: #fff;
  // background-color: rgba(255,255,255,.3);

  .login-title {
    text-align: center;
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .form-item {
    margin: 10px 0;
    position: relative;
  }

  .form-label {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .form-input {
    padding: 0;
    width: 300px;
    outline: none;
    border: none;
    border-radius: 0 0 3px 3px;
    border-radius: 5px;

    ::v-deep input {
      height: 40px;
      line-height: 40px;
      color: #fff;
      font-size: 14px;
      outline: none;
      background: rgba(0, 0, 0, 0.1);
      border: none;
      transition: all 0.5s;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &::placeholder {
        color: #fff;
      }

      &:focus {
        border-bottom-color: #fff;
      }
    }
  }

  ::v-deep .el-form-item__label,
  ::v-deep .el-input__icon {
    color: #fff !important;
  }

  .login-btn {
    width: 100%;
    height: 35px;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    color: #333;
    font-size: 14px;
  }
}
</style>
