/**
  -- Created by Lichuangwei on 2023/01/30
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 签到活动
  -- 1.签到设置
  -- 2.签到记录
* */

import BlankView from '@/views/BlankView.vue';

const SignManage = [
  {
    path: 'signManage',
    name: 'SignManage',
    meta: {
      title: '签到活动',
      path: '/signManage/set',
    },
    component: BlankView,
    children: [
      /** 签到设置 */
      {
        path: '/signManage/set',
        name: 'SignManageSet',
        meta: {
          title: '签到设置',
          path: '/signManage/set',
          affix: true,
        },
        component: () => import('@/views/SignManage/Set.vue'),
      },
      /** 签到记录 */
      {
        path: '/signManage/record',
        name: 'SignManageRecord',
        meta: {
          title: '签到记录',
          path: '/signManage/record',
          affix: true,
        },
        component: () => import('@/views/SignManage/Record.vue'),
      },
    ],
  },
];

export default SignManage;