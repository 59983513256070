import { isEqual, differenceBy, intersectionBy, cloneDeep, unionBy } from 'lodash-es'

/**
  * 执行深比较来确定两者的值是否相等
  * 这个方法支持比较 arrays, array buffers, booleans, date objects, error objects, maps, numbers, Object objects, regexes, sets, strings, symbols, 以及 typed arrays. Object 对象值比较自身的属性，不包括继承的和可枚举的属性。
  * 不支持函数和DOM节点比较。
  */
export const comparisonData = (object, other) => {
  return isEqual(object, other)
}

/**
  * 这个方法类似_.difference ，除了它接受一个 iteratee （注：迭代器）， 调用array 和 values 中的每个元素以产生比较的标准。 结果值是从第一数组中选择。iteratee 会调用一个参数：(value)。（注：首先使用迭代器分别迭代array 和 values中的每个元素，返回的值作为比较值）。
  * 1.array (Array): 要检查的数组。
  * 2.[values] (...Array): 排除的值。
  * 3.[iteratee=_.identity] (Array|Function|Object|string): iteratee 调用每个元素。
  */
export const differenceByList = (list, list1, type) => {
  return differenceBy(list, list1, type)
}

export const intersectionByList = (list, list1, type) => {
  return intersectionBy(list, list1, type)
}

/** 深拷贝 */
export const cloneDeepData = (value) => {
  return cloneDeep(value)
}

/** 数组去重 */
export const unionByList = (list, list1, key) => {
  return unionBy(list, list1, key);
}

export default {
  comparisonData,
  differenceByList,
  intersectionByList,
  cloneDeepData,
  unionByList,
};