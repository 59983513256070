/**
  -- Created by Lichuangwei on 2023/5/17
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 常见问题解答
* */

import BlankView from '@/views/BlankView.vue'

const fqaManage = [
  {
    path: 'fqaManage',
    name: 'FqaManage',
    redirect: '/fqaManage/category/list',
    meta: {
      title: '帮助与反馈',
      path: '/fqaManage/category/list',
    },
    component: BlankView,
    children: [
      {
        path: '/fqaManage/category/list',
        name: 'FqaManageCategoryList',
        meta: {
          title: '问题分类管理',
          path: '/fqaManage/category/list',
          affix: true,
        },
        component: () => import('@/views/FqaManage/category/list.vue')
      },
      {
        path: '/fqaManage/question/list',
        name: 'FqaManageQuestionList',
        meta: {
          title: '问题列表',
          path: '/fqaManage/question/list',
          affix: true,
        },
        component: () => import('@/views/FqaManage/question/list.vue')
      },
      {
        path: '/fqaManage/question/add',
        name: 'FqaManageQuestionAdd',
        meta: {
          title: '新建问题',
          path: '/fqaManage/question/add',
          affix: true,
        },
        component: () => import('@/views/FqaManage/question/add.vue')
      },
      {
        path: '/fqaManage/question/amend',
        name: 'FqaManageQuestionAmend',
        meta: {
          title: '编辑问题',
          path: '/fqaManage/question/amend',
          affix: true,
        },
        component: () => import('@/views/FqaManage/question/amend.vue')
      },
      {
        path: '/fqaManage/feedback/list',
        name: 'FqaManageFeedbackList',
        meta: {
          title: '反馈列表',
          path: '/fqaManage/feedback/list',
          affix: true,
        },
        component: () => import('@/views/FqaManage/feedback/list.vue')
      }
    ]
  }
]

export default fqaManage;