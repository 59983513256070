/**
  * 版本管理
  **/

import http from '@/utils/http'
import qs from 'qs'

export default {

  /** 新增应用版本 */
  versionAdd(data) {
    return http({
      method: 'post',
      url: '/version/add',
      data: qs.stringify(data)
    })
  },

  /** 应用版本详情 */
  versionDetails(params) {
    return http({
      method: 'get',
      url: '/version/details',
      params
    })
  },

  /** 修改应用版本 */
  versionUpdate(data) {
    return http({
      method: 'post',
      url: '/version/update',
      data: qs.stringify(data)
    })
  },

  /** 应用版本上下线 */
  versionShelf(params) {
    return http({
      method: 'get',
      url: '/version/shelf',
      params
    })
  },

  /** 更新资源包 */
  versionAsset(data) {
    return http({
      method: 'put',
      url: '/asset',
      data
    })
  },

  /** 资源类型列表 */
  getAssetTypes() {
    return http({
      method: 'get',
      url: '/asset/types',
    })
  },



}