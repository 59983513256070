/** 限制输入框只能输入 数字 必须大于0 */
const formatterInteger = (value) => {
  try {
    return value = value.replace(/^[^1-9]+|[^0-9]/g, '');
  } catch {
    console.log('error');
  }
};

/** 限制输入框只能输入 数字 可以输入0 但输入00会失败 */
const formatterNumber = (value) => {
  try {
    return value = value.replace(/[^0-9.]/g, '').replace(/^0+(\d)|(\.)/, '$1');
  } catch {
    console.log('error');
  }
};

/** 限制输入框是金额类型 */
const formatterMoney = (value) => {
  try {
    return value = value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/, '$1$2$3').replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,2})/g)[0];
  } catch {
    console.log('error');
  }
}

/** 限制输入框不能输入中文 */
const formatterNoChinese = (value) => {
  try {
    return (value = value.replace(/[\u4e00-\u9fa5]/g, ''));
  } catch {
    console.log('error');
  }
}

/** 限制输入框保留一位小数 体重 */
const formatterWeight = (value) => {
  try {
    return value = value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/, '$1$2$3').replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,1})/g)[0];
  } catch {
    console.log('error');
  }
}

/** 限制输入框为正整数 排序 */
const formatterSort = (value) => {
  try {
    return (value = value.replace(/^\.+|[^\d]/g, ''));
  } catch {
    console.log('error');
  }
}

/** 限制输入框为正整数 库存 */
const formatterStock = (value) => {
  try {
    return value = value.replace(/^\.+|[^\d]/g, '').replace(/^0+(\d)|(\.)/, '$1');
  } catch {
    console.log('error');
  }
}

/** 限制Id类型输入 */
const formatterId = (value) => {
  try {
    return value = value.replace(/^\.+|[^\d]/g, '');
  } catch {
    console.log('error');
  }
}

/** 解析url ? 后面的参数 */
const getUrlParams = (url) => {
  // const url = window.location.href; // 获取当前页面的 URL
  const queryString = url.substring(url.indexOf('?') + 1);
  const params = {};

  queryString.split('&').forEach((param) => {
    const [key, value] = param.split('=');
    params[key] = decodeURIComponent(value);
  });

  return params
}

export default {
  formatterInteger,
  formatterNumber,
  formatterMoney,
  formatterNoChinese,
  formatterId,
  getUrlParams,
  formatterWeight,
  formatterStock,
  formatterSort,
};