

// 宠物体型列表
export const petContour = [
    {
        label: '玩具',
        value: 0
    },
    {
        label: '茶杯',
        value: 1
    },
    {
        label: '迷你',
        value: 2
    },
    {
        label: '标准',
        value: 3
    },
    {
        label: '小型',
        value: 4
    },
    {
        label: '中型',
        value: 5
    },
    {
        label: '大型',
        value: 6
    },
    {
        label: '巨型',
        value: 7
    }
];

// 宠物类型列表
export const petType = [
    {
        label: '喵星人',
        value: 0
    },
    {
        label: '汪星人',
        value: 1
    }
];

// 启用、禁用类型
export const status = [
    {
        label: '启用',
        value: 1
    },
    {
        label: '禁用',
        value: 2
    }
];

// 启用、禁用类型
export const statusList = [
    {
        label: '启用',
        value: true
    },
    {
        label: '禁用',
        value: false
    }
]

// 状态类型
export const goodsStatus = [
    {
        label: '上架',
        value: 1
    },
    {
        label: '下架',
        value: 2
    }
];

/* 获取订单状态 */
export const payStatus = [
//   {
//     label: '已取消',
//     value: 'CANCELLED',
//   },
  {
    label: '待付款',
    value: 'PENDING_PAYMENT',
  },
  {
    label: '定金待支付',
    value: 'PENDING_PAYMENT_DEPOSIT',
  },
  {
    label: '尾款待支付',
    value: 'PENDING_PAYMENT_BALANCE',
  },
  {
    label: '拼团中',
    value: 'GROUPING',
  },
  {
    label: '待发货',
    value: 'PENDING_SHIPMENT',
  },
  {
    label: '部分发货',
    value: 'PARTIAL_SHIPMENT',
  },
  {
    label: '待收货',
    value: 'PENDING_RECEIPT',
  },
  {
    label: '已完成',
    value: 'COMPLETED',
  },
  {
    label: '售后中',
    value: 'AFTER_SALES_SERVICE',
  },
  {
    label: '已退款',
    value: 'REFUNDED',
  },
  {
    label: '部分退款',
    value: 'PARTIAL_REFUND',
  },
  {
    label: '拒绝退款',
    value: 'REFUND_REJECTED',
  },
  {
    label: '订单关闭',
    value: 'ORDER_CLOSED',
  },
];

/* 获取支付方式 */
export const payType = [
    {
        label: '支付宝',
        value: 1
    },
    {
        label: '微信',
        value: 2
    },
    {
        label: '微信小程序',
        value: 3
    }
];

/* 获取商城广告类型 */
export const bannerType = [
    {
        label: '首页轮播图',
        value: 1,
    },
    {
        label: '首页广告图1',
        value: 3,
    },
    {
        label: '首页广告图2',
        value: 4,
    },
    {
        label: '拼团轮播图',
        value: 2,
    },
    {
        label: '新人专享广告图',
        value: 5,
    },
    {
        label: '通知专用页',
        value: 6
    }
];

/** 应用版本管理 -- 设备类型 */
export const platform = [
    {
        label: 'iOS',
        value: 'iOS'
    },
    {
        label: 'android',
        value: 'android'
    }
];

/** APP广告 -- 广告类型 */
export const appAdvertType = [
    {
        label: 'APP首页轮播图',
        value: 1,
    },
    {
        label: 'APP启动页广告',
        value: 2
    },
    {
        label: 'APP个人中心广告',
        value: 3
    }
];

/* 获取商城广告跳转类型 */
export const bannerFormType = [
    {
        label: "无",
        value: 0,
    },
    {
        label: "站外链接",
        value: 1,
    },
    {
        label: "站内链接",
        value: 2,
    },
    // {
    //     label: "商品详情",
    //     value: 3,
    // },
    // {
    //     label: "商品专区",
    //     value: 4,
    // }
];

/** APP广告 -- 跳转类型 */
export const appAdvertForm = [
    {
        label: "无",
        value: 0
    },
    {
        label: '站外链接',
        value: 1,
    },
    {
        label: '站内链接',
        value: 2
    },
    // {
    //     value: 3,
    //     label: "商品详情"
    // },
    // {
    //     label: "商品专区",
    //     value: 4,
    // }
];

/** APP广告 -- 媒体类型 */
export const appAdvertUrlType = [
    {
        label: '图片',
        value: '1',
    },
    {
        label: '视频',
        value: '2',
    }
];

/** 售后退款状态 */
export const refundStatus = [
    {
        label: '撤销申请',
        value: 'WITHDRAW_APPLICATION'
    },
    {
        label: '等待退款',
        value: 'REFUND_PENDING'
    },
    {
        label: '退款成功',
        value: 'REFUND_SUCCESSFUL'
    },
    {
        label: '处理退货申请',
        value: 'RETURN_PENDING'
    },
    {
        label: '退货中',
        value: 'RETURN_IN_PROGRESS'
    },
    {
        label: '退货审核',
        value: 'RETURN_REVIEW'
    },
    {
        label: '退货退款成功',
        value: 'RETURN_REFUND_SUCCESSFUL'
    },
    {
        label: '拒绝退款',
        value: 'WAITING_RESPONSE'
    },
    {
        label: '退款完结',
        value: 'REFUND_COMPLETED'
    }
];

/** 售后发货状态 */
export const expressStatus = [
    {
        label: '未发货',
        value: 0,
    },
    {
        label: '揽收',
        value: 1,
    },
    {
        label: '在途',
        value: 2,
    },
    {
        label: '派件',
        value: 3,
    },
    {
        label: '签收',
        value: 4,
    },
    {
        label: '拒收',
        value: 5,
    },
];

/** 商品详情展示风格 */
export const goodStyle = [
    {
        label: '常规风格',
        value: 'default',
    },
    {
        label: '简约风格',
        value: 'brief'
    }
];

/** 资源包类型列表 */
export const assetType = [
    {
        label: '设备控制面板',
        value: 1
    },
    {
        label: '商城',
        value: 2
    }
];

/** 硬件产品测试报告 */
export const testRecordstatus = [
    {
        label: '通过',
        value: true,
    },
    {
        label: '不通过',
        value: false
    },
];

/** 拼团活动状态 */
export const groupActivityState = [
    {
        label: '未发布',
        value: 'PENDING',
    },
    {
        label: '未开始',
        value: 'NOT_STARTED',
    },
    {
        label: '进行中',
        value: 'IN_PROGRESS',
    },
    {
        label: '已结束',
        value: 'FINISHED',
    },
    {
        label: '已关闭',
        value: 'CLOSED',
    },
];

/** 拼团订单状态 */
export const groupOrderState = [
    {
        label: '拼团中',
        value: 'GOING',
    },
    {
        label: '拼团成功',
        value: 'SUCCESS',
    },
    {
        label: '拼团失败',
        value: 'FAIL',
    },
];

/** 拼团订单返现状态 */
export const groupCashbackState = [
    {
        label: '未返现',
        value: 'NO',
    },
    {
        label: '待返现',
        value: 'PENDING',
    },
    {
        label: '已返现',
        value: 'FINISH',
    },
    {
        label: '返现失败',
        value: 'FAIL',
    },
]

/** 预售活动状态 */
export const presaleState = [
    {
        label: '未发布',
        value: 'PENDING',
    },
    {
        label: '进行中',
        value: 'IN_PROGRESS',
    },
    {
        label: '已结束',
        value: 'FINISHED',
    }
]

/** 预售活动优惠级别 */
export const presaleLevel = [
    {
        label: '商品级别',
        value: 'GOOD'
    },
    {
        label: 'SKU级别',
        value: 'SKU'
    },
];

/** 商品折扣活动状态 */
export const discountState = [
    {
        label: '未发布',
        value: 'PENDING'
    },
    {
        label: '未开始',
        value: 'NOT_STARTED'
    },
    {
        label: '已暂停',
        value: 'PAUSED'
    },
    {
        label: '进行中',
        value: 'IN_PROGRESS'
    },
    {
        label: '已结束',
        value: 'FINISHED'
    },
    {
        label: '已关闭',
        value: 'CLOSED'
    }
]

/** 商品折扣优惠方式 */
export const discountMethod = [
    {
        label: '打折',
        value: 'DISCOUNT'
    },
    {
        label: '满减',
        value: 'REDUCE'
    },
];

/** 店铺折扣优惠类型 */
export const discountLevel = [
    {
        label: '自选商品',
        value: 'PART'
    },
    {
        label: '全店商品',
        value: 'ALL'
    }
]

/** 店铺折扣优惠条件 */
export const shopDiscountMethod = [
    {
        label: '满件（打折）',
        value: 'DISCOUNT'
    },
    {
        label: '满元（减钱）',
        value: 'REDUCE'
    }
]

/** 优惠券状态 */
export const couponState = [
    {
        label: '领取中',
        value: 'RECEIVING',
    },
    {
        label: '已领完',
        value: 'FINISHED',
    },
    {
        label: '已结束',
        value: 'CLOSED'
    }
]

/** 优惠券限领条件 */
export const couponQuota = [
    {
        label: 1,
        value: 1,
    },
    {
        label: 2,
        value: 2,
    },
    {
        label: 3,
        value: 3,
    },
    {
        label: 4,
        value: 4,
    },
    {
        label: 5,
        value: 5,
    },
    {
        label: '不限量',
        value: 0,
    },
]

export const componentsType = [
    {
        label: '基础组件',
        value: '',
    },
    {
        label: '营销组件',
        value: '',
    },
]

export const componentsName = [
    {
        label: '图文导航',
        value: '',
    },
    {
        label: '轮播图',
        value: '',
    },
    {
        label: '魔方',
        value: '',
    },
    {
        label: '商品列表',
        value: '',
    },
];

/** 前台分类-跳转类型 */
export const frontCategoryLinkType = [
    {
        label: '无',
        value: ''
    },
    {
        label: '分类商品聚合页',
        value: 'CATEGORY'
    },
    {
        label: '品牌',
        value: 'BRAND'
    },
    {
        label: '站内链接',
        value: 'LINK'
    },
    {
        label: '商品详情',
        value: 'GOOD'
    }
]

/** 活动商品配置活动位置 */
export const activityAliasList = [
    {
        label: '商城首页',
        value: 'home',
    },
    {
        label: '今日特价',
        value: 'homeDailySpecial'
    },
    {
        label: '专区自配',
        value: 'homeActivity',
    },
    {
        label: '二级页面1',
        value: 'secondaryPage'
    },
    {
        label: '二级页面2',
        value: 'secondaryPage2'
    },
    {
        label: '二级页面3',
        value: 'secondaryPage3'
    },
]

/** 活动商品配置活动展示风格 */
export const activityAliasStyleList = [
    {
        label: '常规风格',
        value: 'default',
    },
    {
        label: '画廊',
        value: 'gallery',
    },
    {
        label: '列表',
        value: 'list',
    },
    {
        label: '宫格',
        value: 'grid',
    }
]

/** 活动商品配置活动展示列数 */
export const activityColumnList = [
    {
        label: '一列',
        value: '1',
    },
    {
        label: '两列',
        value: '2',
    },
    {
        label: '三列',
        value: '3'
    }
]

/** 商城广告状态 */
export const mallAdvertStatus = [
    {
        label: '未开始',
        value: 'NOT_START',
    },
    {
        label: '进行中',
        value: 'RUNNING',
    },
    {
        label: '已停止',
        value: 'STOP',
    },
    {
        label: '已过期',
        value: 'EXPIRED',
    },
];

/** 优惠券是否开放领取 */
export const couponVisibleList = [
    {
        label: '是',
        value: true,
    },
    {
        label: '否',
        value: false
    }
]

/** 通用是否类型 */
export const visibleList = [
    {
        label: '是',
        value: true,
    },
    {
        label: '否',
        value: false
    }
]

/** N元任选状态 */
export const optionalStateList = [
    {
        label: '未开始',
        value: 'NOT_STARTED',
    },
    {
        label: '已暂停',
        value: 'PAUSED',
    },
    {
        label: '进行中',
        value: 'IN_PROGRESS',
    },
    {
        label: '已结束',
        value: 'FINISHED',
    },
    {
        label: '已关闭',
        value: 'CLOSED',
    }
]

/** 新人专享状态 */
export const newcomerStateList = [
    {
        label: '未发布',
        value: 'PENDING',
    },
    {
        label: '未开始',
        value: 'NOT_STARTED',
    },
    {
        label: '已暂停',
        value: 'PAUSED',
    },
    {
        label: '进行中',
        value: 'IN_PROGRESS',
    },
    {
        label: '已结束',
        value: 'FINISHED',
    },
    {
        label: '已关闭',
        value: 'CLOSED',
    }
]

/** 消息推送-推送类型 */
export const pushTypeList = [
    {
        label: '系统消息',
        value: 'SYSTEM',
    },
    {
        label: '智能设备',
        value: 'DEVICE',
        disabled: true,
    },
    {
        label: '商城订单',
        value: 'SHOP_ORDER',
        disabled: true,
    },
    {
        label: '备忘提醒',
        value: 'REMIND',
        disabled: true,
    }
]

/** 消息推送-推送来源 */
export const sendSourceList = [
    {
        label: '系统推送',
        value: 'SYSTEM'
    },
    {
        label: '手动推送',
        value: 'MANUAL'
    }
]

/** 消息推送-推送目标 */
export const sendTargetList = [
    {
        label: '全部用户',
        value: 'ALL',
    },
    {
        label: '指定用户',
        value: 'SPECIFY_USER',
    },
    {
        label: '指定标签用户',
        value: 'SPECIFY_TAG'
    }
]

/** 消息推送-发送时机 */
export const pushOpportunityList = [
    {
        label: '立即发送',
        value: 'NOW',
    },
    {
        label: '定时发送',
        value: 'TIMING',
    }
]

/** 站内链接跳转 */
export const navigateToUrlList = [
    {
        label: '商品详情',
        value: '/pages/mall/detail',
    },
    {
        label: '商品专区',
        value: '/pages/mall/goods',
    },
    {
        label: '通知专用页',
        value: '/pages/activity/detail',
    },
    {
        label: 'N元任选活动页',
        value: '/pages/activity/optional/detail',
    },
    {
        label: '新人专享活动页',
        value: '/pages/activity/newcomer/detail',
    },
    {
        label: '优惠券详情',
        value: '/pages/coupon/detail',
    },
    {
        label: '店铺折扣详情',
        value: '/pages/coupon/discount/detail',
    },
    {
        label: '二级页面1',
        value: '/pages/mall/secondary-page?alias=secondaryPage',
    },
    {
        label: '二级页面2',
        value: '/pages/mall/secondary-page?alias=secondaryPage2',
    },
    {
        label: '二级页面3',
        value: '/pages/mall/secondary-page?alias=secondaryPage3',
    },
    {
        label: '助力拉新活动页',
        value: '/pages/activity/assist/detail',
    },
    {
        label: '问卷调查页',
        value: '/pages/survey/index',
    },
    {
        label: '九宫格抽奖页',
        value: '/pages/lottery/index',
    }
];

/** 站内跳转需要参数的路径 */
export const navigateToIsValidator = [
  '/pages/mall/detail',
  '/pages/mall/goods',
  '/pages/activity/optional/detail',
  '/pages/coupon/detail',
  '/pages/coupon/discount/detail',
  '/pages/survey/index',
  '/pages/lottery/index',
];

/** APP消息推送需要的路径参数 */
export const appDefaultUrl = `cp://cloudpets.com/shop?url=`;

/* 站内链接跳转类型 */
export const advertLink = [
  {
    label: '商城首页',
    value: '/pages/mall/index',
  },
  ...navigateToUrlList,
];

/** 运费管理计价方式 */
export const freightMethodList = [
    {
        label: '按件数',
        value: 'PIECE',
    },
    {
        label: '按重量',
        value: 'WEIGHT',
    }
]

/** 开票状态 */
export const invoiceStatus = [
    {
        label: '待处理',
        value: 'PENDING',
    },
    {
        label: '已驳回',
        value: 'REJECTED',
    },
    {
        label: '已撤销',
        value: 'CANCELED',
    },
    {
        label: '已开票',
        value: 'TICKETED',
    },
    {
        label: '待冲红',
        value: 'WAIT_RED',
    },
    {
        label: '已冲红',
        value: 'BE_RED',
    },
];

/** 助力拉新活动状态 */
export const assistStateList = [
    {
        label: '未开始',
        value: 'NOT_STARTED',
    },
    {
        label: '进行中',
        value: 'IN_PROGRESS',
    },
    {
        label: '已结束',
        value: 'FINISHED',
    },
    {
        label: '已关闭',
        value: 'CLOSED',
    },
]

/** 助力拉新助力对象 */
export const assistTargetList = [
    {
        label: '全部用户',
        value: 'ALL',
    },
    {
        label: '指定新人',
        value: 'NEW',
    },
]

/** 优惠券-有效期类型 */
export const couponTimeTypeList = [
    {
        label: '指定时间',
        value: 'TIME_RANGE',
    },
    {
        label: '领取后',
        value: 'RELATIVE',
    },
];

/** 售后方式 */
export const refundTypeList = [
    {
        label: '仅退款',
        value: 1,
    },
    {
        label: '退货退款',
        value: 2,
    },
];

/** 赠品管理活动状态 */
export const giftStateList = [
    {
        label: '进行中',
        value: 'PUBLISHED',
    },
    {
        label: '已结束',
        value: 'FINISHED',
    },
]

/** 买赠活动状态 */
export const buyGiftStateList = [
    {
        label: '未开始',
        value: 'NOT_STARTED',
    },
    {
        label: '进行中',
        value: 'IN_PROGRESS',
    },
    {
        label: '已过期',
        value: 'EXPIRED',
    },
    {
        label: '已结束',
        value: 'FINISHED',
    },
]

/** 性别 */
export const sexList = [
    {
        label: '男',
        value: 'MALE',
    },
    {
        label: '女',
        value: 'FEMALE',
    },
    {
        label: '未知',
        value: 'UNKNOWN',
    },
];

/** 年龄 */
export const ageList = [
    {
        label: '18岁以下',
        value: 1,
    },
    {
        label: '18-24',
        value: 2,
    },
    {
        label: '25-30',
        value: 3,
    },
    {
        label: '31-35',
        value: 4,
    },
    {
        label: '36-40',
        value: 5,
    },
    {
        label: '40岁以上',
        value: 6,
    },
]

/** 注册时间筛选类型 */
export const registerTimeTypeList = [
    {
        label: '注册时间',
        value: 'RELATIVE'
    },
    {
        label: '距当前时间',
        value: 'RANGE'
    }
]

/** 宠物类型 */
export const petSpecieList = [
    {
        label: '猫',
        value: 'CAT',
    },
    {
        label: '狗',
        value: 'DOG',
    },
]

/** 宠物性别 */
export const petSexList = [
    {
        label: 'MM',
        value: 'MM',
    },
    {
        label: 'GG',
        value: 'GG',
    },
    {
        label: '绝育MM',
        value: 'JY_MM',
    },
    {
        label: '绝育GG',
        value: 'JY_GG',
    },
]

/** 发放优惠券-发放对象 */
export const userTargetList = [
    // {
    //     label: '全部用户',
    //     value: 'ALL',
    // },
    {
        label: '指定用户',
        value: 'SPECIFY_USER',
    },
    {
        label: '指定标签用户',
        value: 'SPECIFY_TAG'
    }
]

/** 商品主图放置位置 */
export const goodsBadgeStyleList = [
    {
        label: '上方',
        value: 1
    },
    {
        label: '下方',
        value: 2
    },
]

/** 商品主图活动状态 */
export const goodsBadgeStateList = [
    {
        label: '未开始',
        value: 'NOT_STARTED'
    },
    {
        label: '进行中',
        value: 'IN_PROGRESS'
    },
    // {
    //     label: '已过期',
    //     value: 'EXPIRED'
    // },
    {
        label: '已结束',
        value: 'FINISHED'
    },
]

/** 秒杀活动状态 */
export const flashStateList = [
    {
        label: '未开始',
        value: 'NOT_STARTED'
    },
    {
        label: '预热中',
        value: 'PREHEATING'
    },
    {
        label: '进行中',
        value: 'IN_PROGRESS'
    },
    {
        label: '已结束',
        value: 'FINISHED'
    },
]

/** 九宫格抽奖活动状态 */
export const luckyGridStateList = [
    {
        label: '待发布',
        value: 'PENDING'
    },
    {
        label: '未开始',
        value: 'NOT_STARTED'
    },
    {
        label: '进行中',
        value: 'IN_PROGRESS'
    },
    {
        label: '已结束',
        value: 'FINISHED'
    },
]

/** 设置奖品类型 */
export const prizeTypeList = [
    {
        label: '优惠券',
        value: 'COUPON'
    },
    {
        label: '赠品',
        value: 'GIFT'
    },
    {
        label: '未中奖',
        value: 'LOSE'
    },
]

/** 问卷调查-活动状态 */
export const surveyStateList = [
    {
        label: '未发布',
        value: 'PENDING',
    },
    {
        label: '未开始',
        value: 'NOT_STARTED'
    },
    {
        label: '进行中',
        value: 'IN_PROGRESS'
    },
    {
        label: '已结束',
        value: 'FINISHED'
    },
]

/** 商品关联设备 */
export const goodsEquipmentList = [
    {
        label: '喂食器干燥剂',
        value: 'DESICCANT'
    },
    {
        label: '饮水机滤芯',
        value: 'FILTER'
    },
    {
        label: '猫砂盆除臭块',
        value: 'DEODORANT'
    },
    {
        label: '猫砂盆猫砂',
        value: 'SAND'
    },
]

/** 商品评价-评价星级 */
export const goodsReviewStarList = [
    {
        label: '全部',
        value: ''
    },{
        label: '1星',
        value: 1
    },
    {
        label: '2星',
        value: 2
    },
    {
        label: '3星',
        value: 3
    },
    {
        label: '4星',
        value: 4
    },
    {
        label: '5星',
        value: 5
    }
]

/** 商品评价-置顶状态 */
export const goodsReviewPinList = [
    {
        label: '全部',
        value: ''
    },
    {
        label: '置顶',
        value: true
    },
    {
        label: '未置顶',
        value: false,
    }
]

/** 商品评价-是否隐藏 */
export const goodsReviewStateList = [
    {
        label: '全部',
        value: ''
    },
    {
        label: '显示',
        value: 'SHOW'
    },
    {
        label: '隐藏',
        value: 'HIDE',
    }
]

/** 签到活动-签到状态 */
export const signStateList = [
    {
        label: '启用',
        value: 'OPEN',
    },
    {
        label: '停用',
        value: 'STOP'
    }
]

/** 问卷调查-题目类型 */
export const questionnaireSurveyTypeList = [
    {
        label: '单选题',
        value: 'RADIO',
    },
    {
        label: '多选题',
        value: 'CHECK',
    },
    {
        label: '多行文本题',
        value: 'TEXT',
    }
]

export default {
  petType,
  petContour,
  refundStatus,
  expressStatus,
  assetType,
  goodStyle,
  presaleLevel,
  discountMethod,
  discountLevel,
  shopDiscountMethod,
  couponState,
  couponQuota,
  componentsType,
  componentsName,
  frontCategoryLinkType,
  statusList,
  activityAliasList,
  mallAdvertStatus,
  couponVisibleList,
  visibleList,
  optionalStateList,
  newcomerStateList,
  pushOpportunityList,
  appDefaultUrl,
  freightMethodList,
  invoiceStatus,
  activityAliasStyleList,
  activityColumnList,
  couponTimeTypeList,
  refundTypeList,
  giftStateList,
  buyGiftStateList,
  petSpecieList,
  sexList,
  petSexList,
  registerTimeTypeList,
  userTargetList,
  goodsBadgeStyleList,
  flashStateList,
  luckyGridStateList,
  prizeTypeList,
  surveyStateList,
  goodsEquipmentList,
  navigateToUrlList,
  navigateToIsValidator,
  signStateList,
  questionnaireSurveyTypeList,
};