<template>
  <!-- 表单组件 -->
  <div class="moe-form">
    <el-form
      class="moe-form__inner"
      ref="moeForm"
      :model="model"
      :rules="rules"
      :label-width="labelWidth"
      :label-suffix="labelSuffix"
      :status-icon="false"
      :inline="inline">
      <slot />

      <div class="moe-form__tool" v-if="showTool">
        <el-button v-if="showClose" type="info" icon="el-icon-close" @click="$moe_coordinator.navigateBack()">关闭</el-button>
        <el-button v-if="showBack" type="info" icon="el-icon-back" @click="$router.go(-1)">返回</el-button>
        <slot name="tool" />
      </div>
    </el-form>
  </div>
</template>

<script>
/**
 * 表单 - moe-form
 * @description 基于el-form组件的二次封装的表单，用于添加，编辑等页面
 * @property {Object} model 绑定对象
 * @property {Object} rules 校验对象
 * @property {String} labelSuffix label尾部符号
 * @property {Boolean} inline 是否为行内表单
 * @property {String} labelWidth 表单域标签的的宽度，例如 '50px'。支持 auto。
 * @event {Function} validate 表单校验方法
 **/
export default {
  name: 'moe-form',
  props: {
    model: {
      type: Object,
      default: () => {},
    },

    rules: {
      type: Object,
      default: () => {},
    },

    labelSuffix: {
      type: String,
      default: ':',
    },

    inline: {
      type: Boolean,
      default: false,
    },

    showBack: {
      type: Boolean,
      default: true
    },

    showClose: {
      type: Boolean,
      default: false
    },

    showTool: {
      type: Boolean,
      default: true
    },

    labelWidth: {
      type: String,
      default: 'auto'
    },

    defaultVerify: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    /**
     * 表单校验
     **/
    validate(callback, verifyCatch) {
      this.$refs.moeForm.validate((verify) => {
        if (verify) {
          callback()
        } else {
          if (this.defaultVerify) {
            this.$moe_msg.warning('请完善信息 !')
          } else {
            verifyCatch && verifyCatch();
          }
        }
      })
    },

    /**
     * 对部分表单字段进行校验的方法
     **/
    validateField(...args) {
      this.$refs.moeForm.validateField(...args)
    },

    /**
     * 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
     **/
    resetFields(...args) {
      this.$refs.moeForm.resetFields(...args)
    },

    /**
     * 移除表单项的校验结果。传入待移除的表单项的 prop 属性或者 prop 组成的数组，如不传则移除整个表单的校验结果
     **/
    clearValidate(...args) {
      this.$refs.moeForm.clearValidate(...args)
    },
  },
}
</script>

<style lang="scss" scoped>
.moe-form {
  background-color: #fff;
  padding: 30px 15px;

  &__inner {
    min-width: 500px;
    width: 50%;
    margin: auto;
  }

  &.default-form {
    .moe-form__inner {
      width: 100%;
    }
  }
  &.auto-form {
    display: flex;
    flex: 1;
    .moe-form__inner {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 0;
    }
  }

  ::v-deep .el-select {
    width: 100%;
  }

  &__tool {
    text-align: center;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 1000px) {
}
</style>