/**
 * 推送API
 **/

import http from '@/utils/http'
import http2 from '@/utils/http2';
import qs from 'qs'

/* export API */
export default {
    /* 推送列表 */
    pushList(params) {
        return http({
            method: 'get',
            url: '/message/pageList',
            params
        })
    },

    /** 获取推送消息详情 */
    getPushDetail(params) {
        return http({
            method: 'get',
            url: '/message/detail',
            params
        })
    },

    /* 推送添加 */
    pushAdd(data) {
        return http2({
            method: 'post',
            url: '/message/add',
            data
        })
    },

    /** 短信模板列表 */
    getMessageTplList() {
        return http({
            method: 'get',
            url: '/shop/sms/tplList',
        })
    },

    /** 短信列表 */
    getMessageList(params) {
        return http({
          method: 'get',
          url: '/shop/sms/pageList',
          params,
        });
    },

    /** 推送短信 */
    messageAdd(data) {
        return http2({
            method: 'post',
            url: '/shop/sms/add',
            data
        })
    },

    /** 获取短信详情 */
    getMessageDetai(params) {
        return http({
            method: 'get',
            url: '/shop/sms/detail',
            params
        })
    }
}