/**
 * 用户状态模块
 **/

import LOGIN_API from '@/api/modules/login'

export default {
	namespaced: true,
	state: {
		//用户权限状态
		isAuth: Boolean(sessionStorage.getItem('token')),

		//用户信息
		user: {},
	},
	getters: {},
	mutations: {
		/* 设置用户信息 */
		setUserInfo(state, data) {
			state.user = data;
		},

		/* 设置权限状态 */
		setIsAuth(state, data) {
			state.isAuth = data;
		},
	},
	actions: {
		/* 更新用户信息 */
		updateUserInfo({ commit }) {
			LOGIN_API.userInfo({}).then(data => {
				if (data.code == 200) {
					let user = data.result;
					commit('setUserInfo', user);
				}
			});
		}
	},
	modules: {}
}